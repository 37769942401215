import "./Css_Files/App.css";
import SearchBar from "./Components/SearchBar";
import SearchResult from "./Components/SearchResult";
import React, { Component } from "react";
import { getWithExpiry, get_Storage } from "./Utils/LocalStorage_Handeling";
import LoginModal from "./Components/Login_Modal";
import storageChanged from "storage-changed";
import { GetCurrTime, GettingRecords } from "./Utils/GlobalFunctions";
import {toast, Toaster} from "react-hot-toast";
import norecord from "./images/noRecord.png";
import { Advance_Function_Cost, Basic_Function_Cost, Mini_Function_Cost, Users } from "./Utils/DummyData";
import SideMenu from "./Components/SideMenu";
import CheckoutModal from "./Components/Checkout_Modal";
import UserProfile from "./Components/UserProfile";
import { UpdateCredits_Backend } from "./Utils/ApiCalls";
import PricingModal from "./Components/PricingModal";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "@mui/material";



class Widget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail:localStorage.getItem("user_details")===null?null:JSON.parse(localStorage.getItem("user_details")).username,
      CheckoutModalState: false,
      PricingModal_state:false,
      UserCredentials:
        JSON.parse(localStorage.getItem("user_details")) === null
          ? {}
          : JSON.parse(localStorage.getItem("user_details")),
      UserCredits:
        localStorage.getItem("UserCredits") === null
          ? ""
          : localStorage.getItem("UserCredits"),
      CheckoutModalData: JSON.parse(
        localStorage.getItem("SelectedDataArray") === null
          ? "[]"
          : localStorage.getItem("SelectedDataArray")
      ),
      LoginModalState: false,
      SeletedUsers: JSON.parse(
        localStorage.getItem("SelectedDataArray_Link") === null
          ? "[]"
          : localStorage.getItem("SelectedDataArray_Link")
      ),

      UserResult: Users.FinalData, //null,   // change to dummy data
      CountofData: 12,
    };
  }
  CheckAuthenticationParam = () => {
    var Response = getWithExpiry("authentication_param");//n
    var UserObject=get_Storage("user_details")//nn
    if (Response!==null && UserObject!==null) {
      this.setState({ LoginModalState: false });
   

    } else {
      this.setState({ LoginModalState: true });
      
    }
  };

  Checkout_Toggle = () => {
    this.setState({ CheckoutModalState: !this.state.CheckoutModalState });
  };

  componentWillMount() {
    this.CheckAuthenticationParam();
  }
  componentDidMount() {
    localStorage.setItem("SelectedDataArray_Link", "[]");
    localStorage.setItem("SelectedDataArray", "[]");
    localStorage.setItem("Search_Response", "{}");
    localStorage.setItem("SingleProfileData", "undefined");

    storageChanged("local", {
      eventName: "changeinstorage",
    });
    window.addEventListener("changeinstorage", (event) => {
      if (event.detail.key === "authentication_param") {
        this.CheckAuthenticationParam();
      } else if (event.detail.key === "Search_Response") {
        var Data = JSON.parse(
          localStorage.getItem("Search_Response") == null
            ? null
            : localStorage.getItem("Search_Response")
        );
        if (Data !== null) {
          if (Data.MergeStatus !== undefined) {
            if (Data.MergeStatus) {
              this.setState({
                UserResult: [...this.state.UserResult, ...Data.FinalData],
              });
            } else {
              var Count = Data.Return_Count.replace(" results", "");
              Count = Count.replace("About", "");
              Count = Count.replace(",", "");

              localStorage.setItem("UserCount", Data.Return_Count);
              console.log(Count);
              this.setState({
                UserResult: Data.FinalData,
                CountofData: parseInt(Count),
              });
            }
          }
        }
      } else if (event.detail.key === "SelectedDataArray_Link") {
        var allData = this.state.SeletedUsers;
        if (event.detail.value != null) {
          var NewData = JSON.parse(event.detail.value);

          if (allData.length !== NewData.length) {
            this.setState({
              SeletedUsers: NewData,
            });
          }
        }
      } else if (event.detail.key === "SelectedDataArray") {
        var allData = this.state.CheckoutModalData;
        if (event.detail.value != null) {
          var NewData = JSON.parse(event.detail.value);

          if (allData.length !== NewData.length) {
            this.setState({
              CheckoutModalData: NewData,
            });
          }
        }
      } else if (event.detail.key === "user_details") {
        if (event.detail.value != null) {
          var NewData = JSON.parse(event.detail.value);

          this.setState({
            UserCredentials: NewData,
            userEmail:NewData.username
          });
        }
      } else if (event.detail.key === "UserCredits") {
        if (event.detail.value != null) {
          var NewData = event.detail.value;
          while (NewData.includes('"')) {
            NewData = NewData.replace('"', "");
          }
          NewData = parseInt(NewData);

          this.setState({ UserCredits: NewData });
        }
      }
    });
  }
UpdatingPricingModal=()=>{
this.setState({
  PricingModal_state:false
})
}
ShowPricingModal=()=>{
  this.setState({PricingModal_state:true})
}
  componentWillUnmount() {
    // localStorage.setItem("SingleProfileData",undefined)
    window.removeEventListener("changeinstorage", () => {
      alert("listener remove");
    });
  }
  LoadMoreData = () => {
    var LoadBtn = document.getElementById("LoadMoreBtn");
    var response = GettingRecords(true);
    LoadBtn.innerText = "Loading..";
    LoadBtn.disabled = true;

    toast.promise(
      response,
      {
        loading: "Please Wait",
        success: (data) => {
          if (data === true) {
            LoadBtn.innerText = "Load More";
            LoadBtn.disabled = false;

            return `Records Loaded`;
          } else {
            return "No More Records";
          }
        },
        error: (err) => `Something Went Wrong Please Try Again`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          color: "green",
          duration: 4000,
        },
      }
    );
  };
  render() {
    return (
      <div className="App">
        <Toaster/>
        {this.state.LoginModalState === true ? (
          <>
            <LoginModal />
            <div className="Disabled">
              <SearchBar />
            </div>
          </>
        ) : (
          <>
            <SearchBar scrollFunc={this.scrollToSection} />{" "}
            <PricingModal ModalState={this.state.PricingModal_state} ModalStateCallback={this.UpdatingPricingModal}/>  
            {/* <UserProfile
              UserName={this.state.UserCredentials.username}
              Credits={this.state.UserCredits}
              PricingModal={()=>this.ShowPricingModal()}
            /> */}
            <CheckoutModal
              ModalState={this.state.CheckoutModalState}
              HandleClose={this.Checkout_Toggle}
              ModalData={this.state.CheckoutModalData}
            ></CheckoutModal>
            {this.state.SeletedUsers.length > 0 ? (
              <SideMenu
                No_Of_Users={this.state.SeletedUsers.length}
                OpenModal={this.Checkout_Toggle}
              />
            ) : (
              <></>
            )}
            {this.state.UserResult === null ? (
              <></>
            ) : this.state.UserResult.length === 0 ? (
              <div className="p-5 bg-Grey noResults" name="noRecords">
                <img src={norecord} alt="No-Records"></img>
                <p> No Results Found</p>
              </div>
            ) : this.state.UserResult.length < this.state.CountofData ? (
              <>
                <CenterUI UserResult={this.state.UserResult} PricingModal={()=>this.ShowPricingModal()} />
                <Tooltip title="10 Credits">
                <button
                  onClick={()=>{
             
                    var response=UpdateCredits_Backend(this.state.userEmail,Basic_Function_Cost,"down","Dash")
                    response.then((data)=>{
                      if(!data.includes("null")){
                        this.LoadMoreData()

                        }
                    else{
                      //pricing modal
                      toast.error("You Dont Have Enough Credits")
                    this.setState({PricingModal_state:true})
                    }

                    }).catch(()=>{
                      toast.error("Something went Wrong")
                    })
                    
                  }}
                  id="LoadMoreBtn"
                  className="btn shadow bg-white LoadMore-btn"
                >
                  Load More
                </button>
                </Tooltip>
              </>
            ) : (
              <>
                <CenterUI UserResult={this.state.UserResult} PricingModal={()=>this.ShowPricingModal()}/>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const CenterUI = (props) => {
  var UserCount =
    localStorage.getItem("UserCount") === null
      ? "No"
      : localStorage.getItem("UserCount");
  return (
    <div className="row bg-greycontainer-fluid" name="noRecords">
      <div className="d-flex justify-content-end pt-3 mr-5">
        <h5>
          <strong>{UserCount + "  "}</strong> found
        </h5>
        
      </div>

      {props.UserResult.map((item, key) => (
        <SearchResult
          key={item.User_MemberId}
          UserImage={item.User_Image}
          UserName={item.User_Name}
          Position={item.Designation}
          Description={item.Basic_About}
          ProfileLink={item.User_NavigationUrl}
          UserLocation={item.User_Location}
          PricingModal={props.PricingModal}
        />
      ))}
    </div>
  );
};

export default Widget;
