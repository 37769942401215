import React from "react";
import '../Pages/customcss/Custom.css'
import Linkedin_Form from "../../../WebApp/Components/LinkedInForm";
import { setWithExpiry } from "../../../WebApp/Utils/LocalStorage_Handeling";
import toast from "react-hot-toast";
import { BasicDetails_Backend } from "../../../WebApp/Utils/ApiCalls";
import introJs from "intro.js";
import "../../assets/assets/scss/introjs.scss"

class GetToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        Cookie_value:"",
        Button_Enabled:true,
    };
  }

componentDidMount(){
  introJs().setOption("dontShowAgain", true).start();
}

  handleCookie=()=>{
    setWithExpiry("authentication_param",this.state.Cookie_value,8600000);

var resultPromise =  BasicDetails_Backend(
  "Pak",
  "Country"
);
toast.promise(
  resultPromise,
  {
    loading: 'Please Wait',
    success: (data) => {
      if(data!==null){
           if(data.Message!==undefined){
            return "Invalid Cookie";   

           }
           else{
            this.props.setTab();
            this.props.seValue();
            return 'Successfully Connected'
           }
       
      }
      
      else{    
        return "Invalid Cookie";   
      }
   },
    error: (err) => `Something Went Wrong Please Try Again`,
  },
  {
    style: {
      minWidth: '250px',
    },
    success: {
      duration: 5000,
      icon: '🔥',
    },
  }
);



  }
SetTabIndex=()=>{
  this.props.setTab();

}

SetCookieValue=(e)=>{
  this.setState({Cookie_value:e.target.value})
  if(e.target.value===""){
this.setState({Button_Enabled:true})
  }
  else{
    this.setState({Button_Enabled:false})
  }

}

  render() {
    return (<>
     <div className="p-4 text-light" data-title="Welcome" data-intro="Lets Start" data-step={1}>
     <div className="p-2 d-flex justify-content-center flex-column text-center">
        <h2>Connect To LinkedIn</h2>
        <div className="w-70 align-center" >
        <p>To perform actions on your behalf, LeadsFizz need to connect to your linkedin account<br/>
        You can connect your linkedin account with LeadsFizz either by loggingin directly or by manually entering the session cookie.
        </p>
        </div>
   
       
        </div>

      <div className="row">
        
        {/* Login modal column */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* <Login_Modal/> */}
         <div className="shadow rounded p-4 AutoConnect" data-title="Connect Automatically" data-intro="Login here with your linkedin account to connect automatically">
         <h4>Connect Automatically</h4>
         <p>
            Login to your LinkedIn account directly to connect automatically.
           </p>
           <Linkedin_Form
       setTab={()=>this.SetTabIndex()}
           />
         </div>
        </div>
        {/* Inset Cookie Column */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12" data-title="Connect Manually" data-intro="You can connect you linked in account manually from this section">
          <div className="rounded p-4">
           <h4>Connect Manually</h4>
           <p>
            You can connect to Linked Manually With The Following Steps
           </p>
           <ol className="ManualSteps" data-title="Follow these steps" data-intro="Follow the following steps to get the coockie value from linkedin ">
            <li>Visit Linkedin.com</li>
            <li>Login to your linked in account</li>
            <li>Press Ctrl+Shift+i Or F12</li>
            <li>Go to "Application" tab</li>
            <li>Click on "Cookies"  tab</li>
            <li>Click On "https://www.linkedin.com/" sub tab </li>
            <li>Look for the value with name "li_at"</li>
            <li>Copy the contents from "Cookie Value" section</li>
            <li>Paste the content in the field below</li>
           </ol>
           <input
           type="text"
           id="Cookie_Input"
           className="form-control mt-4 mb-4"
           placeholder="Paste cookie value here...."
           data-title="Paste Value"
           data-intro="After getting the cookie value from LinkedIn paste the value here and click connect"
           onChange={(e)=>{
            this.SetCookieValue(e)
        
        }}
           ></input>

           <button className="btn btn-primary rounded shadow mb-4"
           onClick={()=>this.handleCookie()}
           disabled={this.state.Button_Enabled}
           >Connect</button>
           <p><small>Note: Cookie is updated everytime you login to your Linkedin account, Please stay logged in untill you finised your tasks</small></p>
          </div>
        </div>
      </div>
      </div>
      </>
    );
  }
}

export default GetToken;
