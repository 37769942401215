import comingsoon from '../../assets/images/comingsoon.png'
import React from 'react';
import '../Pages/customcss/Custom.css'

const  Comingsoon = () =>{
   return(
    <div className="Comingsoon text-light text-center pt-5">
<h1 className='mt-5 mb-3 align-middle'>Coming Soon</h1>
<div className='d-flex justify-content-center align-middle'>
<img src={comingsoon}  width={"500"}></img>
</div>

        
    </div>
   );
}

export default Comingsoon;