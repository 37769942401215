import React from "react";
import PageHeader from "../PageHeader";
import InfoMessage from "./InfoMessage";
import '../Pages/customcss/Custom.css'


class InvitationMessage extends React.Component{
constructor(props)
{
super(props)
this.state={
    Profilelinks:JSON.parse(localStorage.getItem("BulkLinks")),
    UserMessage:""
    
}
}

HandleUserMessage=(e)=>{

 
  localStorage.setItem("Invite_Message",this.state.UserMessage)
  this.props.seValue()
  this.props.setTab()
  
  
}

render(){

return(


    <>
    <div className="p-4 mb-3 text-light">
        <InfoMessage
        TitleLine="Here you can write a custom message to be included in the invites you're sending."
        />
    <div className="p-4 shadow rounded">
    <div className="text-center">
    <h5>Profiles Found In File : {this.state.Profilelinks.length}</h5>

    </div>
    <div className="Ivitiation_Message">
        <lable>Your Message:</lable>
        <textarea
        className="form-control rounded mb-4"
        onChange={(e)=>this.setState({UserMessage:e.target.value})}
        ></textarea>
<div className="mt-2">
              <button
                className="btn btn-primary hvr-grow"
              
                onClick={(e) => this.HandleUserMessage(e)}
                id="Continue_Btn_1"
              >
                Continue
              </button>
             
            </div>
    </div>

    </div>

    </div>
    </>
)

}

}

export default InvitationMessage