import React, { useState } from 'react';
import { Chart } from 'chart.js';
import ReactEcharts from 'echarts-for-react';

const Donutchart = () => {

function getData(){
var credsObj=JSON.parse(localStorage.getItem("Creds_Detail"));
return credsObj
}
var colorPalette = ['#BD9577', '#7E748D', '#749EAB']
    const option = {
        title : {
          text: 'Total Credits',
          subtext: 'Summary',
          x:'center',
          textStyle: {
            color: "#C2C2C2",
          },
        },
        tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          textStyle: {
            color: "#C2C2C2",
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['Balance','Bought','Spent'],
          textStyle: {
            color: "#C2C2C2",
          },
        },
        series : [
          {
          name: 'Expendetaure',
          type: 'pie',
          radius : '55%',
          center: ['50%', '60%'],
          data:[
            {value:getData().CredsCount, name:'Balance'},
            {value:getData().credsBought, name:'Bought'},
            {value:getData().credSpent, name:'Spent'},
           
          ],
          itemStyle: {
            emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            
            }
          }
          }
        ],
        color:colorPalette
      };
    
      
      function onChartReady(echarts) {
        console.log('echarts is ready', echarts);
      }
             
      function onChartLegendselectchanged(param, echarts) {
        console.log(param, echarts);
      };
    
      return (
        <>
          <ReactEcharts
            option={option}
            style={{ height: 400 }}
            onChartReady={onChartReady}
            onEvents={{              
              'legendselectchanged': onChartLegendselectchanged
             }}
          />        
        </>
      );
    };




export default Donutchart;