import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader';
import { GetUserData } from '../../Utils/GlobalFunctions';
import ProfileV1 from './ProfileV1'
import dateFormat from "dateformat";
import {PackageDetails,CreditsDetails} from '../../../WebApp/Utils/DummyData'
import '../../../WebApp/Css_Files/Checkout_Modal.css'
import { Cancel_Subscription } from '../../../WebApp/Utils/ApiCalls';
import PricingModal from '../../../WebApp/Components/PricingModal';
import toast from 'react-hot-toast';
export const Subscriptions = () => {
const [subscriptionID, setsubscriptionID]=useState("")
const[TransacID,setTransacID]=useState("")
const [NextBillingData, setNextBillingDate]=useState("")
const [paidUser, setpaidUser]=useState(false)
const [packageDetails, setpackageDetails]= useState({})
const [UserTransactions, setUserTransactions]=useState([])
const[Pricing_toggle, setPricing_Toggle]=useState(false)
const[DisableBtn, setdisableBtn]=useState("")
useEffect(() => {
SettingData()
}, [])
const SettingData=()=>{
  var UserSubsription= GetUserData().PurchasingDetails.Subscription
  setsubscriptionID(UserSubsription.user_sub_ID)
  if(UserSubsription.TransDetail!==undefined){
  setTransacID(UserSubsription.TransDetail.Trans_GUID)}
  var UserTrans=GetUserData().PurchasingDetails.Transactions
  UserTrans.forEach((item)=>{
      if(item.Trans_Type=="subscription"){
   
          PackageDetails.forEach((innerItem)=>{
              if(innerItem.PriceID==item.Trans_Product ){
                  item["ProductName"]=innerItem.PackageName+" Plan"
                  item["ProductPrice"]=innerItem.PackagePrice
                 
              }
          })
      }
      else{
          CreditsDetails.forEach((innerItem)=>{
              if(innerItem.PriceID==item.Trans_Product ){
                  item["ProductName"]=innerItem.PackageName
                  item["ProductPrice"]=innerItem.PackagePrice
              }
          })
      }
      console.log(UserTrans)
  })
  setUserTransactions(UserTrans)
  
  if(UserSubsription.user_subscription!=="Free")
  {
      
      PackageDetails.forEach(item => {
          if(item.PackageName==UserSubsription.user_subscription)
          {
              setpackageDetails(item)
          }
      });
  
      var Subdate= UserSubsription.subscription_date
      
      var NextMonth= new Date((new Date(Subdate)).getTime() + 30*24*60*60*1000)
      NextMonth=dateFormat(NextMonth, "dddd, mmmm dS, yyyy")
      
      setNextBillingDate(NextMonth)
      setpaidUser(true)
  }
   else{
    setpaidUser(false)
   }
}
const cancelSub=()=>{
  setdisableBtn("DisabledButton")
var response=Cancel_Subscription(subscriptionID,TransacID)
  toast.promise(
    response,
    {
      loading: 'Please Wait',
      success: (data) => {
        if(data.Status==="Success"){
          var UserDetails=GetUserData()
          UserDetails.PurchasingDetails=data.PurchasingDetails
          localStorage.setItem("User_Data", JSON.stringify(UserDetails))
       
        SettingData()
        setdisableBtn("")
        return `Subscription canceled succesfully`
        }
     },
      error: (err) => `Something Went Wrong Please Try Again`,
    },
    {
      style: {
        minWidth: '250px',
      },
      success: {
        duration: 5000,
        
      },
    }
  );
  
   
}
  return (
    

      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText="Subscriptions & Transactions"
              Breadcrumb={[
               
                { name: "Subscriptions & Transactions", navigate: "" },
              ]}
            />
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                      {/* <Tab eventKey="Billing" title="Billing"> */}
                        <div className="tab-pane active show" id="billings">
                          <div className="body">
                            <h6>Current Plan</h6>
                            <div className="payment-info">
                              <h3 className="payment-name">
                                <i className="fa fa-paypal"></i> {GetUserData().PurchasingDetails.Subscription.user_subscription}
                              </h3>
                             
                            {
                            
paidUser?<>
                            <span>Next billing charged {packageDetails.PackagePrice}</span>
                              <br />
                              <em className="text-muted">
                                Autopay on {NextBillingData}
                              </em>
                              </>
                            :<></>  
                            }
                              
                            
{paidUser?
                                <button type="button" className={"btn btn-danger edit-payment-info d-block ml-3" + DisableBtn } onClick={()=>cancelSub()}>
                              Cancel
                            </button>
                            :
                            <></>
}
                              <button type="button" className="btn btn-primary edit-payment-info d-block" onClick={()=>setPricing_Toggle(true)}>
                              Change Plan
                            </button>
                          
                            </div>
                            {/* <p className="margin-top-30">
                              <a>
                                <i className="fa fa-plus-circle"></i> Add
                                Payment Info
                              </a>
                            </p> */}
                          </div>

                          <div className="body">
                            <h6>Billing History</h6>
                            <table className="table billing-history">
                              <thead className="sr-only">
                                <tr>
                                  <th>Plan</th>
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  UserTransactions.length==0?
                                  <tr><td><h3 className='text-center'>No Transactions</h3></td></tr>:
UserTransactions.map((item, key)=>{
return(<tr key={key}>
                                  <td>
                                    <h3 className="billing-title">
                                      {item.ProductName}
                                      {item.Trans_Type=="subscription"&& item.Trans_Status=="Verified"?
                                       <span className='text-success'>Active</span>
                                      :
                                      item.Trans_Type=="payment"&& item.Trans_Status=="Verified"?
                                      <span className='text-success'>Success</span>
                                      :
                                      item.Trans_Type=="subscription"&& item.Trans_Status=="Cancelled"?
                                      <span className='text-danger'>Cancled</span>
                                     :
                                     item.Trans_Type=="payment"&& item.Trans_Status=="Failed"?
                                     <span className='text-danger'>Failed</span>
                                     :
                                     item.Trans_Type=="subscription"&& item.Trans_Status=="New"?
                                     <span className='text-warning'>Pending</span>
                                    :
                                    item.Trans_Type=="payment"&& item.Trans_Status=="New"?
                                    <span className='text-warning'>Pending</span>
                                    :
                                      <></>
                                      }
                                      
                                    </h3>
                                    <span className="invoice-number">
                                       TID: <small>{item.Trans_GUID}</small>
                                        <br/>
                                      </span>
                                    <span className="text-muted">
                                      Charged at {item.Trans_Time}
                                    </span>
                                  </td>
                                  <td className="amount">{item.ProductPrice}</td>
                                  {/* <td className="action">
                                    <a>View</a>
                                  </td> */}
                                </tr>)
})

                                
                             }
                              </tbody>
                            </table>
                            {/* <button type="button" className="btn btn-primary">
                              Update
                            </button>
                            <button type="button" className="btn btn-default">
                              Cancel
                            </button> */}
                          </div>
                        </div>
                      {/* </Tab> */}                   
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PricingModal
                      ModalState={Pricing_toggle}
                      ModalStateCallback={() => setPricing_Toggle(false)}
                    />
      </div>
   

 
  )
}
