import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import React from 'react';
import PageHeader from '../PageHeader';
import GetToken from '../Linkedin/GetToken';

import QueryScrapper from './QueryScrapper'
import '../Pages/customcss/Custom.css'
import ScrapeSummary from './ScrapperSummary';
import QueryScrapeSummary from './QueryScrapeSummary';
class ScrapeFromData extends React.Component{

constructor(props){
    super(props)
    this.state={

tabIndex:0,
isDiabled0:false,
isDiabled1:false,
isDiabled2:false
    }
}
componentWillMount(){
   if(JSON.parse(localStorage.getItem("authentication_param")) !==null && JSON.parse(localStorage.getItem("User_Query"))===null){
    this.setState({tabIndex:1})    
   }
   if(JSON.parse(localStorage.getItem("authentication_param")) !==null && JSON.parse(localStorage.getItem("User_Query"))!==null){
    this.setState({tabIndex:2})    
   }
   if(JSON.parse(localStorage.getItem("authentication_param")) ===null){
 
   this.setState({isDiabled0:true}) 
   }
   if(JSON.parse(localStorage.getItem("User_Query")) ===null){
    this.setState({isDiabled1:true}) 
   }

}
componentDidMount(){
  if(this.state.tabIndex===0){
   this.ClearSearch() 
  }

}
SetTabValue=()=>{
  this.setState({isDiabled0:false}) 
}
SetTabIndex=()=>{
    this.setState({tabIndex:1})
}
SetTabIndex2=()=>{
  this.setState({tabIndex:2})
}
SetTabValue2=()=>{
  this.setState({isDiabled1:false}) 
}
ClearSearch(){
localStorage.removeItem("User_Query")
}
render(){

    return(
<>
<div className="text-left p-3">
    <PageHeader
          HeaderText="Linkedin Profile Scrapper from data"
          Breadcrumb={[
            { name: "Linkedin", navigate: "LinkedInMenu" },
            { name: "Profile Scrapper", navigate: "" },
          ]}
        />
    </div>
<Tabs     selectedIndex={this.state.tabIndex} onSelect={(tabIndex) =>  this.setState({ tabIndex })}
 >
    <TabList>
      <Tab>Connect Linkedin Account</Tab>
      <Tab disabled={this.state.isDiabled0}>Profiles To Scrape</Tab>
      <Tab disabled={this.state.isDiabled1}> Summary </Tab>
    
    </TabList>

    <TabPanel>
      <GetToken
         setTab={()=>this.SetTabIndex()}
         seValue={()=>this.SetTabValue()}
         />
    </TabPanel>
    <TabPanel>
     <QueryScrapper
  setTab={()=>this.SetTabIndex2()}
  seValue={()=>this.SetTabValue2()}
     />
    </TabPanel>
    <TabPanel>
     <QueryScrapeSummary
     UserQuery={JSON.parse(localStorage.getItem("User_Query"))}
     OueryType="Queries"
     Credits={20}
     />
    </TabPanel>
    
  </Tabs>
  </>
    )
};

}
export default ScrapeFromData;