import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import storageChanged from "storage-changed";
import Login from "./Dashboard/screens/Login";
import dashboard from "./Dashboard/screens/Dashbord/Dashbord";
import NavbarMenu from "./Dashboard/components/NavbarMenu";
import registration from "./Dashboard/screens/Auth/Registration";
import lockscreen from "./Dashboard/screens/Auth/Lockscreen";
import forgotpassword from "./Dashboard/screens/Auth/ForgotPassword";
import page404 from "./Dashboard/screens/Auth/Page404";
import page403 from "./Dashboard/screens/Auth/Page403";
import page500 from "./Dashboard/screens/Auth/Page500";
import page503 from "./Dashboard/screens/Auth/Page503";
import ProfileV2Page from "./Dashboard/screens/Pages/ProfileV2";
import Comingsoon from "./Dashboard/components/Linkedin/comingsoo";
import maintanance from "./Dashboard/screens/Pages/Maintanance";
import Widget from "./WebApp/App";
import LinkedinMenu from "./Dashboard/components/Linkedin/LinkedInMenu";
import ScrapeFromData from "./Dashboard/components/Linkedin/ScrapeFromData";
import ProfileScrape from "./Dashboard/components/Linkedin/ProfileScrape";
import { DecryptVal } from "./Dashboard/Utils/GlobalFunctions";
import EmployeeScrape from "./Dashboard/components/Linkedin/EmployeeScrape";
import NetworkBooster from "./Dashboard/components/Linkedin/NetworkBooster";
import ResetPassword from "./Dashboard/screens/Auth/ResetPassword";
import LeadsDashboard from "./Dashboard/components/MyLeads/LeadsDashboard";
import { Payments } from "./Dashboard/screens/Pages/Payments";
import { Subscriptions } from "./Dashboard/screens/Pages/Subscriptions";


// import AllPagesLink from './LandingPage/AllPagesLink'
window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      CredsObj: JSON.parse(localStorage.getItem("Creds_Detail")),
      UserObj: JSON.parse(localStorage.getItem("User_Data")),
    };
  }
  componentWillMount() {
    storageChanged("local", {
      eventName: "DashChangeInStorage",
    });
    window.addEventListener("DashChangeInStorage", (event) => {
      if (event.detail.key === "Creds_Detail") {
        this.setState({
          CredsObj: JSON.parse(event.detail.value),
        });
      } else if (event.detail.key === "User_Data") {
        this.setState({
          UserObj: JSON.parse(event.detail.value),
        });
      }
    });
  }
  componentWillUnmount() {
    window.removeEventListener("DashChangeInStorage", () => {});
  }
  render() {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    const activeKey1 = res;
    var LoggedInValue = localStorage.getItem("LoggedStatus");
    if (LoggedInValue === "true") {
      return (
        <div id="wrapper">
          <NavbarMenu
            history={this.props.history}
            activeKey={activeKey1}
            credsDetails={this.state.CredsObj}
            UserObj={this.state.UserObj}
          />
          <div id="main-content">
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={dashboard}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={dashboard}
              />
              
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Widget`}
                component={Widget}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/profile`}
                component={() => <ProfileV2Page UserObj={this.state.UserObj} />}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/LinkedInMenu`}
                component={() => <LinkedinMenu />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/ProfileScrape`}
                component={ProfileScrape}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/ScrapeFromData`}
                component={ScrapeFromData}
              />
               <Route
                exact
                path={`${process.env.PUBLIC_URL}/EmployeeScrape`}
                component={EmployeeScrape}
              />
                 <Route
                exact
                path={`${process.env.PUBLIC_URL}/NetworkBooster`}
                component={NetworkBooster}
              />
                  <Route
                exact
                path={`${process.env.PUBLIC_URL}/Leads`}
                component={LeadsDashboard}
              />
                   <Route
                exact
                path={`${process.env.PUBLIC_URL}/Payments`}
                component={Payments}
              />
                   <Route
                exact
                path={`${process.env.PUBLIC_URL}/Subscription`}
                component={Subscriptions}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/comingsoon`}
                component={Comingsoon}
              />
              <Route exact component={page404} />
            </Switch>
          </div>
        </div>
      );
    } else {
      var rememberStatus = localStorage.getItem("RememberStatus");
      if (rememberStatus === "true" && rememberStatus !== undefined) {
        return (
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={lockscreen}
            />
            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={AllPagesLink} /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={lockscreen}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={forgotpassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page404`}
              component={page404}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page403`}
              component={page403}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page500`}
              component={page500}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page503`}
              component={page503}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/registration`}
              component={registration}
            />
            <Route exact path={`registration`} component={registration} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/lockscreen`}
              component={lockscreen}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/maintanance`}
              component={maintanance}
            />
            <Route component={lockscreen} />
          </Switch>
        );
      } else {
        return (
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Login}
            />
            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={AllPagesLink} /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={forgotpassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/reset`}
              component={ResetPassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page404`}
              component={page404}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page403`}
              component={page403}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page500`}
              component={page500}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page503`}
              component={page503}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/registration`}
              component={registration}
            />
            <Route exact path={`registration`} component={registration} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/maintanance`}
              component={maintanance}
            />
            <Route component={Login} />
          </Switch>
        );
      }
    }
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
});
const DashComp = () => {
  var res = window.location.pathname;
  var baseUrl = process.env.PUBLIC_URL;
  baseUrl = baseUrl.split("/");
  res = res.split("/");
  res = res.length > 0 ? res[baseUrl.length] : "/";
  res = res ? res : "/";
  const activeKey1 = res;
  return <></>;
};
export default withRouter(connect(mapStateToProps, {})(App));
