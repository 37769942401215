import coin1 from '../images/coins.png'
import coin2 from '../images/money.png'
import coin3 from '../images/coins2.png'
import memb1 from '../images/paper-plane.png'
import memb2 from '../images/travelling.png'
import memb3 from '../images/rocket.png'
import React from "react"

export const CountriesData=[
    {
        "Code": "103121230",
        "Name": "Philippines",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "101022442",
        "Name": "Pakistan",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "102927786",
        "Name": "Peru",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105072130",
        "Name": "Poland",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "100364837",
        "Name": "Portugal",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "102986501",
        "Name": "Pennsylvania, United States",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "90009642",
        "Name": "Pune/Pimpri-Chinchwad Area",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105015875",
        "Name": "France",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "106383538",
        "Name": "Paris, Île-de-France, France",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105081143",
        "Name": "Paraná, Brazil",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "103671728",
        "Name": "Pune, Maharashtra, India",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105871508",
        "Name": "São Paulo, Brazil",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "102306254",
        "Name": "Punjab, Pakistan",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "90000079",
        "Name": "Portland, Oregon Metropolitan Area",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "90009574",
        "Name": "Greater São Paulo Area",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "100808673",
        "Name": "Panama",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105926908",
        "Name": "Punjab, India",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105245958",
        "Name": "Puerto Rico",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "101667098",
        "Name": "Pernambuco, Brazil",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "102203735",
        "Name": "Provence-Alpes-Côte d'Azur, France",
        "Sub_Title": "",
        "Image": ""
    }
];




export const IndustryData=[
    {
        "Code": "50",
        "Name": "Architecture & Planning",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "23",
        "Name": "Food Production",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "9",
        "Name": "Law Practice",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "126",
        "Name": "Media Production",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "13",
        "Name": "Medical Practice",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "35",
        "Name": "Motion Pictures and Film",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "87",
        "Name": "Package/Freight Delivery",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "146",
        "Name": "Packaging and Containers",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "61",
        "Name": "Paper & Forest Products",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "39",
        "Name": "Performing Arts",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "15",
        "Name": "Pharmaceuticals",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "131",
        "Name": "Philanthropy",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "136",
        "Name": "Photography",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "117",
        "Name": "Plastics",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "107",
        "Name": "Political Organization",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "67",
        "Name": "Primary/Secondary Education",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "83",
        "Name": "Printing",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "105",
        "Name": "Professional Training & Coaching",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "102",
        "Name": "Program Development",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "79",
        "Name": "Public Policy",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "98",
        "Name": "Public Relations and Communications",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "78",
        "Name": "Public Safety",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "82",
        "Name": "Publishing",
        "Sub_Title": "",
        "Image": ""
    },
    {
        "Code": "106",
        "Name": "Venture Capital & Private Equity",
        "Sub_Title": "",
        "Image": ""
    }
];


export const CompaniesData=[
        {
            "Code": "1044",
            "Name": "PwC",
            "Sub_Title": "Company • Information Technology & Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQFgGCanagq95g/company-logo_400_400/0/1625142822638?e=1660176000&v=beta&t=VQUcYMDnrGr0aNlAVFlWPRb7EqyVvFjn-RjFiq6dRUg"
        },
        {
            "Code": "1431",
            "Name": "PepsiCo",
            "Sub_Title": "Company • Food & Beverages",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQE9stRV5JkCFQ/company-logo_400_400/0/1519856314179?e=1660176000&v=beta&t=9nOj92P2qTcl7v45aVb5aVKRNyzP_d1jQ7--mxBVTHc"
        },
        {
            "Code": "1185",
            "Name": "Pfizer",
            "Sub_Title": "Company • Pharmaceuticals",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQH43nk9kEqupw/company-logo_400_400/0/1625150326372?e=1660176000&v=beta&t=fhN3hSP_LCc1YOTv_IeNKGNOKJ7EiEOZdyAvDJxl6ek"
        },
        {
            "Code": "1090",
            "Name": "Philips",
            "Sub_Title": "Company • Hospital & Health Care",
            "Image": "https://media-exp1.licdn.com/dms/image/C510BAQG4O5wCG6zf2Q/company-logo_400_400/0/1519855869410?e=1660176000&v=beta&t=mmXz_VwRHUsA4s751wRcvRs6arlhOBBh9K2UHAqP-F4"
        },
        {
            "Code": "1116",
            "Name": "Procter & Gamble",
            "Sub_Title": "Company • Consumer Goods",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQHadxuyf9RhdA/company-logo_400_400/0/1624365567580?e=1660176000&v=beta&t=cbQLbnqre6b07l6Lmjv6ehpKDYcwmmBWluICbD1xxNQ"
        },
        {
            "Code": "4898",
            "Name": "Parsons Corporation",
            "Sub_Title": "Company • Information Technology & Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQFWGwgrezTu7A/company-logo_400_400/0/1625239306696?e=1660176000&v=beta&t=ebu9x38-FU4E6xSLOuaDdWZsaaxEf6QJ_YsbLtldz1M"
        },
        {
            "Code": "5034",
            "Name": "Persistent Systems",
            "Sub_Title": "Company • Information Technology & Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQGctyhyN9TCDQ/company-logo_400_400/0/1643009065294?e=1660176000&v=beta&t=FHIlk4dAD-Jukfnt2rkPSsK8C7gndnrmMyJ1joh3rm0"
        },
        {
            "Code": "1482",
            "Name": "PayPal",
            "Sub_Title": "Company • Internet",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQGgcRYcsEA95g/company-logo_400_400/0/1593617920398?e=1660176000&v=beta&t=0hB1GYvahKB4O9I4I3bJVcI2aB9lmdVrsOAORQLBZRk"
        },
        {
            "Code": "2340144",
            "Name": "Paytm",
            "Sub_Title": "Company • Financial Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQEMfum38pUSZg/company-logo_400_400/0/1650348399294?e=1660176000&v=beta&t=pOEW34vAM8NQXsKptBqUY03uHBi607QB_jk_Xtgq2VM"
        },
        {
            "Code": "2934",
            "Name": "S&P Global",
            "Sub_Title": "Company • Information Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQGeVH2if33u7w/company-logo_400_400/0/1646084010165?e=1660176000&v=beta&t=awVQjHxbx0XhauudL5D8zE1zfTrjKyhanlDx-wkrBOI"
        },
        {
            "Code": "1092713",
            "Name": "PwC India",
            "Sub_Title": "Company • Management Consulting",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQHTjFhFQtmj9w/company-logo_400_400/0/1626360566762?e=1660176000&v=beta&t=hrTz90n95UjyN43gM5Lt5jfUD5BHqXPN4wOm_g3IERU"
        },
        {
            "Code": "4430",
            "Name": "PPD",
            "Sub_Title": "Company • Research",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQHYo6yJGKzgsQ/company-logo_400_400/0/1638971613413?e=1660176000&v=beta&t=d5CZTfkXWDDH5rhuQeOySa2wB_sk42XnCqMafmnf9UI"
        },
        {
            "Code": "479080",
            "Name": "A.P. Moller - Maersk",
            "Sub_Title": "Company • Transportation/Trucking/Railroad",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQFxaOJ8VzplhA/company-logo_400_400/0/1616162064676?e=1660176000&v=beta&t=gttLmVVhN5qLF6KKTrXECDpHrXHDVdDR0FWVw6sDCSw"
        },
        {
            "Code": "1935",
            "Name": "PTC",
            "Sub_Title": "Company • Computer Software",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQG_8IYItHh_Tg/company-logo_400_400/0/1625070846114?e=1660176000&v=beta&t=X_ErKIXhm-mSjc6E54XdockruraWqVAy5ETh2ICu46g"
        },
        {
            "Code": "1067",
            "Name": "J.P. Morgan",
            "Sub_Title": "Company • Financial Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQEVXhQs3mC56Q/company-logo_400_400/0/1630056226107?e=1660176000&v=beta&t=XQwpAdqT9WSul1szrnTlmW0XGTISjq4Ph7uaTXIHSsQ"
        },
        {
            "Code": "4167",
            "Name": "PNC",
            "Sub_Title": "Company • Financial Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQGZzrspYyz9oQ/company-logo_400_400/0/1531496983497?e=1660176000&v=beta&t=rqec7b_HHq-vn6sqy5n3lBDFekArIa-CQZ3GcQYS5Nc"
        },
        {
            "Code": "165597",
            "Name": "PUMA Group",
            "Sub_Title": "Company • Retail",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQHKvdFDZKXvTA/company-logo_400_400/0/1626189191023?e=1660176000&v=beta&t=Fjp5df6vYdQGUYxpw8OEEpEqRnp8OOQktE-4AQ3Pm8U"
        },
        {
            "Code": "165464",
            "Name": "Photon",
            "Sub_Title": "Company • Information Technology & Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4D0BAQGtCLX3g29FIA/company-logo_400_400/0/1553270488393?e=1660176000&v=beta&t=hyS2q7-BFxRwdy3XYhBk5MGPnvRD-QBA4E3Cw5w2FzI"
        },
        {
            "Code": "1025",
            "Name": "Hewlett Packard Enterprise",
            "Sub_Title": "Company • Information Technology & Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C4E0BAQH6qdm7VJ5BPA/company-logo_400_400/0/1593697162881?e=1660176000&v=beta&t=tETJFJkIHbNWx0fihyktsstKoOY2yOReI1BFGtGN_c8"
        },
        {
            "Code": "554421",
            "Name": "Pine Labs",
            "Sub_Title": "Company • Information Technology & Services",
            "Image": "https://media-exp1.licdn.com/dms/image/C560BAQFcQtvpZs3Ojw/company-logo_400_400/0/1650947889223?e=1660176000&v=beta&t=69NqYCCsqRk-Cq7bd786VzCqYIOsuuOz3elPAFUjsqI"
        }
    
];

export const Users={
    "Return_Count": "10",
    "FinalData": [
        {
            "User_MemberId": "773666615",
            "User_Name": "Hamza Tatheer",
            "Designation": "Full Stack Developer",
            "Basic_About": "4 shared connections",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4D03AQGZW8itCdRumQ/profile-displayphoto-shrink_800_800/0/1591559414592?e=1657756800&v=beta&t=0ZLrvqgWpc0yQo62pP_J1eESIKJNMjgoTArrb_75k4g",
            "User_Location": "Pakistan",
            "User_NavigationUrl": "https://www.linkedin.com/in/hamza-tatheer-89b123197?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAC4dNzcBCcit-Dqi12vjZbeWg6SY4vCZwck"
        },
        {
            "User_MemberId": "667784211",
            "User_Name": "Abdul Rafay Salfi",
            "Designation": "Full Stack Developer at GagaMuller Group | ASP.Net | Node.Js | MongoDb | React.Js | Python",
            "Basic_About": "10 shared connections • 232 followers",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4D03AQGUb1sqdSzO9A/profile-displayphoto-shrink_800_800/0/1625325851509?e=1657756800&v=beta&t=K7BXQcehwAKGz5V8yGKad-htABofu3Wt4lq6hz96KOk",
            "User_Location": "Rawalpindi",
            "User_NavigationUrl": "https://www.linkedin.com/in/abdulrafaysalfi?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACfNlBMBWC-yv3wcjNBXFZyBQFRqN8wPVA0"
        },
        {
            "User_MemberId": "468561832",
            "User_Name": "Muhammad Tayyab",
            "Designation": "Full Stack Engineer at F3 Technologies",
            "Basic_About": "11 shared connections",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C5603AQFq0Bp4iHhnkA/profile-displayphoto-shrink_800_800/0/1622437366703?e=1657756800&v=beta&t=Un63ZSE0I8HfQnTtd7ydMdRm9r8ClvhCfPSByJdsCB0",
            "User_Location": "Rawalpindi",
            "User_NavigationUrl": "https://www.linkedin.com/in/satti-574b06110?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABvtr6gBAedgU9BbPoGEtkfUU6aqsKdOReU"
        },
        {
            "User_MemberId": "625153004",
            "User_Name": "Bilal Chaudhary",
            "Designation": "Full Stack Engineer at Hashe Computer Solutions (Pvt) Ltd.",
            "Basic_About": "16 shared connections • 997 followers",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4E03AQGDpHWYE-_soA/profile-displayphoto-shrink_800_800/0/1618264666487?e=1657756800&v=beta&t=NJ-D-H3FqLEAc8T9eXZdalFVXI-z18AjYp-o8R2BuZg",
            "User_Location": "Lahore District",
            "User_NavigationUrl": "https://www.linkedin.com/in/chbilalnaseer?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACVDE-wBZ69IkDo0NBMe1zf_q-NoA9H3QM4"
        },
        {
            "User_MemberId": "409018428",
            "User_Name": "Hashir Rehman Khan",
            "Designation": "Full Stack Engineer | Ruby | Rails | React | DevOps",
            "Basic_About": "Provides services - Application Development, Web Development, SaaS Development, IT Consulting, Technical Support, Leadership Development, Mobile Application Development, Custom Software Development, Cloud Application Development, Database Development",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4E03AQGJKcs55GG6tA/profile-displayphoto-shrink_800_800/0/1635678410985?e=1657756800&v=beta&t=qGiGga2K5Q_oCcl3Xq04al7iasOW8fbG9JrLSzz6-6w",
            "User_Location": "Lahore District",
            "User_NavigationUrl": "https://www.linkedin.com/in/hashir-rehman-khan-590b90b4?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABhhIDwBJvYn18ZcUoewilLspSNizBj-2Rk"
        },
        {
            "User_MemberId": "496977019",
            "User_Name": "Talha Fiaz",
            "Designation": "React Native | React | Next | Node | JavaScript | TypeScript | Mongo | Postgres | Sql | Firebase | Full Stack",
            "Basic_About": "Provides services - Mobile Application Development, Android Development, Application Development, iOS Development, Web Development",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4D03AQHkEVRx7kx9FQ/profile-displayphoto-shrink_800_800/0/1643128009039?e=1657756800&v=beta&t=jgHEQib5yBrrS64So4mj-tiTVjUwtUlPphQde5hjiZQ",
            "User_Location": "Rawalpindi",
            "User_NavigationUrl": "https://www.linkedin.com/in/talhafiaz09?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAB2fRHsB4GUhqIgp9FYWSrI519vgD9yeaBA"
        },
        {
            "User_MemberId": "748242477",
            "User_Name": "Hadayat Niazi",
            "Designation": "Laravel Full stack Developer",
            "Basic_About": "9 shared connections • 943 followers",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C5603AQHUy5AGHugGNA/profile-displayphoto-shrink_800_800/0/1624445330367?e=1657756800&v=beta&t=xNp4y61w-fQ2tPB5pBuCHqlKXn8K8IfBWdp_x31Ri7w",
            "User_Location": "Punjab, Pakistan",
            "User_NavigationUrl": "https://www.linkedin.com/in/hadiniazi?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACyZRi0BhLBMQd57_xV3zZBdNprFBa6fDow"
        },
        {
            "User_MemberId": "102370249",
            "User_Name": "Ayat Ullah Khan",
            "Designation": "Full Stack Engineer at Nizi Solutions.",
            "Basic_About": "Provides services - Web Development, Mobile Application Development, Web Design, Android Development, Application Development, Business Analytics, Cloud Application Development, Cloud Management, Custom Software Development, Database Development",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C5603AQG4WS-tYJtXlw/profile-displayphoto-shrink_800_800/0/1611726827411?e=1657756800&v=beta&t=fgHGyxaGC1udB9BO92p2txrFT587CAExAvK_lpok5qk",
            "User_Location": "Islāmābād, Pakistan",
            "User_NavigationUrl": "https://www.linkedin.com/in/ayat-ullah-khan?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAYaC8kBDD8FPQA8_BNjBJmUPF1gWfFtDbE"
        },
        {
            "User_MemberId": "637295797",
            "User_Name": "Sajeel Khawaja",
            "Designation": "Full Stack Developer at AT Tech",
            "Basic_About": "2 shared connections",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4E03AQEgAapHZ7TRgw/profile-displayphoto-shrink_800_800/0/1611356070696?e=1657756800&v=beta&t=rLCow4_JKXHGOhCzAP5mDb1x8atxpiTUSnwMvLVdZFE",
            "User_Location": "Punjab, Pakistan",
            "User_NavigationUrl": "https://www.linkedin.com/in/sajeel-waien?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACX8XLUBhbkMjLC5KCxsu575hkhX-OOXbLc"
        },
        {
            "User_MemberId": "747851978",
            "User_Name": "Faiq Javed",
            "Designation": "Software Engineering Manager at Red Sun IT Services | Full Stack Developer | Freelance Web Developer",
            "Basic_About": "8 shared connections",
            "User_Image": "https://media-exp1.licdn.com/dms/image/C4D03AQHn7f9LQzip2Q/profile-displayphoto-shrink_800_800/0/1602501857427?e=1657756800&v=beta&t=qR8bMnsb7H-9iNbYhsIQqZ1gLlI0xRgx2Xmdawlv2CE",
            "User_Location": "Rawalpindi",
            "User_NavigationUrl": "https://www.linkedin.com/in/faiq-javed?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACyTUMoBYb8hBt2sQ4-hP6Wrdo40ALwqY90"
        }
    ]
}
export const ModalData=
    {

    "ProfileName": "Ahtisham Ali",
    "ProfileImage": "https://media-exp1.licdn.com/dms/image/C5603AQFUYEBf8kz5IA/profile-displayphoto-shrink_800_800/0/1610370182092?e=1658966400&v=beta&t=t80sC3l7GO_P8uc0ibY5cp0IFiQiS8W5JcF-6GnPQGI",
    "BasicDetails": [
        "Team Lead | Sr. Full Stack Developer | Reactjs | Asp.net C# | Nodejs | MS SQL",
        "Bahria University Zenith innovations",
        "Islamabad, Islāmābād, Pakistan500+ connections"
    ],
    "Summary": "I am a Full Stack Developer with expertise in frameworks like ASP.net framework, React-JS, MS SQL Server. I have ample experience in the field of Web Development through working on large to mid-scale projects. This experience of working on large-scale projects also made me a key team player and made me efficient in working in teams.My Goal for my career is to excel more in this field and learn new skills and keep myself updated as I am doing so by facing daily challenges and going to the daily learning phase.My Skillset includesBackend Web API Development(Asp.net + MS SQL)Web Development using flavors of JS(React, VUE)Bootstrap, CSS, Material UI.I also have hands-on experience with SEO Strategies and SEO Techniques",
    "ExperienceList": [
        {
            "img": "https://media-exp1.licdn.com/dms/image/C4E0BAQEqVuZiKiqXvA/company-logo_100_100/0/1641380713700?e=1661385600&v=beta&t=o02sscnQkos9zJr-3H3fbxw-pbFmJkL27NF90361n0k",
            "title": "Team Lead and Sr. Full Stack Developer",
            "subtitle": "Zenith innovations",
            "durration": "Mar 2022 - Present3 mos",
            "descrip": "Currently working on Zoho CRM and Extensions Development of Zoho. Feel Free to contact.",
            "activity": "Islamabad"
        },
        {
            "img": "https://media-exp1.licdn.com/dms/image/C4E0BAQH2peXO-ql63w/company-logo_100_100/0/1519856350096?e=1661385600&v=beta&t=fuF5nigLlHWZfw-bt5GavgCQTGQ6LGJa5hVLfSVsW9U",
            "title": "Full Stack Developer",
            "subtitle": "Hypersoft",
            "durration": "Jul 2020 - Nov 20205 mos",
            "descrip": "",
            "activity": "Rawalpindi, Punjab, Pakistan"
        },
        {
            "img": "https://media-exp1.licdn.com/dms/image/C560BAQHBn6QEDvmkzQ/company-logo_100_100/0/1521617375355?e=1661385600&v=beta&t=jv1F9maHqLhEExCVsBeGnVTBquTt_egggvnQXIChkS4",
            "title": "Junior Software Developer",
            "subtitle": "Next Generation Business Solutions",
            "durration": "Nov 2019 - Jun 20208 mos",
            "descrip": "",
            "activity": "Federal Capial &amp;AJK, Pakistan"
        }
    ],
    "EducationList": [
        {
            "img": "https://media-exp1.licdn.com/dms/image/C4D0BAQHX35AQlY6fHw/company-logo_100_100/0/1651508483105?e=1661385600&v=beta&t=5qUMrHj8vb6vP5-s8MlDkc3j3fzBRX1PDUYeBbJnjfk",
            "title": "Bahria University",
            "subtitle": "Bachelor's degreeBachelor in Information Technology",
            "durration": "Sep 2017 - Feb 2021",
            "descrip": "",
            "activity": "Activities and societies: Former President of Byte in Bahria, IEEE Society",
            "grade": "Grade: CGPA 3.2"
        }
    ],
    "VolunteerList": [],
    "SkillsList": [
        "Front-end Development",
        "Search Engine Optimization (SEO)",
        "REST APIs",
        "Project Management",
        "Web Development",
        "Object-Oriented Programming (OOP)",
        "Microsoft Office",
        "JavaScript",
        "HTML",
        "Cascading Style Sheets (CSS)",
        "Content Management Systems (CMS)",
        "C#",
        "Microsoft Azure",
        "Microsoft SQL Server",
        "sql database",
        ".NET Framework",
        "ASP.NET",
        "React.js",
        "Databases",
        "Management",
        "Leadership",
        "Public Speaking",
        "Customer Service",
        "Back-End Web Development",
        "Database Management System (DBMS)",
        "DotNetDeveloper",
        "API Development",
        "On-Page Optimization",
        "Off-Page SEO"
    ],
    "ContactList": [
        {
            "contactVal": "rajazafar736@gmail.com",
            "contactType": "Email"
        },
        {
            "contactVal": "+923421543537 (Mobile)",
            "contactType": "Phone"
        },
        {
            "contactVal": "https://www.linkedin.com/in/ahtisham-ali-a36b05134",
            "contactType": "LinkedIn"
        },
        {
            "contactVal": "rajazafar736 (SKYPE)",
            "contactType": "IM"
        }
]};
export const PackageDetails=[
    {
    PackageName:"Basic",
    PackageImg:memb1,
    PackagePrice:"$100",
    PriceID:"price_1M7EopCvQ4LojgNp8MxA6CTy",
    Mode:"subscription",
    IncludedFeatures:["Feature1","Feture2","Feature3"],
    ExcludedFeatures:["Feature1","Feture2","Feature3"],
    PurchaseLink:"#",
    DowngradeStatus:false,
    Ribbon:"",
    Credits:"500", 
    },
    {
      PackageName:"Standard",
      PackageImg:memb2,
      PackagePrice:"$200",
      PriceID:"price_1M7G7BCvQ4LojgNp04wJryuU",
    Mode:"subscription",
      IncludedFeatures:["Feature1","Feture2","Feature3","Feature4"],
      ExcludedFeatures:["Feature1","Feture2"],
      DowngradeStatus:true,
      PurchaseLink:"#",
      Ribbon:"Popular",
      Credits:"1200", 
      
      },
      {
        PackageName:"Enterprise",
        PackageImg:memb3,
        PackagePrice:"$500",
        PriceID:"price_1M7G7YCvQ4LojgNpH5eJcZDE",
    Mode:"subscription",
        IncludedFeatures:["Feature1","Feture2","Feature3","Feature4","Feature5","Feature6"],
        ExcludedFeatures:[""],
        PurchaseLink:"#",
        DowngradeStatus:true,
        Ribbon:"",
        Credits:"2000", 
        },
       
    
       ];
 export const CreditsDetails=[
        {
        PackageName:"100 Credits",
        PackageImg:coin1,
        PackagePrice:"$10",
        Credits:"100", 
        Mode:"payment",
        PriceID: "price_1M6pyVCvQ4LojgNpAMFePOdz",
        // PriceID:"price_1M7EopCvQ4LojgNp8MxA6CTy",
        ProductID:"Basic_112233" ,   
        PurchaseLink:"https://linkedinlead.alphamechanicalservices.net/",        
        },
        {
            PackageName:"550 Credits",
            PackageImg:coin2,
            PackagePrice:"$50",
            Credits:"550",        
            PriceID: "price_1M6pzBCvQ4LojgNp6UpCDwkX", 
            ProductID:"Advanced_112233",
            Mode:"payment",
            PurchaseLink:"https://linkedinlead.alphamechanicalservices.net/",        
            },
            {
                PackageName:"1200 Credits",
                PackageImg:coin3,
                PackagePrice:"$100",
                Credits:"1200",     
                Mode:"payment",   
                PriceID: "price_1M6pzWCvQ4LojgNpSKkSkHqu", 
                ProductID:"Advanced_112233",
                PurchaseLink:"https://linkedinlead.alphamechanicalservices.net/",        
                },
           
        
           ];
export const Basic_Function_Cost=10;
export const Advance_Function_Cost=20;
export const Mini_Function_Cost=5;
export const ZeroCredits=0;


export const DummySearchRecords=[
    {
        search:"Full Stack",
        Date:"6/10/22",
        

    },
    {
        search:"CEO",
        Date:"6/10/22",

    },
    {
        search:"Front End Developer",
        Date:"6/10/22",

    },
    {
        search:"Project Manager",
        Date:"6/10/22",

    },
    {
        search:"Full Stack",
        Date:"6/10/22",

    },
    {
        search:"Full Stack",
        Date:"6/10/22",

    },
]