import React from "react";
import { GettingMultipleProfileData } from "../../../WebApp/Utils/ApiCalls";
import { DownloadCSV, FilteExportData, Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import Api_Loader from "../API_Loader";
import SAVE from '../../../WebApp/images/save.png'
import { SaveLeads } from "../SaveLeads";
class AdvancedScrape extends React.Component{

constructor(props){
    super(props)
    this.state={
        isLoading:true,
        ExportData_CSV:[],
        ExportData_Json:[],
        SaveData:[],
        
    }
}
componentDidMount(){
               
        var response = GettingMultipleProfileData(this.props.Links);
        response
          .then((data) => {
        
            if (data != null) {
    
    
              this.setState({ ExportData_Json: data });
           var FilteredData= FilteExportData(data)
           this.setState({SaveData:FilteredData})
              var arrayFinal = [];
              data.forEach((item) => {
                var flattened = {};
                Flatten(item, flattened, "");
                arrayFinal.push(flattened);
              });
              this.setState({ ExportData_CSV: arrayFinal });
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            alert(error)
            this.setState({ isLoading: false });
          });
     

}

render(){
    return(


        <div className="ExitDiv">
            {this.state.isLoading ? (
              <Api_Loader/>
            ) : (
              <div className="">                       
                  <h6 className="mb-3">Select Your Action...</h6>

                  <DownloadCSV
                    fileName={"Advanced Scrape"}
                    dataDetailFormatted={this.state.ExportData_CSV}
                    JsonData={this.state.ExportData_Json}
                    Deduct={false}
                  />
              <SaveLeads LeadsData={this.state.SaveData}/>

                
             </div>
            )}
          </div>
    )
}




}


export default AdvancedScrape