import React from "react"

import "../Css_Files/Login_Modal.css";
import { Authenticate_User } from "../Utils/ApiCalls";
import { setWithExpiry } from "../Utils/LocalStorage_Handeling";
import toast from 'react-hot-toast';


function Linkedin_Form(props) {

  async function Save_Login() {
    var user_email = document.getElementById("Email");
    var user_pass = document.getElementById("password");
    var signInBtn=document.getElementById("signInBtn");

    signInBtn.disabled = true;
    signInBtn.innerText = 'Please Wait...';
        var user_credentials = {
        username: user_email.value,
        password: user_pass.value,
    };

    localStorage.setItem("user_details", JSON.stringify(user_credentials));
    var response_promise = Authenticate_User(
        user_email.value,
        user_pass.value
    );


    toast.promise(
        response_promise,
        {
          loading: 'Please Wait',
          success: (data) => {
            if(data.includes("delete")){
              signInBtn.disabled = false;
              signInBtn.innerText = 'Sign In';
              return "Invalid Credentials";    
            }
            else if(data.includes("AlreadyLinked")){
              signInBtn.disabled = false;
              signInBtn.innerText = 'Sign In';
              return "This LinkedIn Account Is Already Associated to Another User";
          }
            else{
              setWithExpiry("authentication_param",data,8600000);
              signInBtn.disabled = true;
              signInBtn.innerText = 'Welcome';
              props.setTab()
              return 'Successfully Logged In' 
            }
         },
          error: (err) => `Something Went Wrong Please Try Again`,
        },
        {
          style: {
            minWidth: '250px',
          },
          success: {
            duration: 5000,
            icon: '🔥',
          },
        }
      );




}

function Toggle_Password() {
  var eye_icon = document.getElementById("togglePassword");

  var Pass_Type = document.getElementById("password");
  if (Pass_Type.type === "password") {
    Pass_Type.type = "text";
    eye_icon.classList.remove("fa-eye");
    eye_icon.classList.add("fa-eye-slash");
  } else {
    Pass_Type.type = "password";
    eye_icon.classList.add("fa-eye");
    eye_icon.classList.remove("fa-eye-slash");
  }
}






  return (
    <div className=" pt-5 pb-5 pl-2 pr-2">
      <div className="container Login_Form">
        <h2 className="Linkedin_Logo">
          Linked
          <span>
            <i className="fab fa-linkedin"></i>
          </span>
        </h2>
        <div className="Subheading_Text">
          <h1>Sign in</h1>
          <p>Stay updated on your professional world</p>
        </div>
          <div className="Login_Fields ">
            <div className="Login_input mb-4">
              <input
                type="email"
                name="user_email"
                id="Email"
                required={true}
              />
              <label htmlFor="user_email">Email or Phone</label>
            </div>
            <div className="Login_input mb-4">
              <input
                type="password"
                name="password"
                id="password"
                required={true}
              />
              <i
                className="far fa-eye eye_position"
                id="togglePassword"
                onClick={() => Toggle_Password()}
              ></i>
              <label htmlFor="password">Password</label>
            </div>
          </div>

          <button className="Signin_btn" id="signInBtn" onClick={()=>Save_Login()}>
            Sign in
          </button>
      </div>
    </div>
  );
}

export default Linkedin_Form;
