import React from "react";
import "../../../WebApp/Css_Files/Checkout_Modal.css";
import Papa from "papaparse";
import { GetUserData } from "../../Utils/GlobalFunctions";
import introJs from "intro.js";
import "../../assets/assets/scss/introjs.scss";
import { Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import {
  GettingMultipleProfileData,
  UpdateCredits_Backend,
} from "../../../WebApp/Utils/ApiCalls";

import "../Pages/customcss/Custom.css";
import DemoVideo from "./DemoVideo";
import InfoMessage from "./InfoMessage";

class LinksExtract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BulkExport: [],
      FileState: false,
      Proceed: false,
      isLoading: false,
      FileUrl: "",
      UserEmail: GetUserData().login_email,
      PricingModalState: false,
      URlType: "",
      Continue: false,
      infoMessage: props.InfoMessage,
    };
  }
  componentDidMount() {
    introJs().setOption("dontShowAgain", true ,{
      showProgress: true,
    }).start();
  }
  updatePricingModal = () => {
    this.setState({ PricingModalState: false });
  };

  ParseCSVUrl = () => {};
  ParseCSVFile = (CSV) => {
    Papa.parse(CSV, {
      skipEmptyLines: true,
      //  header: true,
      complete: function (results) {
        console.log(results.data);
        localStorage.setItem("BulkLinks", results.data[0]);
      },
    });
    this.setState({ Continue: true }, () => {});
  };

  MoveToSummary = () => {
    this.props.seValue();
    this.props.setTab();
  };

  render() {
    return (
      <>
        {this.state.infoMessage ? (
          <div className="p-4">
            <InfoMessage
              TitleLine="LinkedIn recently implemented a limit of 100 invitations per week. To keep within this, we now recommend sending 20 invitations per working day."
              Description="To do so, choose to send 2 invites per launch below. Then, in the Settings step of the Phantom's setup, select the repeated launch option Once per working hour (9 to 5), excluding weekends. This will send 90 invitations per week."
            />
          </div>
        ) : (
          <></>
        )}

        <div
          className="Main_Scrapper p-4 row"
          data-title="Let's Get Started"
          data-intro="Follow the instructions"
          data-step={1}
          data-position="top"

        >
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-12"
            data-title="Watch Demo"
            data-intro="For a quick start guide guide please watch this video on how to use this tool"
            data-position='right'
          >
            <DemoVideo VideoURL="https://www.youtube.com/embed/OrqSOq2Q3kc" />
          </div>

          <div className="col-lg-7 col-md-7 col-sm-12 col-12">
            <div className="d-flex justify-content-center flex-column shadow rounded pt-5 p-3 text-light">
              <h4>Profiles to {this.props.Type}</h4>
              <p>
                Tell Us Which Linkedin Profile(s) You Woud Like To{" "}
                {this.props.Type}
              </p>
              <div
                className="ManualSteps"
                data-title="Steps"
                data-intro="Follow the following steps"
                data-position='left'
              >
                <h6>Your Profile Url*</h6>
                <ul>
                  <li>
                    Your can upload a CSV file conitaing your profile links{" "}
                  </li>
                  <li>Or you can enter a Url Containing the CSV file</li>
                  <li>
                    Make sure your CSV file is formatted as per instructions
                  </li>
                  <li>Make sure your file url is publiciallly accessable</li>
                </ul>
                {/* File Type Toggle Buttons */}
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <button
                      data-title="File Url"
                      data-intro="You can enter a csv file url manually here"
                      className="p-5 btn btn-dark"
                      onClick={() => this.setState({ URLType: "URL" })}
                    >
                      <i className="fa-solid fa-link ToggleIcon"></i>
                      <br /> Enter File Url
                    </button>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    <button
                      data-title="Upload File"
                      data-intro="You can upload a csv on you computer here"
                      className="p-5 btn btn-dark "
                      onClick={() => this.setState({ URLType: "File" })}
                    >
                      <i className="fa-solid fa-file ToggleIcon"></i>
                      <br /> Upload File{" "}
                    </button>
                  </div>
                </div>
              </div>

              {this.state.URLType === "File" ? (
                <div className="p-4 d-flex flex-column justify-content-center text-center">
                  <p>Please Upload The File Containing Profile links</p>

                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={(e) => {
                      this.ParseCSVFile(e.target.files[0]);
                    }}
                  />
                </div>
              ) : this.state.URLType === "URL" ? (
                <div className="p-4 d-flex flex-column justify-content-center text-center">
                  <input
                    type="text"
                    id="Cookie_Input"
                    className="form-control mt-4 mb-2"
                    placeholder="Paste File URL Here...."
                    onChange={(e) => {
                      this.ParseCSVFile(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              {this.state.Continue ? (
                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn btn-primary rounded shadow mb-4"
                    onClick={() => this.MoveToSummary()}
                  >
                    Continue
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LinksExtract;
