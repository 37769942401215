import React, { useEffect } from 'react'
import { useState } from 'react'
import { FetchingLeads } from '../../../WebApp/Utils/ApiCalls'
import PageHeader from '../PageHeader'
import { SpinnerLoad } from '../SpinnerLoad'
import HoverRows from './UserTable'


 const LeadsDashboard=()=>  {
  const[Loader, setLoader]=useState(true)
  const[LeadsData, setLeadsData]=useState([])
useEffect(() => {
 
var Response=FetchingLeads()

Response.then((data)=>{
  if(data!=null){
    setLeadsData(data.FinalResp)
  }
  setLoader(false)
}
)
}, [])

 
    return (
      <div className='pl-3 pr-3'><PageHeader
      HeaderText="My Leads"
      Breadcrumb={[
        { name: "Dasboard", navigate: "dashboard" },
        { name: "My Leads", navigate: "" },
     ]}     
    />
    <h3>Manage Your Leads</h3>

  {Loader?
  <SpinnerLoad/>
  :
  LeadsData.length!=0?
  <HoverRows Data={LeadsData}/>
  :
  <div className="card">
  <div className="header">
    <h2>
      No Leads Found{" "}
      <small>
  
      </small>
    </h2>
  </div>
  </div>
  }
    </div>
    )
  
}
export default LeadsDashboard