import React from "react";
import "../../../WebApp/Css_Files/Checkout_Modal.css";
import Papa from "papaparse";
import { EncryptVal, GetUserData } from "../../Utils/GlobalFunctions";
import { Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import {
  GettingMultipleProfileData,
  UpdateCredits_Backend,
} from "../../../WebApp/Utils/ApiCalls";

import '../Pages/customcss/Custom.css'
import DemoVideo from "./DemoVideo";

class QueryScrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BulkExport: [],
      FileState: false,
      Proceed: false,
      isLoading: false,
      FileUrl: "",
      UserEmail: GetUserData().login_email,
      PricingModalState: false,
      URlType: "",
      Continue: false,
      QueryValid:false,
    };
  }

  updatePricingModal = () => {
    this.setState({ PricingModalState: false });
  };


  ParseCSVUrl=()=>{
    
  }
  ParseCSVFile = (CSV) => {
    var that=this 
    var OBjects=[]
    Papa.parse(CSV, {
      
      skipEmptyLines: true,
       header: true,
      complete: function (results) {
        console.log(results.data);
        var firstobj=results.data[0]
        OBjects=(Object.keys(firstobj))        
     
        if(OBjects.includes("People") && OBjects.includes("Company") && OBjects.includes("Country") ){
            var FinalEncryptArray=[]
            results.data.forEach(element => {
                var newObj={}
                newObj["People"]=EncryptVal(element.People)
                newObj["Company"]=EncryptVal(element.Company)
                newObj["Country"]=EncryptVal(element.Country)
FinalEncryptArray.push(newObj)                
            });

            localStorage.setItem("User_Query", JSON.stringify(FinalEncryptArray));
            that.setState({ Continue: true }, () => {});
        }
        else{
            if(localStorage.getItem("User_Query")!==null||localStorage.getItem("User_Query")!==undefined)
           { localStorage.removeItem("User_Query")}
           that.setState({Continue:false})
            alert("File Format Not Correct")
        }
        
      },
    });
    
    
  };
  

  MoveToSummary = () => {
    this.props.seValue();
    this.props.setTab();
  };

  render() {
    return (
      <>
        <div className="Main_Scrapper p-4 row">
          <div className="col-lg-5 col-md-5 col-sm-12 col-12">
         <DemoVideo
         VideoURL="https://www.youtube.com/embed/OrqSOq2Q3kc"/>
          </div>

          <div className="col-lg-7 col-md-7 col-sm-12 col-12">
            <div className="d-flex justify-content-center flex-column shadow rounded pt-5 p-3 text-light">
              <h4>Profiles To Scrape</h4>
              <p>Tell Us Which Linkedin Profile(s) You Woud Like To Scrape</p>
              <div className="ManualSteps">
                <h6>Your Profile Url*</h6>
                <ul>
                  <li>Your can upload a CSV file conitaing search queries</li>
                  <li>Or you can enter a Url Containing the CSV file</li>
                  <li>
                    Make sure your CSV file is formatted as per instructions
                  </li>
                  <li>Make sure your file url is publiciallly accessable</li>
                </ul>
                {/* File Type Toggle Buttons */}
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <button
                    className="p-5 btn btn-dark"
                    onClick={() => this.setState({ URLType: "URL" })}
                  >
                    <i className="fa-solid fa-link ToggleIcon"></i>
                    <br /> Enter File Url
                  </button>

                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                      
                  <button
                    className="p-5 btn btn-dark "
                    onClick={() => this.setState({ URLType: "File" })}
                  >
                    <i className="fa-solid fa-file ToggleIcon"></i>
                    <br /> Upload File{" "}
                  </button>
                  </div>
                
                </div>
              </div>

              {this.state.URLType === "File" ? (
                <div className="p-4 d-flex flex-column justify-content-center text-center">
                  <p>Please Upload The File Containing Your Search Query</p>

                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={(e) => {
                      this.ParseCSVFile(e.target.files[0]);
                    }}
                  />
                </div>
              ) : this.state.URLType === "URL" ? (
                <div className="p-4 d-flex flex-column justify-content-center text-center">
                  <input
                    type="text"
                    id="Cookie_Input"
                    className="form-control mt-4 mb-2"
                    placeholder="Paste File URL Here...."
                    onChange={(e) => {
                      this.ParseCSVFile(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              {this.state.Continue ? (
                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn btn-primary rounded shadow mb-4"
                    onClick={() => this.MoveToSummary()}
                  >
                    Continue
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QueryScrapper;
