import React from "react";
import toast from "react-hot-toast";
import PricingModal from "../../../WebApp/Components/PricingModal";
import {
  UpdateCredits_Backend,
} from "../../../WebApp/Utils/ApiCalls";
import { Mini_Function_Cost } from "../../../WebApp/Utils/DummyData";
import { DownloadCSV, Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import { MultiQuerySearch } from "../../Utils/ApiCalls";
import { EncryptVal, GetUserData } from "../../Utils/GlobalFunctions";
import Api_Loader from "../API_Loader";
import "../Pages/customcss/Custom.css";
import AdvancedScrape from "./AdvancedScrape";
import InfoMessage from "./InfoMessage";
import SendIvitations from "./SendInvitations";

class QueryScrapeSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Proceed: false,
      isLoading: false,
      ExportData_Json: [],
      ExportData_CSV: [],
      Links_Array: [],
      UserQuery: props.UserQuery,
      fileName: "",
      LimitExceede: false,
      pricingModal: false,
      AdvancedScrape:false,
      SendInvites:false,
      Entityurn_Array:[],
      userEmail: GetUserData().login_email,
    };
  }
 
  HandleProceed = async (e) => {
    var ContinueBtn= document.getElementById("Continue_Btn_1");
ContinueBtn.innerText = "Loading..";
ContinueBtn.disabled = true;
    var response = await UpdateCredits_Backend(
      this.state.userEmail,
      this.state.UserQuery.length * this.props.Credits,
      "down",
      "dash"
    );


    if (!response.includes("null")) {
      this.HandelMultipleLinks();
    } else {
      toast.error("You Dont Have Enough Credits");
      ContinueBtn.innerText = "Continue";
ContinueBtn.disabled = false;
      this.setState({ pricingModal: true });
    }
  };
  HandleAdvancedScrape = async (e)=>{
    var AdvanceScrape= document.getElementById("Advance_ScrapeBtn");
    AdvanceScrape.innerText = "Loading..";
    AdvanceScrape.disabled = true;


    var response = await UpdateCredits_Backend(
      this.state.userEmail,
      this.state.Links_Array.length*Mini_Function_Cost,
      "down",
      "dash"
    );
    if (!response.includes("null")) {
      this.setState({AdvancedScrape:true})
    } else {
      toast.error("You Dont Have Enough Credits");
      AdvanceScrape.innerText = "Scrape Now";
      AdvanceScrape.disabled = false;
  
      this.setState({ pricingModal: true });
    }
  }
  HandleInvitations=async(e)=>{
    var SendInvites= document.getElementById("SendInvites_Btn");
    SendInvites.innerText = "Loading..";
    SendInvites.disabled = true;

    var response = await UpdateCredits_Backend(
      this.state.userEmail,
      this.state.Entityurn_Array.length*Mini_Function_Cost,
      "down",
      "dash"
    );
    if (!response.includes("null")) {
      this.setState({SendInvites:true})
    } else {
      toast.error("You Dont Have Enough Credits");
      SendInvites.innerText = "Send Invites";
      SendInvites.disabled = false;
  
      this.setState({ pricingModal: true });
    }


  }
  HandelMultipleLinks = () => {
    this.setState({ Proceed: true, isLoading: true });

    var response = MultiQuerySearch(this.state.UserQuery);
    response
      .then((data) => {
        if (data != null) {
          var Final_Array = [];
          data.forEach((element) => {
            Final_Array = [...Final_Array, ...element.FinalData];
          });
          this.setState({ ExportData_Json: Final_Array });

          var arrayFinal = [];
          Final_Array.forEach((item) => {
            var flattened = {};
            Flatten(item, flattened, "");
            arrayFinal.push(flattened);
          });
          this.setState({ ExportData_CSV: arrayFinal });

          arrayFinal.forEach((element) => {
            if (
              !element[".User_NavigationUrl"].includes("/search/results/people")
            ) {
              this.state.Links_Array.push(element[".User_NavigationUrl"]);
              this.state.Entityurn_Array.push(element[".EntityUrn"])
            }
          });
          localStorage.setItem("Entities",JSON.stringify(this.state.Entityurn_Array))

          console.log(this.state.Links_Array);
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        alert(error);
        this.setState({ isLoading: false });
      });
  };
  updatePricingModal = () => {
    this.setState({ pricingModal: false });
  };

  render() {
    return (
      <div className="Bulk_Export mt-4">
       <InfoMessage
       TitleLine="There's a limit to how much you can automate on LinkedIn"
       Description=" We recommend you process a maximum of 5 search
       queries per scrape, or
        15  search queries if you have a Sales Navigator
       account. If you bypass this by too much, you will have to update
       your session cookie more often."
       SubDesc=" You should always spread your actions across several launches
       spaced throughout the day, during office hours where possible."
       />
        {!this.state.Proceed ? (
          <div className="ContinueBtn container d-flex justify-content-start flex-column">
            <div className="panel-body text-light p-4 shadow rounded">
              <table className="table borderless text-light">
                <thead>
                  <tr>
                    <td className="text-left">
                      <strong>{this.props.OueryType} Entered</strong>
                    </td>

                    <td className="text-right">
                      <strong>Credits Required To Scrape</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">
                      Total {this.props.OueryType}:{" "}
                      <strong> {this.state.UserQuery.length}</strong>
                    </td>

                    <td className="text-right">
                      Total:{" "}
                      <strong>
                        {" "}
                        {this.state.UserQuery.length * this.props.Credits} Credits
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <input
                        type={"text"}
                        onChange={(e) => {
                          this.setState({ fileName: e.target.value });
                        }}
                        placeholder="Enter File Name"
                        className="form-control"
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-2">
              <button
                className="btn btn-primary hvr-grow"
                onClick={(e) => this.HandleProceed(e)}
                id="Continue_Btn_1"
              >
                Continue
              </button>
             
            </div>
          </div>
        ) : (
          <div className="ExitDiv">
            {this.state.isLoading ? (
              <Api_Loader/>
            ) : (<>
              <div className="row text-light mb-3">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-4 shadow rounded">
                <h4 className="mb-3">Basic Scrape</h4>
                <h6>{this.state.ExportData_Json.length} Results Found </h6>

                  <h6 className="mb-3">Select Your Action...</h6>

                  <DownloadCSV
                    fileName={this.state.fileName}
                    dataDetailFormatted={this.state.ExportData_CSV}
                    JsonData={this.state.ExportData_Json}
                    Deduct={false}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-4 shadow rounded">
                <h4 className="mb-3">Advanced Scrape</h4>

                {this.state.Links_Array.length!==0?
                <>{this.state.Links_Array.length} Results Available For Advanced Scrape
               <br/> Cost For Advanced Scrapping : {this.state.Links_Array.length*5} Credits
            
            <div className="">
              { !this.state.AdvancedScrape?
            <button
                className="btn btn-primary hvr-grow mt-5"
                onClick={(e) => this.HandleAdvancedScrape(e)}
                id="Advance_ScrapeBtn"
              >
                Scrape Now
              </button>
              :
              <AdvancedScrape
              Links={this.state.Links_Array}
             
              />
              } 
            </div>
               </>

                :
                <>No Results Available For Advanced Scrape</>
                
                }
                </div>                
              </div>
              <div className="row text-light">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-4 shadow rounded">
                <h4 className="mb-3">Send Invites To First 10 People</h4>

                {this.state.Entityurn_Array.length!==0?
                <>{this.state.Entityurn_Array.length} Results Available To Send Invites
               <br/> Cost For Sending Invites : {this.state.Entityurn_Array.length*5} Credits
               <div className="w-75 text-left">
               <small>Note: If you Want to send invites to specific users please download the CSV file and Upload the desired profile links in the "Network Booster Module"</small>
               </div>
               
            
            <div className="">
              { !this.state.SendInvites?
            <button
                className="btn btn-primary hvr-grow mt-5"
                onClick={(e) => this.HandleInvitations(e)}
                id="SendInvites_Btn"
              >
                Send Invites
              </button>
              :
              <SendIvitations
              Entities={this.state.Entityurn_Array}
              UserMsg={"Testing"}
              />
              } 
            </div>
               </>

                :
                <>No Results Available For Advanced Scrape</>
                
                }
                </div>

              </div>
              </>
            )}
          </div>
        )}
         <PricingModal
                ModalState={this.state.pricingModal}
                ModalStateCallback={() => this.updatePricingModal()}
              />
      </div>
    );
  }
}
export default QueryScrapeSummary;
