import React from "react"
import { DecryptVal, EncryptVal } from "./Encryption"

export function setWithExpiry(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}
export function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	else if (itemStr==="null") {
		return null;
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		localStorage.setItem(key,null);
		return null;
    
    }
	return item.value
}
export function Set_Storage(key, value){
localStorage.setItem(key, value)
}
export function get_Storage(key){
   return localStorage.getItem(key);
}
export function SettingStorageEncrypt(key,value){
	const now = new Date()
	var ttl=86400000//24 hours
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: EncryptVal(value),
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}
export function GettingStorageEncrypt(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	else if (itemStr==="null") {
		return null;
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		localStorage.setItem(key,null);
		return null;
    
    }
	return DecryptVal(item.value)
}