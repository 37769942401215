import "../Css_Files/SearchResult.css";
import  '../Css_Files/SideMenu.css'
import React from "react"


import { useState } from "react";
import 'animate.css';

export default function SideMenu (props) {
    const [MenuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!MenuOpen);
        
    };

function changeAnimation(){
var myMenu=document.getElementById('openMenu')
if(myMenu.classList.contains("animate__backInRight")){
    myMenu.classList.remove("animate__backInRight")
    myMenu.classList.add("animate__backOutRight")
}

}

return(
<div className=" ">
    {MenuOpen?
<div className="Menu_Body shadow rounded animate__animated animate__flipInY" id="openMenu">

<div className="Header_Total row">

<div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-start" >
<h5 className="mt-1">Summary</h5>
</div>

<div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
    <button className="btn btn-default" onClick={() => { toggleMenu(); changeAnimation();}} ><i className="fa-solid fa-arrow-right"></i></button>
</div>


</div>

<div className="Total_Body shadow">
<div className="Total_Data"><h5>No of Accounts:</h5> <h5>{props.No_Of_Users}</h5></div>

<div className="Total_Data"><h5>Credits Required:</h5> <h5>{props.No_Of_Users*5}</h5></div>

</div>
<button className="btn btn-default Proceed_btn hvr-grow" onClick={props.OpenModal}>Proceed</button>
</div>:
<div className="MenuClosed shadow">

<button className="toggleBtn" onClick={toggleMenu}><i className="fa-solid fa-shopping-cart Icon_btn"></i><p className="TotalUsers">{props.No_Of_Users}</p></button>
</div>

}

</div>


)

}