import React from "react";
import { GettingMultipleProfileData } from "../../../WebApp/Utils/ApiCalls";
import { DownloadCSV, Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import { SendingInvites } from "../../Utils/ApiCalls";
import Api_Loader from "../API_Loader";

class SendIvitations extends React.Component{

constructor(props){
    super(props)
    this.state={
        isLoading:true,
        Succesful:false      
        
    }
}
componentDidMount(){
                   
        var response = SendingInvites(this.props.Entities,this.props.UserMsg)
        response
          .then((data) => {
        
            if (data != null) {
    
              if(data==="true")
              this.setState({ Succesful: true });         
            }
            else{
                this.setState({ Succesful: false });
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            alert(error)
            this.setState({ isLoading: false });
          });
     

}

render(){
    return(


        <div className="ExitDiv">
            {this.state.isLoading ? (
              <Api_Loader/>
            ) : (

              <div className="">                       
                  {
                    this.state.Succesful?
                    <h6 className="text-success">Invites Sent Successfully!</h6>
                :
                <h6 className="text-danger">There was a problem please try again later.</h6>
                }                                  
             </div>
            )}
          </div>
    )
}




}


export default SendIvitations