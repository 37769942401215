import React, { useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";

import CryptoJS from "crypto-js";
export const GetUserLocation = () => {
  navigator.geolocation.getCurrentPosition(function (position) {
    var User_Location = {
      Latitude: position.coords.latitude,
      Longitude: position.coords.longitude,
    };
    if (
      User_Location === "null" ||
      User_Location === null ||
      User_Location === ""
    ) {
      localStorage.setItem("UserLocation", EncryptVal("nill"));
    } else {
      localStorage.setItem(
        "UserLocation",
        EncryptVal(JSON.stringify(User_Location))
      );
    }
  });
};

export const GetCurrDate = () => {
  var CurrDT = new Date().toLocaleString();
  var DateOnly = CurrDT.substring(0, CurrDT.indexOf(","));
  return DateOnly;
};
export const GetCurrTime = () => {
  var CurrDT = new Date().toLocaleString();
  var TimeOnly = CurrDT.substring(CurrDT.indexOf(",") + 1);
  return TimeOnly;
};

const Key_val = CryptoJS.enc.Utf8.parse("8080808080808080");
const IV_val = CryptoJS.enc.Utf8.parse("8080808080808080");
export const EncryptVal = (value_to_be_ecrypt) => {
  var encrypted_Val = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(value_to_be_ecrypt),
    Key_val,
    {
      iv: IV_val,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return encrypted_Val.toString();
};

export const DecryptVal = (value_to_be_decrypt) => {
  var decrypted_Val = CryptoJS.AES.decrypt(value_to_be_decrypt, Key_val, {
    iv: IV_val,
  });
  //.toString(CryptoJS.enc.Utf8);

  var originalText = decrypted_Val.toString(CryptoJS.enc.Utf8);

  return originalText;
};

// get user data

export const GetUserData = () => {
  var UserData = JSON.parse(localStorage.getItem("User_Data"));
  return UserData;
};

export const Logout = () => {
  var RememberStatus = localStorage.getItem("RememberStatus");
  if (RememberStatus === "true") {
    localStorage.removeItem("LoggedStatus");
    // window.location.href=`${process.env.PUBLIC_URL}/`
  } else {
    localStorage.clear();
  }
};

export const groupBy = (array, key) => {
  if (array != null || array != undefined) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );

      return result;
    }, {});
  } else {
    return null;
  }
};

export const GetLastWeekTrans = () => {
  var data = JSON.parse(localStorage.getItem("TransStat"));

  var Results = groupBy(data, "date_spent");
  if (Results != null) {
    localStorage.setItem("GroupedTrans", JSON.stringify(Results));

    var lastweek = [];
    for (var i = 0; i <= 5; i++) {
      var date = new Date();

      date.setDate(date.getDate() - i);
      date = dateFormat(date, "m/d/yyyy");

      lastweek.push(date.toString());
    }
    var FinalData = {};
    var FinalSum = {};
    lastweek.forEach((element) => {
      if (Results[element] !== undefined) {
        FinalData[element] = Results[element];

        var total = 0;
        var totalTransactions = Results[element];
        for (var i = 0; i < totalTransactions.length; i++) {
          total += parseInt(totalTransactions[i].amount);
        }

        FinalSum[element] = total;
      } else {
        FinalData[element] = [];
        FinalSum[element] = 0;
      }
    });
    localStorage.setItem("LastWeekTrans", FinalSum);
    // console.log(FinalSum)
    // console.log(Object.values(FinalSum).reverse())

    return Object.values(FinalSum).reverse();
  } else {
    return [];
  }
};
export const GetLastWeekDates = () => {
  var windowSize = window.innerWidth;
    var Entires = 0;
    if (windowSize <= 500) {
      Entires = 4;
    } else if (windowSize <= 768) {
      Entires = 13;
    } else {
      Entires = 29;
    }
  var lastweek = [];
  for (var i = 0; i <= Entires; i++) {
    var date = new Date();

    date.setDate(date.getDate() - i);
    date = dateFormat(date, "mmm d");

    lastweek.push(date.toString());
  }
  // console.log(lastweek);
  return lastweek.reverse();
};

export const GetLastWeekSearch = (Forum,Screen) => {
  var data = JSON.parse(localStorage.getItem("SearchStats"));
  var FilteredData = [];
  if (data != null || data != undefined) {
    var FilteredData = data.filter(function (el) {
      return el.search_forum == Forum;
    });
  }
  // console.log(FilteredData)}

  var Results = groupBy(FilteredData, "search_date");
  if (Results != null) {
    localStorage.setItem("GroupedTrans", JSON.stringify(Results));
    var windowSize = window.innerWidth;
    // alert(typeof windowSize)
    var Entires = 0;
    if (windowSize <= 500) {
      Entires = 4;
    } else if (windowSize <= 768) {
      Entires = 13;
    } else {
      Entires = 29;
    }
    var lastweek = [];
    for (var i = 0; i <= Entires; i++) {
      var date = new Date();

      date.setDate(date.getDate() - i);
      date = dateFormat(date, "m/d/yyyy");

      lastweek.push(date.toString());
    }
    var FinalData = {};
    var FinalSum = {};
    var count = 0;
    lastweek.forEach((element) => {
      if (Results[element] !== undefined) {
        FinalData[element] = Results[element];

        var total = 0;
        var taxes = Results[element];
        for (var i = 0; i < taxes.length; i++) {
          total = taxes[i].search_forum;
          count += 1;
        }

        FinalSum[element] = count;
      } else {
        FinalData[element] = [];
        FinalSum[element] = 0;
      }
    });
    localStorage.setItem("LastWeekSearch", FinalSum);
    // console.log(FinalSum)
    // console.log(Object.values(FinalSum).reverse());
    // console.log(lastweek);
    var mapData = Object.values(FinalSum);
    GetLastWeekDates();
    return Object.values(FinalSum).reverse();
  } else {
    return [];
  }
};

export const GetLastByType = () => {
  var data = JSON.parse(localStorage.getItem("TransStat"));

  var Results = groupBy(data, "status");
  // console.log(Results);
  if (Results != null) {
    return Results;
  } else {
    return [];
  }
};

export const GroupByWeekly = (GroupedData, Status) => {
  var Results = groupBy(GroupedData[Status], "date_spent");
  // console.log(Results);
  if (Results != null) {
    var lastweek = [];
    for (var i = 0; i <= 5; i++) {
      var date = new Date();

      date.setDate(date.getDate() - i);
      date = dateFormat(date, "m/d/yyyy");

      lastweek.push(date.toString());
    }

    var FinalData = {};
    var FinalSum = {};
    lastweek.forEach((element) => {
      if (Results[element] !== undefined) {
        FinalData[element] = Results[element];

        var total = 0;
        var taxes = Results[element];
        for (var i = 0; i < taxes.length; i++) {
          total += parseInt(taxes[i].amount);
        }

        FinalSum[element] = total;
      } else {
        FinalData[element] = [];
        FinalSum[element] = 0;
      }
    });
    localStorage.setItem("LastWeekTrans", FinalSum);
    // console.log(FinalSum);
    // console.log(Object.values(FinalSum).reverse());
    var mapData = Object.values(FinalSum);
    return Object.values(FinalSum).reverse();
  } else {
    return [];
  }
};
export const generateUUID=()=> { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;
      if(d > 0){
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}