import React from 'react'
import toast from 'react-hot-toast';
import SAVE from '../../WebApp/images/save.png'
import { Saving_Leads } from '../../WebApp/Utils/ApiCalls';
export const SaveLeads = (props) => {

const SavingLeads=()=>{
    props.LeadsData.forEach(element => {
     var response= Saving_Leads(element)


     toast.promise(
      response,
      {
        loading: 'Please Wait',
        
        success: (data) => {
          if(data!=="null"){

            if(data==="Success"){
              console.log(element.LeadName, "Success")
              return 'Lead Added Successfully'

                  }
                  else if(data==="Failed"){
                    console.log(element.LeadName, "Failed")

                }
              
          }
          else{
            toast.error("Something went wrong")
          }
       },
        error: (err) => `Something Went Wrong Please Try Again`,
      },
      {
        style: {
          minWidth: '250px',
          position: "top-center"
        },
        success: {
          duration: 3000,      
        },
      }
    );

    },()=>{
      window.location.href="http://localhost:3000/Leads"
    });
// console.log(props.LeadsData)


}


  return (
    <button
    className="HeaderButtons btn btn-primary"
    title="Save As Leads"
    onClick={()=>SavingLeads()}
    >
    <span><img src={SAVE} width={"35"}></img>  Save as Lead</span>
  </button>
  )
}
