import React from "react";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import ProfileHeaderCard from "../../components/Pages/ProfileHeaderCard";


import ProfileV2Tabs from "../../components/Pages/ProfileV2Tabs";
import ProfileIconCard from "../../components/Pages/ProfileIconCard";


class ProfileV2Page extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
    
      UserObj:props.UserObj
    };
  }
  componentWillReceiveProps(NewProps){
    if(NewProps.UserObj!==this.state.UserObj){
      this.setState({UserObj:NewProps.UserObj})
    }
    }

  render() {
    return (
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText="User Profile"
              Breadcrumb={[
                { name: "Dashboard", navigate: "" },
                { name: "Profile", navigate: "" },
              ]}
            />
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <ProfileHeaderCard
                User_Obj={this.state.UserObj}
                />
                {/* <ProfileInfoCard /> */}
                {/* <FollowersCard HeaderText="Who to follow" /> */}
              </div>
              <div className="col-lg-5 col-md-12">
                <ProfileV2Tabs 
                 User_Obj={this.state.UserObj}
                />
              </div>
              <div className="col-lg-3 col-md-12">
                <ProfileIconCard />
                {/* {ProfileSliderData.map((data, i) => {
                  return (
                    <ProfileSliderCard
                      key={i}
                      color={data.color}
                      data={data.data}
                      headerText={data.headerText}
                      subTitle={data.subTitle}
                    />
                  );
                })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ioTReducer }) => ({
  isSecuritySystem: ioTReducer.isSecuritySystem,
});

export default connect(mapStateToProps, {})(ProfileV2Page);
