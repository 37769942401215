export const LinkedInCards=[

{
    Title:"Linkedin Lead Finder : Complete ",
    Description:"Complete LinkedIn Experience, Search, Scrape & Save",
    Link:"Widget",

},
{
    Title:"LinkedIn Profile Scraper from URL",
    Description:"Scrape all the available data from LinkedIn profiles!",
    Link:"ProfileScrape",

},
{
    Title:"LinkedIn Profile Scraper from Query",
    Description:"Scrape profiles by Company, Country or Name",
    Link:"ScrapeFromData",

},
{
    Title:"Scrape Employees By Company",
    Description:"Scrape data of all available employees of companies",
    Link:"EmployeeScrape",

},

{
    Title:"LinkedIn Connection and Network Booster",
    Description:"Boost your LInkedin Profile and Send Bulk Invites",
    Link:"NetworkBooster",

},
{
    Title:"LinkedIn Auto Message Sender",
    Description:"Send Messages automatically to multiple users",
    Link:"NetworkBooster",

},

]

export const LeadsData=[

{
    Name:"John Doe",
    Email:"John@gmail.com",
    URL:"https://www.john.com",
    Action:'      <div className="btn-group" role="group">'+
    '<Dropdown as="div">'+
      '<Dropdown.Toggle'+
        'variant="success"'+
        'as="button"'+
        'id="dropdown-basic"'+
        'className="btn btn-secondary">'+      
        'Action'+
      '</Dropdown.Toggle>'+
      '<Dropdown.Menu'+
        'as="ul"'+
        'className="dropdown-menu dropdown-menu-right">'+      
        '<a className="dropdown-item">Dropdown link</a>'+
        '<a className="dropdown-item">Dropdown link</a>'+
      '</Dropdown.Menu>'+
    '</Dropdown>'+
  '</div>"'

},
{
    Name:"John Doe",
    Email:"John@gmail.com",
    URL:"https://www.john.com",
    Action:'      <div className="btn-group" role="group">'+
    '<Dropdown as="div">'+
      '<Dropdown.Toggle'+
        'variant="success"'+
        'as="button"'+
        'id="dropdown-basic"'+
        'className="btn btn-secondary">'+      
        'Action'+
      '</Dropdown.Toggle>'+
      '<Dropdown.Menu'+
        'as="ul"'+
        'className="dropdown-menu dropdown-menu-right">'+      
        '<a className="dropdown-item">Dropdown link</a>'+
        '<a className="dropdown-item">Dropdown link</a>'+
      '</Dropdown.Menu>'+
    '</Dropdown>'+
  '</div>"'

},
{
    Name:"John Doe",
    Email:"John@gmail.com",
    URL:"https://www.john.com",
    Action:'      <div className="btn-group" role="group">'+
    '<Dropdown as="div">'+
      '<Dropdown.Toggle'+
        'variant="success"'+
        'as="button"'+
        'id="dropdown-basic"'+
        'className="btn btn-secondary">'+      
        'Action'+
      '</Dropdown.Toggle>'+
      '<Dropdown.Menu'+
        'as="ul"'+
        'className="dropdown-menu dropdown-menu-right">'+      
        '<a className="dropdown-item">Dropdown link</a>'+
        '<a className="dropdown-item">Dropdown link</a>'+
      '</Dropdown.Menu>'+
    '</Dropdown>'+
  '</div>"'

}


]

