
 import React from "react";
 const DemoVideo=(props)=>{

    return(
        <div className="d-flex justify-content-center flex-column shadow rounded pt-5 p-3 text-light">
        <h4>How To Format your CSV</h4>
          <iframe
           height={"300"}
            src={props.VideoURL}
            title="YouTube video player"
            
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>


    );


}

export default DemoVideo