const Base_Url = "https://leadsfizz.api-apx.com/api/";
export const SignUp_Endpoint = Base_Url + "v1/DashBoardSignup";
export const Login_Endpint=Base_Url+"v1/DashBoardLogin";
export const Update_Pass=Base_Url+"v1/UpdatePassword";
export const CheckPass_Endpoint=Base_Url+"v1/CheckingPassword";
export const BasicInfo_Endpoint=Base_Url+"v1/UpdatingBasicInfo";
export const MultiProfiles_Endpoint=Base_Url+"v1/GettingMulitProfilesData";

export const QueryMultiUserProfiles_Endpoint=Base_Url+"v1/Query_MultiProfileDetails";
export const SendingInvites_Endpoint=Base_Url+"v1/ConnectMultiUser"
export const ValidateEmail_Endpoint=Base_Url+"v1/ValidateEmail"
export const ResetPassword_Endpoint=Base_Url+"v1/ResettingPassword"