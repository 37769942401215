import "../Css_Files/SearchResult.css";
import React, { useState } from "react";
import ProfileModal from "./ProfileModal";
import Logo from "../images/logo3.png";


import SideMenu from "./SideMenu";
import { GettingProfileInfo, UpdateCredits_Backend } from "../Utils/ApiCalls";
import { Basic_Function_Cost, Mini_Function_Cost, ZeroCredits } from "../Utils/DummyData";
import toast from "react-hot-toast";
import PricingModal from "./PricingModal";
function SearchResult(props) {
  if (props.UserName === "LinkedIn Member") {
    return <UIResults mainProps={props} style="Disabled_Ele" />;
  } else {
    return <UIResults mainProps={props} style="Normal" />;
  }
}

function UIResults(props_secondary) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  //Clipping Link
  const [userEmail]=useState(JSON.parse(localStorage.getItem("user_details")).username)

  const FullLink = props_secondary.mainProps.ProfileLink;

  const SelctedUsers = () => {
    var ClippedLink = FullLink.substring(0, FullLink.indexOf("?"));
    var DispInfo = {
      DispName: props_secondary.mainProps.UserName,
      DispImg: props_secondary.mainProps.UserImage,
      DispLink: ClippedLink,
    };
    console.log(DispInfo);
    var DataArray = JSON.parse(localStorage.getItem("SelectedDataArray"));
    var linkArray = JSON.parse(localStorage.getItem("SelectedDataArray_Link"));

    console.log(linkArray);
    if (linkArray !== null) {
      var LinkIndex = linkArray.indexOf(ClippedLink);
      if (LinkIndex != -1) {
        // console.log(linkArray);
        // console.log(LinkIndex);

        linkArray.splice(LinkIndex, 1);
        localStorage.setItem("SelectedDataArray_Link", linkArray);
      } else {
        linkArray.push(ClippedLink);

        localStorage.setItem("SelectedDataArray_Link", linkArray);
      }
    } else {
      linkArray = [];
      linkArray.push(ClippedLink);
      localStorage.setItem("SelectedDataArray_Link", linkArray);
    }

    //Display Data

    if (DataArray !== null) {
      var FilteredArray = DataArray.filter(function (el) {
        return el.DispLink.includes(ClippedLink);
      });
      if (FilteredArray.length !== 0) {
        var index;
        for (index = 0; index < DataArray.length; ++index) {
          if (DataArray[index].DispLink.includes(ClippedLink)) {
            DataArray.splice(index, 1); // Removes this entry
            break; // Exits the loop
          }
        }
        localStorage.setItem("SelectedDataArray", JSON.stringify(DataArray));
      } else {
        //it mean data does not exist
        DataArray.push(DispInfo);
        localStorage.setItem("SelectedDataArray", JSON.stringify(DataArray));
      }
    } else {
      DataArray = [];
      DataArray.push(DispInfo);
      localStorage.setItem("SelectedDataArray", JSON.stringify(DataArray));
    }
    console.log(DataArray);
  };

  const GetDetails = async (link) => {
    var Data = await GettingProfileInfo(link);
    localStorage.setItem("SingleProfileData", JSON.stringify(Data));
  };
  return (
    <div
      className={"col-lg-3 col-md-6 col-sm-12 col-12 " + props_secondary.style}
    >
      <div className="container mt-4 mb-4 p-3 d-flex justify-content-center">
        <div className="card p-4 m-0 bg-white">
          <div className="d-flex justify-content-end">
            <input
              type="checkbox"
              id="SelctedUser"
              className="form-check-input"
              onChange={SelctedUsers}
            />
          </div>
          <div className=" image d-flex flex-column justify-content-center align-items-center">
            <button className="btn-secondary border-0 pic">
              
              {props_secondary.mainProps.UserImage === null ? (
                <img src={Logo} height="100" width="100" />
              ) : (
                <img
                  src={props_secondary.mainProps.UserImage}
                  height="100"
                  width="100"
                ></img>
              )}
            </button>
            <span className="name mt-3" id="Result_Name">
              {props_secondary.mainProps.UserName}
            </span>
            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
              <span className="idd1">{props_secondary.mainProps.Position}</span>
            </div>

            <div className="text mt-3 TextOver">
              
              <span>
                
                <p>{props_secondary.mainProps.Description}</p>
              </span>
            </div>
            <div className=" d-flex mt-2">
              
              {/* <a href={props_secondary.mainProps.ProfileLink} target={"_blank"}> */}
              <button
                className="btn1  viewprofile hvr-grow"
               
                onClick={() => {
                  var response=UpdateCredits_Backend(userEmail,ZeroCredits,"down","StandAlone")
                  response.then((data)=>{
                    if(!data.includes("null")){
                      GetDetails(props_secondary.mainProps.ProfileLink);
                      toggleModal();
                      }
                  else{
                    //pricing modal
                    toast.error("You Dont Have Enough Credits")
                    props_secondary.mainProps.PricingModal()
                  }

                  }).catch((e)=>{console.log(e)
                    toast.error("Something went Wrong")
                  })
                }}
                
              >
                
                View Profile
              </button>
              {/* </a> */}
            </div>

            <div className=" px-2 rounded mt-4 date ">
              
              <span className="Location">
                
                <i className="fa-solid fa-location-dot"></i>
                {props_secondary.mainProps.UserLocation}
              </span>
            </div>
          </div>
        </div>
      </div>
      <span className="tooltiptext_">Not a public Member on LinkedIn</span>

      <ProfileModal
        modalState={isOpen}
        ProfileImage={props_secondary.mainProps.UserImage}
        ProfileName={props_secondary.mainProps.UserName}
        ProfileDescription={props_secondary.mainProps.Description}
        ProfileLink={FullLink.substring(0, FullLink.indexOf("?"))}
        ProfilePosition={props_secondary.mainProps.Position}
        handleClose={toggleModal}
      />
    </div>
  );
}

export default SearchResult;
