import React from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/ourlogo_White.png";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { Reset_Password, ValidateEmail } from "../../Utils/ApiCalls";
import { EncryptVal } from "../../Utils/GlobalFunctions";
import toast,{Toaster} from "react-hot-toast";
import Page403 from "./Page403";
import Page503 from "./Page503";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Password: "",
      RePassword:"",
      EmptyPassword: false,
      EmptyRePassword:false,
      PasswordMatch:true,
      Reset:false,
      Expired:false,
      User_Email:"",
      UnAuthorized:false
    };
  }
  componentWillMount(){

    var queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var URL_Email=urlParams.get("email")
    var dummyMail="dumb@testmail.com"
    var URl_Time=urlParams.get("time")
    URl_Time=new Date (URl_Time)
    URl_Time=URl_Time.getTime()
    var addMlSeconds = 5*60 * 60 * 1000;
var newDateObj = new Date(URl_Time - addMlSeconds);
    var currentDate=new Date()
  
   URl_Time=dateFormat(URl_Time, "dddd, mmmm dS, yyyy, h:MM:ss TT");
   

      if(newDateObj<currentDate){
        this.setState({Expired:true})
      }   
this.setState({User_Email:URL_Email})
   

  var response=ValidateEmail(URL_Email)
  response.then((data)=>{
if(data.includes("Invalid")||data.includes("false")){
    this.setState({UnAuthorized:true})
}
  })
    }



  HandleResetClick = () => {
    if (this.state.Password === "" && this.state.RePassword==="") {
      this.setState({ EmptyPassword: true , EmptyRePassword:true});
    } 
    
    else if (this.state.Password === ""){
        this.setState({ EmptyPassword: true})

    }
    else if (this.state.RePassword===""){
        this.setState({ EmptyRePassword: true})

    }
    else if (this.state.Password !==this.state.RePassword){
        this.setState({PasswordMatch:false})
    }
    else{
        this.setState({EmptyPassword: false , EmptyRePassword:false,PasswordMatch:true})
        var response=Reset_Password(this.state.User_Email,EncryptVal(this.state.Password))
        toast.promise(
            response,
            {
              loading: "Please Wait",
              success: (data) => {
                if (data.includes("Updated")) {
                  setTimeout(() => {
                    window.location.href="/"
                  }, 1000);
                  return "Password Updated";
                }
                else{
                    return 'Somethign went wrong'
                }
              },
              error: (err) => {
                this.setState({ EmailSent: false });
                return `Something Went Wrong Please Try Again`;
              },
            },
            {
              style: {
                minWidth: "250px",
              },
              success: {
                duration: 15000,
              },
            }
          );

    }
  };

  render() {
    return (<>
      {this.state.UnAuthorized?
      <Page403/>:
      this.state.Expired?
      <Page503/>
      :
        <div className="theme-cyan">
        <Toaster/>
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Recover my password</p>
                  </div>
                  <div className="body">
                    <p>
                     Please enter your new password.
                    </p>
                    <div className="form-auth-small ng-untouched ng-pristine ng-valid">
                      <div className="form-group">
                      {!this.state.PasswordMatch?
                    <p className="text-danger">Password donot match</p>  
                    :
                    <></>
                    }
                        <input
                          className="form-control"
                          placeholder="New password"
                          type="password"
                          onChange={(e) =>
                            this.setState({ Password: e.target.value })
                          }
                        />
                        {this.state.EmptyPassword ? (
                          <p className="text-danger">
                            Please enter a password
                          </p>
                        ) : (
                          <></>
                        )}
                         <input
                          className="form-control"
                          placeholder="Re-Enter Password"
                          type="password"
                          onChange={(e) =>
                            this.setState({ RePassword: e.target.value })
                          }
                        />
                        {this.state.EmptyRePassword ? (
                          <p className="text-danger">
                            Please re enter your password
                          </p>
                        ) : (
                          <></>
                        )}

                      </div>

                      <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={(ee) => this.HandleResetClick()}
                      >
                        RESET PASSWORD
                      </button>
                      <div className="bottom">
                        <span className="helper-text">
                          Know your password? <Link to={"/"}> Login</Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      </>
    );
  }
}



export default ResetPassword;
