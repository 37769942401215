import React from "react"

const Base_Url = "https://leadsfizz.api-apx.com/api";
export const BasicDetails_Endpoint = Base_Url + "/v1/GetBasicData";
export const Authenticate_Endpoint = Base_Url + "/v1/AuthenticateUser";

export const QuerySearch_Endpoint = Base_Url + "/v1/GetDetailList";
export const QuerySpecificProfile = Base_Url + "/v1/GetProfileDetails";
export const QueryMultipleProfiles = Base_Url + "/v1/Get_MultiProfileDetails";

export const UpdateCreds_Endpoint = Base_Url + "/v1/UpdateCredits";

export const StripeEndpoint= Base_Url+"/v1/Stripe_Checkout"
export const TransactionEndpoint= Base_Url+"/v1/VerifyPayment"
export const cancelSubEndpoint= Base_Url+"/v1/CancelSubscriptions"
export const changeSubEndpoint= Base_Url+"/v1/UpgradeSubscription"

export const FetchingLeadsEndpoint=Base_Url+"/v1/FetchingAllLeads"
export const AddingLeadsEndpoint=Base_Url+"/v1/AddingNewLeads"
