import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/ourlogo_White.png";
import { DecryptVal, EncryptVal, GetCurrDate, GetCurrTime, GetUserLocation } from "../../Utils/GlobalFunctions";
import { SignUp_User } from "../../Utils/ApiCalls";
import { Toaster,toast } from "react-hot-toast";

class Registration extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      UserName:"",
      UserEmail:"",
      UserPassword:"",    
       SignupDate: GetCurrDate(),
  SignupTime:GetCurrTime(),
  emailExist:false
    }
  }


  componentDidMount(){
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");

    //fetching user location and placing in local storage

      GetUserLocation();

  }
FieldValidation=()=>{
  var EmailField=document.getElementById('signup-email')
  var NameField=document.getElementById('signup-Name')
  var PassField=document.getElementById('signup-password')
if(this.state.UserEmail===""&&this.state.UserName===""&&this.state.UserPassword===""){
  EmailField.classList.add("bg-danger")
  NameField.classList.add("bg-danger")
  PassField.classList.add("bg-danger")
  return false
}
else if(this.state.UserEmail===""||!this.state.UserEmail.includes("@")||!this.state.UserEmail.includes(".")){
  
EmailField.classList.add("bg-danger")
NameField.classList.remove("bg-danger")
PassField.classList.remove("bg-danger")
return false
}
else if(this.state.UserName===""){
 
NameField.classList.add("bg-danger")
PassField.classList.remove("bg-danger")
return false
}
else if(this.state.UserPassword===""){
  PassField.classList.add("bg-danger")
  EmailField.classList.remove("bg-danger")
  NameField.classList.remove("bg-danger")
return false
}
else {
  EmailField.classList.remove("bg-danger")
  NameField.classList.remove("bg-danger")
  PassField.classList.remove("bg-danger")
  return true
}


}

  SaveRegistration=(e)=>{
      e.preventDefault()
this.FieldValidation()
if(this.FieldValidation()){
  var SignUp_Name=EncryptVal(this.state.UserName)
  var SignUp_Email=EncryptVal(this.state.UserEmail)
  var SignUP_Password=EncryptVal(this.state.UserPassword)
  var SignUp_Date=EncryptVal(this.state.SignupDate)
  var SignUp_Time=EncryptVal(this.state.SignupTime)
  var Signup_Img=EncryptVal("null")
  var Signup_Promise= SignUp_User(SignUp_Name,SignUp_Email,SignUP_Password,SignUp_Date,SignUp_Time,Signup_Img)

  toast.promise(
    Signup_Promise,
    {
      loading: 'Please Wait',
      success: (data) => {
        if(data.includes("Exists")){
          this.setState({emailExist:true})
         
          return 'Email Already Exists'
          }
          else{
            this.setState({emailExist:false})
            setTimeout(() => {
              window.location.href=`${process.env.PUBLIC_URL}/login`
             }, 500);
            return "Welcome Aboard"
          }
     },
      error: (err) => `Something Went Wrong Please Try Again`,
    },
    {
      style: {
        minWidth: '250px',
      },
      success: {
        duration: 5000,
        
      },
    }
  );




// Signup_Promise.then((data)=>{
// if(data.includes("Exists")){
// this.setState({emailExist:true})
// }
// else{
//   this.setState({emailExist:false})
// }
// }).catch((error)=>{
// alert(error)
// })

    
}

  }
  render() {
    return (
      <div className="theme-cyan">
        <Toaster/>
        <div >
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img src={Logo} alt="Lucid" style={{ height: "40px", margin: "10px" }} />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Create an account</p>
                  </div>
                  <div className="body">
                    <form 
                      className="form-auth-small ng-untouched ng-pristine ng-valid"
                      
                    ><div className="form-group">
                    <label className="control-label sr-only" >
                      Full Name
                        </label>
                    <input
                      className="form-control"
                      id="signup-Name"
                      placeholder="Your Full Name"
                      type="test"
                      onChange={e=>
                      this.setState({UserName:e.target.value})
                      }
                    />
                  </div>
                      <div className="form-group">
                        <label className="control-label sr-only" >
                          Email
                            </label>
                        <input
                          className="form-control"
                          id="signup-email"
                          placeholder="Your email"
                          type="email"
                          onChange={e=>
                            this.setState({UserEmail:e.target.value})
                            }
                        />
                      {this.state.emailExist?
                        <div className="text-danger">Email Already Exists</div>:<></>}
                      </div>
                      
                      <div className="form-group">
                        <label className="control-label sr-only" >
                          Password
                            </label>
                        <input
                          className="form-control"
                          id="signup-password"
                          placeholder="Password"
                          type="password"
                          onChange={e=>
                            this.setState({UserPassword:e.target.value})
                            }
                        />
                      </div>
                      <button className="btn btn-primary btn-lg btn-block" onClick={(e) => this.SaveRegistration(e)}
                      type="submit">
                        REGISTER
                        </button>
                      <div className="bottom">
                        <span className="helper-text">
                          Already have an account?{" "}
                          <a href="login">Login</a>
                        </span>
                      </div>
                    </form>
                    {/* <div className="separator-linethrough">
                      <span>OR</span>
                    </div>
                    <button className="btn btn-signin-social">
                      <i className="fa fa-facebook-official facebook-color"></i> Sign in with
                        Facebook
                        </button>
                    <button className="btn btn-signin-social">
                      <i className="fa fa-twitter twitter-color"></i> Sign in with Twitter
                        </button> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Registration.propTypes = {
// };

// const mapStateToProps = ({ loginReducer }) => ({
//   email: loginReducer.email,
//   password: loginReducer.password
// });

export default (Registration);
