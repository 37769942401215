import { QuerySearch_Backend } from "./ApiCalls";
import CsvDownload from "react-json-to-csv";
import "../Css_Files/App.css";
import Csv from "../images/csv.png";
import React from "react"

import Json from "../images/json.png";
import toast from "react-hot-toast";
import { UpdateCredits_Backend } from "./ApiCalls";
import { Basic_Function_Cost } from "./DummyData";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import { GetUserData } from "../../Dashboard/Utils/GlobalFunctions";
export const GettingRecords = async (state_Count) => {
  var count = 0;
  if (state_Count) {
    count = parseInt(localStorage.getItem("Count_page"));
    count = count += 10;
    localStorage.setItem("Count_page", count);
  } else {
    localStorage.setItem("Count_page", 0);
  }
  let UserSearch =
    localStorage.getItem("UserQuery") === null ||
    localStorage.getItem("UserQuery") === "" ||
    localStorage.getItem("UserQuery") === undefined
      ? ""
      : localStorage.getItem("UserQuery");

  var selectedCountry_Code =
    localStorage.getItem("Country_Code") === null ||
    localStorage.getItem("Country_Code") === "" ||
    localStorage.getItem("Country_Code") === undefined
      ? ""
      : localStorage.getItem("Country_Code");

  var selectedIndustry_Code =
    localStorage.getItem("Industry_Code") === null ||
    localStorage.getItem("Industry_Code") === "" ||
    localStorage.getItem("Industry_Code") === undefined
      ? ""
      : localStorage.getItem("Industry_Code");

  var selectedCompany_Code =
    localStorage.getItem("Company_Code") === null ||
    localStorage.getItem("Company_Code") === "" ||
    localStorage.getItem("Company_Code") === undefined
      ? ""
      : localStorage.getItem("Company_Code");

  var SearchNumber = count;
  var SearchType =
    localStorage.getItem("User_SearchType") === null ||
    localStorage.getItem("User_SearchType") === "" ||
    localStorage.getItem("User_SearchType") === undefined
      ? "PEOPLE"
      : localStorage.getItem("User_SearchType");

  var SearchDefination =
    localStorage.getItem("User_SearchDefination") === null ||
    localStorage.getItem("User_SearchDefination") === "" ||
    localStorage.getItem("User_SearchDefination") === undefined
      ? ""
      : localStorage.getItem("User_SearchDefination");

  var Response = await QuerySearch_Backend(
    UserSearch,
    SearchNumber,
    SearchType,
    selectedIndustry_Code,
    selectedCountry_Code,
    selectedCompany_Code,
    SearchDefination
  );
  Response["MergeStatus"] = state_Count;
  localStorage.setItem("Search_Response", JSON.stringify(Response));
  console.log(Response);
  return true;
  // alert("Query: " + UserSearch + " Location: " + selectedCountry_Code);
};

//flatten

export const Flatten = (json, flattened, str_key) => {
  for (var key in json) {
    if (json.hasOwnProperty(key)) {
      if (json[key] instanceof Object && json[key] !== "") {
        Flatten(json[key], flattened, str_key + "." + key);
      } else {
        flattened[str_key + "." + key] = json[key];
      }
    }
  }
};
//csv download
export const DownloadCSV = (props) => {
  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(props.JsonData)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "UserData.json";

    link.click();
  };
  const [userEmail] = useState(
    // JSON.parse(localStorage.getItem("user_details")).username
    GetUserData().login_email
  );

  return (
    <>

      <CsvDownload
        data={props.dataDetailFormatted}
        filename={props.fileName + ".csv"}
        id={props.id}
        title="Download as CSV"
        className="HeaderButtons btn btn-primary"
      >
      <span><img src={Csv} width={"35"}></img>  Export as CSV</span>
      {/* <span><i className="fa-solid fa-file-csv"></i>  Export ss CSV</span> */}
      </CsvDownload>

      <button
        className="HeaderButtons btn btn-primary"
        title="Download as JSON"
        onClick={() => {
          if (props.Deduct) {
            var response = UpdateCredits_Backend(
              userEmail,
              Basic_Function_Cost,
              "down","StandAlone"
            );
            response
              .then((data) => {
                if (!data.includes("null")) {
                  exportData();
                } else {
                  //pricing modal
                  toast.error("You Dont Have Enough Credits");
                  props.PricingModal();
                }
              })
              .catch((e) => {
                console.log(e);
                toast.error("Something went Wrong");
              });
          } else {
            exportData();
          }
        }}
        id={props.id}
      >
        <span><img src={Json} width={"35"}></img> Export as JSON</span>
      </button>
    </>
  );
};

export const GetCurrTime=()=>{

  var CurrDT = new Date().toLocaleString()
   var TimeOnly= CurrDT.substring(CurrDT.indexOf(",")+ 1);
   return TimeOnly
  
}
export const GetCurrDate=()=>{

  var CurrDT = new Date().toLocaleString()
  var DateOnly= CurrDT.substring(0, CurrDT.indexOf(","));
return DateOnly
}

export const GetUserEmail=()=>{
  return JSON.parse(localStorage.getItem("user_details")).username
}

export const FilteExportData=(data)=>{
  var Leadobj={
    userEmail:GetUserData().login_email,
    LeadName:"",
    LeadImage:"",
    LeadAbout:"",
    ContactEmail:"",
    ContactPhone:"",
    ContactWebsite:"",
    ContactLinkedin:"",
    ContactOther:"",
    AddedDate:GetCurrDate()

 };    
 var ExportArray=[]
  data.forEach((element)=>{
    Leadobj.LeadName=element.ProfileName;
    Leadobj.LeadImage=element.ProfileImage;
    element.BasicDetails.forEach((innerItem1)=>{
      Leadobj.LeadAbout+=innerItem1+" "
    })
    element.ContactList.forEach((innerItem2)=>{
      if(innerItem2.contactType=="Email"){
        Leadobj.ContactEmail=innerItem2.contactVal
      }
       if(innerItem2.contactType=="LinkedIn"){
        Leadobj.ContactLinkedin=innerItem2.contactVal
      }
    if(innerItem2.contactType=="Phone"){
        Leadobj.ContactPhone=innerItem2.contactVal
      }
    if(innerItem2.contactType=="Website"){
        Leadobj.ContactWebsite=innerItem2.contactVal
      }
      if(innerItem2.contactType=="Other"){
        Leadobj.ContactOther=innerItem2.contactVal
      }


    })
    ExportArray.push(Leadobj)


  })

return(ExportArray)
}