import React from 'react'
import '../Pages/customcss/Custom.css'
import DEFAULT from '../../assets/images/user.png'
export const Details = (props) => {
  return (
    <div className='ModalBg text-white'>
    <div className="ModalBox">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">{props.Data.Lead_Name}</h5>
        <button type="button" className="close" onClick={()=>props.closeModal()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
    <div className='d-flex justify-content-center'>

    

<div className='p-2 text-white text-center'>

{props.Data.Lead_Image!==""?
    <img src={props.Data.Lead_Image}  className="Detail_Image" width={"150"}></img>:
<img src={DEFAULT}  className="Detail_Image" width={"150"}></img>}
<h4 className='mb-3'>{props.Data.Lead_Name}</h4>
<div className='p-4 Detail_Info'>
    
    <p>{props.Data.Lead_About} </p>
    {props.Data.Contact_Phone===""?
    <></>
    :
      < p> <i className="fa-solid fa-mobile-screen"></i> {props.Data.Contact_Phone} </p>
        }
     {props.Data.Contact_Email===""?
    <></>
    :
      < p> <i className="fa-regular fa-envelope"></i> {props.Data.Contact_Email} </p>
        }
         
        <a className="btn btn-primary "href={props.Data.Contact_Linkedin} target="_blank" >Visit Profile</a>
        </div>
    

</div>

    </div>


      </div>
    
    </div>
  </div>
</div>
</div>
  )
}
