import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/logo-white.svg";
import defaultavatar from "../../assets/images/defaultavatar.png";
import { GetUserData ,EncryptVal, GetCurrTime } from "../../Utils/GlobalFunctions";
import { Login_User } from "../../Utils/ApiCalls";
import toast, { Toaster } from "react-hot-toast";
class Lockscreen extends React.Component {
  state={
    User_Data:GetUserData(),
    UserPassword:"",
    UserEmail:GetUserData().login_email,
    invalidCred:false
  }

  FieldValidation=()=>{   
    var PassField=document.getElementById('logbackin')    
  if(this.state.UserPassword===""){   
    PassField.classList.add("bg-danger")    
    return false
  }
  
  else { 
    PassField.classList.remove("bg-danger")    
    return true
  }
  
  
  }

  LoginClick=()=>{
    this.FieldValidation()
   if(this.FieldValidation()){
       var Login_Time=GetCurrTime()
      var Login_Response=Login_User( EncryptVal(this.state.UserEmail),EncryptVal(this.state.UserPassword),EncryptVal(Login_Time))
      toast.promise(
       Login_Response,
       {
         loading: 'Please Wait',
         success: (data) => {
           if(data.LoginStatus.includes("Invalid")){
             
            this.setState({invalidCred:true})
             return 'Invalid Credentials'
             }
             else{
               this.setState({invalidCred:false})
              setTimeout(() => {
                 window.location.href=`${process.env.PUBLIC_URL}/dashboard`
                }, 800);
                var UserObject={
                  "credsBought":data.UserObject.credits_bought,
                  "credSpent":data.UserObject.credits_spent,
                  "CredsCount":data.UserObject.user_credits,
                 
                 }
                 localStorage.setItem("Creds_Detail",JSON.stringify(UserObject))
    
                localStorage.setItem("LoggedStatus","true")
                localStorage.setItem("User_Data",JSON.stringify(data.UserObject))
                return "Succesfully Logged In "
             }
        },
         error: (err) => `Something Went Wrong Please Try Again`,
       },
       {
         style: {
           minWidth: '250px',
         },
         success: {
           duration: 5000,
           
         },
       }
     );
   
   

   }
     }
LogoutCick=()=>{
  localStorage.clear()
}

  render() {
    return (
      <div className="theme-cyan">
        <Toaster/>
        <div >
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img src={Logo} alt="Lucid" style={{ height: "40px", margin: "10px" }} />
                </div>
                <div className="card">
                  <div className="body">
                    <div className="user text-center m-b-30">
                     { this.state.User_Data.user_image==="null"||this.state.User_Data.user_image===""||this.state.User_Data.user_image==="nill" ?
                     <img style={{ height: "140px",  }}
                        alt="Avatar"
                        className="rounded-circle"
                        src={defaultavatar}
                      />
                    :
                    <img
                        alt="Avatar"
                        className="rounded-circle"
                        src={this.state.User_Data.user_image}
                      />

                    }
                      <h4 className="name m-t-10">{this.state.User_Data.user_fullName}</h4>
                      <p>{this.state.User_Data.login_email}</p>
                    </div>
                    <form  className="ng-untouched ng-pristine ng-valid">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Enter your password ..."
                          type="password"
                          id="logbackin"
                          onChange={val => {
                            
                            this.setState({UserPassword:val.target.value})
                          }}
                        />
                      </div>
                      {/* <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={() => { window.location.pathname="/templates/lucid/react/dashboard" }}>
                        Login
                        </button> */}
                        <a className="btn btn-primary btn-lg btn-block text-white" onClick={()=>this.LoginClick()}>
                          
                          Login
                        </a>
                    </form>
                  </div>
                  <div className="bottom ml-5 mb-5">
                       
                        <span>
                          Login with another account?{" "}
                          <a href="login"  onClick={()=>this.LogoutCick()}>Click Here</a>
                        </span>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Lockscreen.propTypes = {
};

const mapStateToProps = ({ loginReducer }) => ({
});

export default connect(mapStateToProps, {
})(Lockscreen);
