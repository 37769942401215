import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { LeadsData } from "../../Utils/DummyData";
import DEFAULT from '../../assets/images/user.png'
import { Details } from "./Details";

const HoverRows=(props)=>  {
const [ItemObj, setItemObj] =useState({})
const [ModalState, setModalState] =useState(false)

const ShowDetails=(Item)=>{
setItemObj(Item)

setModalState(true)

}
    return (
     
        <div className="card">
          <div className="header">
            <h2>
              Leads Management{" "}
              <small>
          
              </small>
            </h2>
          </div>
          <div className="body table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {/* <th width="10%"></th> */}
                  <th width="5%"></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Linkedin Profile</th>
                  <th>Phone</th>
                  <th>Website</th>
                  <th>Source</th>
                  <th width="20%">Action</th>
                </tr>
              </thead>
              <tbody>
              {props.Data.map((item,key)=>{  
                return(
                  <tr key={key}>
                  {/* <td width="10%" className="d-flex justify-content-center">  <input class="form-check-input" type="checkbox" value=""/></td> */}
                 {item.Lead_Image=="nill" || item.Lead_Image=="" ?
                 <td width="5%"><img width={"55"} src={DEFAULT}></img></td>:

                  <td width="5%"><img width={"55"} src={item.Lead_Image}></img></td>}
                  <td onClick={()=>ShowDetails(item)}>{item.Lead_Name}</td>

                  <td>{  item.Contact_Email==""||item.Contact_Email=="nill"?
                  <span>--</span> 
                  :
                  item.Contact_Email
                }</td>

                  <td>{
                    item.Contact_Linkedin==""||item.Contact_Linkedin=="nill"?
                   <span>--</span> 
                    :
                    <a href={item.Contact_Linkedin} target="_blank" >View Profile</a>
                    }</td>
                  <td>{
                    item.Contact_Phone==""||item.Contact_Phone=="nill"?
                    <span>--</span> 
                     :
                 <span> {item.Contact_Phone}</span>
                  }</td>
                  <td>
                  {
                    item.Contact_Website==""||item.Contact_Website=="nill"?
                    <span>--</span> 
                     :
                 <span><a target="_blank" href={item.Contact_Website.substring(0, item.Contact_Website.lastIndexOf('/'))}>Visit Website</a></span>
                  }
                    </td>
                    <td>Linkedin</td>
                  <td width="20%">      
                    
                      <div className="btn-group" role="group">
                  <Dropdown as="div">
                    <Dropdown.Toggle
                      variant="success"
                      as="button"
                      id="dropdown-basic"
                      className="btn btn-primary"
                    >
                      Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      as="ul"
                      className="dropdown-menu dropdown-menu-right"
                    >
                      <a className="dropdown-item">Send Email</a>
                      <a className="dropdown-item">Export</a>
                      <a className="dropdown-item">Add to campaign</a>
                      <a className="dropdown-item" onClick={()=>ShowDetails(item)}>Details</a>
                      <a className="dropdown-item">Delete</a>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                
                </td>
                
                </tr>
                )
              }) }
                
              </tbody>
            </table>
          </div>

        {ModalState?
          <Details Data={ItemObj} closeModal={()=>setModalState(false)}/>
        :
        <></>
        }


        </div>
    
    );
  
}



export default HoverRows;
