import "../Css_Files/Checkout_Modal.css";
import { useState } from "react";
import "../Css_Files/ProfileModal.css";
import { DownloadCSV, GetCurrTime } from "../Utils/GlobalFunctions";
import "animate.css";
import { Flatten } from "../Utils/GlobalFunctions";
import PlaceHolder from "../images/logo3.png";
import { GettingMultipleProfileData, UpdateCredits_Backend } from "../Utils/ApiCalls";
import toast, { Toaster } from "react-hot-toast";
import { Basic_Function_Cost, Mini_Function_Cost } from "../Utils/DummyData";
import PricingModal from "./PricingModal";
import React from "react"

export default function CheckoutModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [PricingModalState, setpricingModalState]=useState(false);
  const [FinalArray, setFinalArray] = useState([]);
  const [ExportData, setExportData]=useState([])
  const [userEmail]=useState(JSON.parse(localStorage.getItem("user_details")).username)
  const toggleContinue = () => {
    setIsOpen(!isOpen);
  };
  const GettingData = async () => {
    setLoading(true);
    var LinkList = [];
    try {
      LinkList = JSON.parse(
        localStorage.getItem("SelectedDataArray_Link") === undefined
          ? "[]"
          : localStorage.getItem("SelectedDataArray_Link") === null
          ? "[]"
          : localStorage.getItem("SelectedDataArray_Link")
      );
    } catch {
      LinkList = [];
    }
    var response = GettingMultipleProfileData(LinkList);
    response
      .then((data) => {
        if (data != null) {
          setExportData(data)

          var arrayFinal = [];          
          data.forEach((item) => {
            var flattened = {};
            Flatten(item, flattened, "");
            arrayFinal.push(flattened);
          });
          setFinalArray(arrayFinal);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

const updatePricingModal=()=>{
  setpricingModalState(false)
}

  if (props.ModalState) {
    return (
      <div className="popup-box">
        {/* <Toaster/> */}
        <div className="box animate__animated animate__fadeInUp">
          <div className="row ModalHeader">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-start">
              <h4>Checkout</h4>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
              {" "}
              <span
                className="close-icon"
                onClick={() => {
                  props.HandleClose();
                  setIsOpen(false);
                }}
              >
                <i className="fa fa-close" />
              </span>
            </div>
          </div>
          <div className="Ceckout_Body">
            <div className="row d-flex justify-content-end">
              {!isOpen ? (
                <>
                  {/* User List Start */}
                  <div className="container">
                    <div className="row">
                      <br />
                      <div className="col-md-12">
                        {/* <div className=" col-lg-12 col-md-12 col-sm-6 col-xs-12 col-md-push-6 col-sm-push-6"></div> */}
                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 col-md-pull-6 col-sm-pull-6">
                          <div className="">
                            <div className="panel-body">
                              <table className="table borderless" id="Checkout_Table">
                                <thead>
                                  <tr>
                                    <td className="text-left">
                                      <strong>Seleted Accounts</strong>
                                    </td>                                 
                                 
                                    <td className="text-right">
                                      <strong>Credits Required</strong>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* <!-- foreach ($order->lineItems as $line) or some such thing here --> */}
                                  {props.ModalData.map((item, key) => (
                                    <tr key={key}>
                                      <td className="col-md-3">
                                        <div className="media">
                                          <a
                                            className="thumbnail pull-left"
                                            href="#"
                                          >
                                            {item.DispImg == null ? (
                                              <img
                                                className="dispImg mr-3"
                                                src={PlaceHolder}
                                              />
                                            ) : (
                                              <img
                                                className="dispImg mr-3"
                                                src={item.DispImg}
                                              />
                                            )}{" "}
                                          </a>
                                          <div className="media-body">
                                            <h6 className="DispName">
                                              {item.DispName}
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-right">5 Credits</td>
                                    </tr>
                                  ))}
                                  <tr>
                                  <td className="text-left">
                                  Total Accounts Selected: <strong> {props.ModalData.length}</strong>
                                    </td>                                 
                                 
                                    <td className="text-right">
                                    Total: <strong> {props.ModalData.length*5} Credits</strong> 
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                    
                        </div>
                       
                      </div>
                    </div>
                    <div className="ContinueBtn">
                    <button
                      className="btn hvr-grow"
                      onClick={() => {
                        
                        var response=UpdateCredits_Backend(userEmail,props.ModalData.length*Mini_Function_Cost,"down","StandAlone")
                        response.then((data)=>{
                          if(!data.includes("null")){
                            toggleContinue();
                            GettingData();
                            }
                        else{
                          //pricing modal
                          toast.error("You Dont Have Enough Credits")
                          setpricingModalState(true)                  
                          
                        }
  
                        }).catch((e)=>{console.log(e)
                          toast.error("Something went Wrong")
                        })
                      }}
                    >
                      Continue
                    </button>
                    <PricingModal ModalState={PricingModalState} ModalStateCallback={()=>updatePricingModal()}/>
                  </div>
                  </div>
                  {/* USer List End */}
                  
                </>
              ) : (
                <>
                  <div className="ExitDiv">
                    {isLoading ? (
                      <div>
                        <h4>Loading...</h4>
                        <div className="d-flex justify-content-center">
                          <div className="Progress">
                            <div className="Progress-value"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h4>Select Your Action...</h4>
                        {/* <button
                      className="btn btn-success"
                      onClick={toggleContinue}
                    >
                      Back
                    </button> */}

                    <DownloadCSV
                      fileName="FileMain"
                      dataDetailFormatted={FinalArray}
                      JsonData={ExportData}
                      Deduct={false}
                    />
                      </>
                    )}
                   
                  </div>
                  {/* extdivend */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
