import React, { Component } from "react";
import "../Css_Files/ProfileModal.css";
import SideInfo from "./SideInfo";
import { ModalData } from "../Utils/DummyData";
import storageChanged from "storage-changed";
import PlaceHolder from '../images/logo3.png'
import No_Record from "./NoRecord";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from "@mui/material";
// import { UserData } from "../Utils/DummyArray";
import { Flatten } from "../Utils/GlobalFunctions";
import { DownloadCSV } from "../Utils/GlobalFunctions";
import PricingModal from "./PricingModal";

import { Advance_Function_Cost, Mini_Function_Cost, Users } from "../Utils/DummyData";
import { UpdateCredits_Backend } from "../Utils/ApiCalls";
  

export class ProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TestData:[],
      FlattenData:{},
      ContactisOpen: false,
      PricingModalState:false,
      userEmail:JSON.parse(localStorage.getItem("user_details")).username,
      ModalData:
        localStorage.getItem("SingleProfileData") == null
          ? {} :
          localStorage.getItem("SingleProfileData") == "undefined"
          ? "undefined"
          : JSON.parse(localStorage.getItem("SingleProfileData")),
    };
  }
  toggleContact = () => {
    this.setState({ ContactisOpen: !this.state.ContactisOpen });
  };
  componentDidMount() {
    

 
storageChanged("local", {
      eventName: "changeinSingleProfilestorage",
    });
    window.addEventListener("changeinSingleProfilestorage", (event) => {
      if (event.detail.key === "SingleProfileData") {
        if (event.detail.value === "undefined") {
          this.setState({
            ModalData: event.detail.value,
          });
        } else {
          this.setState({
            ModalData: JSON.parse(event.detail.value),
          },()=>
          {
            var flattened = {};
            Flatten(this.state.ModalData, flattened, "");
            var FlattenedArray=[];
            FlattenedArray.push(flattened)
           this.setState({
          FlattenData:FlattenedArray
        })

          });
        }
      }
    });
   
  }


  componentWillUnmount() {
    window.removeEventListener("changeinSingleProfilestorage", () => {
      alert("listener remove");
    });
  }
   UpdatePricingModal (){
    this.setState({PricingModalState:false})
  }
  ShowPricingModal(){
    this.setState({PricingModalState:true})
  }

  render() {
    if (this.props.modalState) {
      return (
        <div className="popup-box">
          {/* <Toaster/> */}
          <div className="box">
            <div className="row ModalHeader">
              <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-start">
                <h4 className="">User Details</h4>                
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-start">
                <Tooltip title="10 Credits" placement="bottom" arrow>
                <DownloadCSV
                fileName={this.state.ModalData.ProfileName}
                dataDetailFormatted={this.state.FlattenData}
                JsonData={this.state.ModalData}
                Deduct={true}
                PricingModal={()=>this.ShowPricingModal()}
                id="Single_Profile"
              /></Tooltip>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end">
                {" "}
                <span className="close-icon" onClick={this.props.handleClose}>
                  <i className="fa fa-close" />
                </span>
              </div>
            </div>
            {this.state.ModalData === "undefined"? (
                 <div className="Loader">
                 <div className="circle"></div>
                 <div className="circle"></div>
                 <div className="circle"></div>
                 <div className="shadowLoader"></div>
                 <div className="shadowLoader"></div>
                 <div className="shadowLoader"></div>
                 <span>Loading</span>
             </div>
            
            ) : this.state.ModalData === {} ? (
              <No_Record/>
            ) : (
              <div className="modalBody">
                 {/* <Toaster/> */}
                <PricingModal ModalState={this.state.PricingModalState} ModalStateCallback={()=>this.UpdatePricingModal()}/>   
                {/* Main Modal Row */}
                <div className="row MainRow">
                  {/* Main Column 1 Start */}
                  <div className="col-lg-7 col-md-7 col-sm-12 col-12 pr-5">
                    <div className="row TopRow1">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 MainCol1">
                        <div className="row InnerTopRow1 shadow rounded">
                          <div className="col-xl-3 col-lg-5 col-md-5 col-sm-4 col-5 ModalPic">
                            {this.state.ModalData.ProfileImage==""?
                            <img src={PlaceHolder}/>:
                            <
                              img
                              src={this.state.ModalData.ProfileImage}
                              alt="User Image"
                            />
                          
                          }
                          </div>
                          <div className="col-xl-9 col-lg-7 col-md-7 col-sm-8 col-7 ModalName">
                            <h4> {this.state.ModalData.ProfileName}</h4>
                           { this.state.ModalData.BasicDetails===null?<No_Record/>:
                           <div className="basicDetails">
                              {this.state.ModalData.BasicDetails.map(
                                (item, key) => (
                                  <p key={key} className={"Sub_Details" + key}>
                                    {item}
                                  </p>
                                )
                              )}
                            </div>}
                          </div>
                        </div>
                        <div className="row UserSummary">
                          <h4>Summary</h4>
                          
                          {
                            this.state.ModalData.Summary===""?<No_Record/>:
                            <p>{this.state.ModalData.Summary}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="row BottomRow1">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 MainCol3 ">
                      <div className="ContactDiv">
                          <h4>Contact Info</h4>
                        </div>
{this.state.ModalData.ContactList==[]?
<No_Record/>
:  
  <div className="ContactsData">
                        {this.state.ContactisOpen ? (
                          this.state.ModalData.ContactList.map((item, key) => (
                            <div className="row RealContactInfo" key={key}>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6 RealContactInfo">
                                <h6>{item.contactType} </h6>
                                <p>{item.contactVal}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          
                          <div className="NoCreditsData">
                            <h6>Profile</h6>
                            <p>{this.props.ProfileLink}</p>
                           {this.state.ModalData.ContactList.length>1?
                           
                           <div className="">
                            <div className="row DummyContact">
                              <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                                {" "}
                                <h6>Email </h6>
                                <p>DummyUser@gmail.com</p>{" "}
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                <h6> Dummy Phone </h6>
                                <p>+1-234-56789</p>{" "}
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                <h6> Dummy Skype</h6>
                                <p>@username</p>{" "}
                              </div>
                            </div>
                            <div className="buyCredits">
                              <h5>Unlock full contact info for 50 Credits</h5>
                              <button
                                className="btn btn-success"
                                onClick={() => this.toggleContact()}
                              >
                                Unlock Now
                              </button>
                            </div>
                          </div>:<></>}
                          </div>
                        )}

 </div>}
                        

                      </div>
                    </div>
                  </div>
                  {/* Main Column 1 Ends */}

                  {/* Main Column 2 Start */}
                  <div className="col-lg-5 col-md-5 col-sm-12 col-12 MainCol2">
                    {/* Education & Experience Info */}

                    <div className="row BottomRow1">
                      <SideInfo
                        Heading="Experience"
                        DataType={this.state.ModalData.ExperienceList}
                        firstItem={this.state.ModalData.ExperienceList[0]}
                        pricingModal={()=>this.ShowPricingModal()}
                      />
                      <SideInfo
                        Heading="Education"
                        DataType={this.state.ModalData.EducationList}
                        firstItem={this.state.ModalData.EducationList[0]}
                        pricingModal={()=>this.ShowPricingModal()}
                      />
                    </div>
                    {/* Skills info */}
                    <div className="row BottomRow2">
                      <div className="SkillListHeading">
                        {" "}
                        <h4>Skills</h4>
                        { this.state.ModalData.SkillsList===null?
                        <No_Record/>:
                        this.state.ModalData.SkillsList.map((item, key) => (
                          <ul className="SkillList d-inline" key={key}>
                            <li>{item}</li>
                          </ul>
                        ))}
                      </div>

                    </div>
                    {/* skill info end */}
                    
                  </div>
                </div>
              </div>
            )}
          </div>
              </div>
      );
    } else {
      return <></>;
    }
  }
}

export default ProfileModal;
