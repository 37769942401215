import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../assets/images/ourlogo_White.png";
import { updateEmail, updatePassword, onLoggedin } from "../actions";
import moment from "moment";
import {
  EncryptVal,
  GetCurrTime,
  GetUserLocation,
} from "../Utils/GlobalFunctions";
import { Login_User } from "../Utils/ApiCalls";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      UserEmail: "",
      UserPassword: "",
      curDT: new Date().toLocaleString(),
      invalidCred: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoad: false,
      });
    }, 500);
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");

    GetUserLocation();
  }

  FieldValidation = () => {
    var EmailField = document.getElementById("signin-email");
    var PassField = document.getElementById("signin-password");
    if (this.state.UserEmail === "" && this.state.UserPassword === "") {
      EmailField.classList.add("bg-danger");
      PassField.classList.add("bg-danger");

      return false;
    } else if (
      this.state.UserEmail === "" ||
      !this.state.UserEmail.includes("@") ||
      !this.state.UserEmail.includes(".")
    ) {
      EmailField.classList.add("bg-danger");
      PassField.classList.remove("bg-danger");
      return false;
    } else if (this.state.UserPassword === "") {
      PassField.classList.add("bg-danger");
      EmailField.classList.remove("bg-danger");
      return false;
    } else {
      EmailField.classList.remove("bg-danger");
      PassField.classList.remove("bg-danger");

      return true;
    }
  };

  LoginClick = () => {
    this.FieldValidation();
    if (this.FieldValidation()) {
      var Login_Time = GetCurrTime();
      var Login_Response = Login_User(
        EncryptVal(this.state.UserEmail),
        EncryptVal(this.state.UserPassword),
        EncryptVal(Login_Time)
      );
      toast.promise(
        Login_Response,
        {
          loading: "Please Wait",
          success: (data) => {
            if (data.LoginStatus.includes("Invalid")) {
              this.setState({ invalidCred: true });
              return "Invalid Credentials";
            } else {
              this.setState({ invalidCred: false });

              var UserObject = {
                credsBought: data.UserObject.credits_bought,
                credSpent: data.UserObject.credits_spent,
                CredsCount: data.UserObject.user_credits,
              };
              localStorage.setItem("Creds_Detail", JSON.stringify(UserObject));
              localStorage.setItem("LoggedStatus", "true");
              localStorage.setItem(
                "User_Data",
                JSON.stringify(data.UserObject)
              );
              localStorage.setItem("SearchStats", JSON.stringify(data.SearchStat));
              localStorage.setItem("TransStat", JSON.stringify(data.TransStat));
              window.location.href=`${process.env.PUBLIC_URL}/`
              return "Succesfully Logged In ";
            }
          },
          error: (err) => `Something Went Wrong Please Try Again`,
        },
        {
          style: {
            minWidth: "250px",
          },
          success: {
            duration: 5000,
          },
        }
      );
    }
  };
  RememberStatusChange = () => {
    var rememberStatus = localStorage.getItem("RememberStatus");
    if (rememberStatus === "true") {
      localStorage.removeItem("RememberStatus");
    } else {
      localStorage.setItem("RememberStatus", "true");
    }
  };
  render() {
    const { navigation } = this.props;
    const { email, password } = this.props;
    return (
      <div className="theme-cyan">
        <Toaster />
        <div
          className="page-loader-wrapper"
          style={{ display: this.state.isLoad ? "block" : "none" }}
        >
          <div className="loader">
            <div className="m-t-30">
              <img
                src={require("../assets/images/logo-icon.svg")}
                width="48"
                height="48"
                alt="Lucid"
              />
            </div>
            <p>Please wait...</p>
          </div>
        </div>
        <div className="hide-border">
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Login to your account</p>
                  </div>
                  <div className="body">
                    <div className="form-auth-small" action="index.html">
                      <div className="form-group">
                        {this.state.invalidCred ? (
                          <div className="text-danger">Invalid Credentials</div>
                        ) : (
                          <></>
                        )}
                        <label className="control-label sr-only">Email</label>
                        <input
                          className="form-control"
                          id="signin-email"
                          placeholder="Email"
                          required
                          type="email"
                          /* value={email} */

                          onChange={(val) => {
                            this.props.updateEmail(val.target.value);
                            this.setState({ UserEmail: val.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signin-password"
                          required
                          placeholder="Password"
                          type="password"
                          /* value={password} */

                          onChange={(val) => {
                            this.props.updatePassword(val.target.value);
                            this.setState({ UserPassword: val.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group clearfix">
                        <label className="fancy-checkbox element-left">
                          <input
                            type="checkbox"
                            onChange={(e) => this.RememberStatusChange(e)}
                          />
                          <span>Remember me</span>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={() => this.LoginClick()}
                      >
                        Login
                      </button>
                      {/* <a
                        className="btn btn-primary btn-lg btn-block"
                        href="#"
                        onClick={()=>this.LoginClick()}
                      >Login</a> */}
                      <div className="bottom">
                        <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <Link to={"/forgotpassword"}>
                            Forgot password?
                          </Link>
                        </span>
                        <span>
                          Don't have an account?{" "}
                          <a href="registration">Register</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  updateEmail: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

const mapStateToProps = ({ loginReducer }) => ({
  email: loginReducer.email,
  password: loginReducer.password,
});

export default connect(mapStateToProps, {
  updateEmail,
  updatePassword,
  onLoggedin,
})(Login);
