import { useState } from "react";
import "../Css_Files/PricingModal.css";
import React from "react"

import "animate.css";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { PackageDetails,CreditsDetails } from "../Utils/DummyData";
import { Change_Plan, Stripe_Checkout, UpdateCredits_Backend } from "../Utils/ApiCalls";
import { GetCurrTime } from "../Utils/GlobalFunctions";
import { GetUserData } from "../../Dashboard/Utils/GlobalFunctions";
import '../../WebApp/Css_Files/Checkout_Modal.css'
export default function PricingModal(props) {
  const [isOpen, setIsOpen] = useState(props.ModalState);
const[stateModal,setModalState]=useState(false);
const[Pricing_Toggle, setPricing_Toggle]=useState(false);
const userEmail= GetUserData().login_email
const [UserPlan, setuserPlan]=useState("")
const [disableBtn, setdisableBtn]=useState("")
const StipreCheckout=(PriceID,Mode,SubName, BTN)=>{

  setdisableBtn("DisabledButton")
  
  var Domain= window.location.protocol + '//' + window.location.host +'/' 
if(SubName==""){  
var response=Stripe_Checkout(Domain, PriceID,Mode,"Nill")
}
else{
  var response=Stripe_Checkout(Domain, PriceID,Mode,SubName)
}
toast.promise(
  response,
  {
    loading: 'Please Wait',
    success: (data) => {
      if(data!=="null"){
        if (data.MainUrl=="Something Went Wrong"){
          setdisableBtn("")
          
          toast.error("Something went wrong")
        }
        else{
          setdisableBtn("DisabledButton")
         
          window.location.href=data.MainUrl
          return 'Redirecting to payment gateway'
        }
          
      }
      else{
        toast.error("Something went wrong")
      }
   },
    error: (err) => `Something Went Wrong Please Try Again`,
  },
  {
    style: {
      minWidth: '250px',
      position: "top-center"
    },
    success: {
      duration: 3000,      
    },
  }
);


}

const ChangePlan=(PriceID,Mode,SubName)=>{
  var Domain= window.location.protocol + '//' + window.location.host +'/' 
  setdisableBtn("DisabledButton")

    var response=Change_Plan(Domain, PriceID,Mode,SubName)
  
    toast.promise(
      response,
      {
        loading: 'Please Wait',
        
        success: (data) => {
          if(data!=="null"){

            if (data.MainUrl=="Something Went Wrong"){
              toast.error("Something went wrong")
              setdisableBtn("")
            }
            else{
              setdisableBtn("")
              window.location.href=data.MainUrl
              return 'Redirecting to payment gateway'
            }
              
          }
          else{
            toast.error("Something went wrong")
          }
       },
        error: (err) => `Something Went Wrong Please Try Again`,
      },
      {
        style: {
          minWidth: '250px',
          position: "top-center"
        },
        success: {
          duration: 3000,      
        },
      }
    );
}



  useEffect(() => {
  
if(!stateModal){
    setModalState(true)
}
else{
setIsOpen(props.ModalState)
}

setuserPlan(GetUserData().PurchasingDetails.Subscription.user_subscription)

});

  if (isOpen) {
    return (
      <div className="popup-Modal">

        <div className="box box_Pricing animate__animated animate__bounceInUp">
          <div className="row">
            <div className="col-lg-11 col-md-10 col-sm-10 col-11">
              {/* Pricing Header */}
    <header className="ml-5 text-center">
    {/* <Toaster
      position="top-right"

    /> */}

      <div className="row Pricing_Header">
        <div className="col-lg-12 mx-auto">
          <h1>Pricing Plans</h1>
          <p>No More Hidden Charges&nbsp;Choose your plan<br/></p>
        </div>
      </div>
    </header>
    {/* <!-- END --> */}
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex justify-content-end">
              {" "}
              <span
                className="close-icon PriceClose"
                onClick={() => {
                  {props.ModalStateCallback()}
                }}
              >
                <i className="fa fa-close" />
              </span>
            </div>
          </div>
          <div className="Pricing_Body">
          <section>
  <div className="container py-4"> 
    <div className="row text-center align-items-end">
      {/* <!-- Pricing Table--> */}
      <div className="mb-4">
        <button className="btn Price_Toggle shadow" onClick={()=>setPricing_Toggle(true)}>Credits</button>
      <button className="btn Price_Toggle shadow"onClick={()=>setPricing_Toggle(false)}>Memberships</button></div>
     { Pricing_Toggle===false?
     PackageDetails.map((item, key) => (
      <div className="col-lg-4 mb-5 mb-lg-5" key={key}>
        <div className="bg-white p-5 rounded-lg shadow Item">
          {/* Ribbon */}
        {item.Ribbon!==""?
        
          <div className="ribbon">{item.Ribbon}</div>
          
          :
          <></>
          }
          {/* Ribbon End */}
          <h1 className="h6 text-uppercase font-weight-bold mb-4">{item.PackageName}</h1>
          <img src={item.PackageImg} className="PricingImg mb-4"/>
          <h6 className="card-price text-center">{item.PackagePrice}<span className="period">/month</span></h6>

          <div className="custom-separator my-4 mx-auto bg-primary"></div>

          <ul className="list-unstyled my-5 text-small text-left featureList">
            {item.IncludedFeatures.map((Feature, key) => (
              <li key={key} className="mb-2">
              <i className="fa fa-check mr-2 text-primary"></i>{Feature}</li>))
              }
       
          {item.ExcludedFeatures!=""? item.ExcludedFeatures.map((Nofeature, key) => (
           <li key={key} className="mb-2 text-muted">
              <i className="fa fa-times mr-2"></i>
              <del>{Nofeature}</del>
            </li>)):<></>
            }
          
          </ul>
          {  UserPlan==="Free"      ?
          
          <a className={"btn btn-primary btn-block p-2 shadow rounded-pill text-white  " +disableBtn} onClick={(e)=>StipreCheckout(item.PriceID, item.Mode, item.PackageName)}>Subscribe Now</a>
          :
          UserPlan==item.PackageName ?
          <a className="btn btn-primary btn-block p-2 shadow rounded-pill text-white DisabledButton" disabled >Active plan</a>
          :
    UserPlan==="Basic" && item.PackageName!== UserPlan?
    <a className={"btn btn-primary btn-block p-2 shadow rounded-pill text-white  " +disableBtn} onClick={(e)=>ChangePlan(item.PriceID, item.Mode, item.PackageName)}>Upgrade</a>

    :
    UserPlan==="Standard" && item.PackageName!== UserPlan && item.PackageName=="Basic"?
          <a className={"btn btn-primary btn-block p-2 shadow rounded-pill text-white  " +disableBtn} onClick={(e)=>ChangePlan(item.PriceID, item.Mode, item.PackageName)}>Downgrade</a>
:
UserPlan==="Standard" && item.PackageName!== UserPlan && item.PackageName=="Enterprise"?
<a className={"btn btn-primary btn-block p-2 shadow rounded-pill text-white  " +disableBtn} onClick={(e)=>ChangePlan(item.PriceID, item.Mode, item.PackageName)}>Upgrade</a>
:
<a className={"btn btn-primary btn-block p-2 shadow rounded-pill text-white  " +disableBtn}onClick={(e)=>ChangePlan(item.PriceID, item.Mode, item.PackageName)}>Downgrade</a>

          }

        </div>
      </div>)):
      CreditsDetails.map((item, key) => (
      <div className="col-lg-4 mb-5 mb-lg-5" key={key}>
        <div className="bg-white p-5 rounded-lg shadow Item">
    
          <h1 className="h6 text-uppercase font-weight-bold mb-4">{item.PackageName}</h1>
          <img src={item.PackageImg} className="PricingImg mb-4"/>
          <h2 className="h1 font-weight-bold">{item.PackagePrice}</h2>

          <div className="custom-separator my-4 mx-auto bg-primary"></div>
          <div className="NoOfCredits p-3"><span><h2><strong>{item.Credits}</strong></h2>Credits</span></div>
          
          <a className={"btn btn-primary btn-block p-2 shadow rounded-pill text-white  " +disableBtn} onClick={(e)=>StipreCheckout(item.PriceID, item.Mode,"")}>Buy Now</a>
:
         
        </div>
      </div>))
      
      }
      {/* <!-- END --> */}  

    </div>
  </div>
</section>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
