
import React from "react";
import '../Pages/customcss/Custom.css'

const InfoMessage=(props)=>{

return(
    <div className="container text-light info_Section p-2 shadow rounded">
    <div className="row">
      <div className="col-lg-1 col-sm-1 col-md-1 col-1">
        <i className="fa-solid fa-circle-info text-large"></i>
      </div>
      <div className="col-lg-11 col-md-11 col-sm-11 col-11">
        <p>{props.TitleLine}</p>
        <small>
         {props.Description}
        </small><div>
        <small>
         {props.SubDesc}
        </small></div>
      </div>
    </div>
  </div>

);


}
export default InfoMessage