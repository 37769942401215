import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import UserImg from "../assets/images/userimg.jpg";
import "animate.css";
import '../components/Pages/customcss/Custom.css'
import PricingModal from "../../WebApp/Components/PricingModal";
import defaultavatar from "../assets/images/defaultavatar.png";
// import './Pages/customcss/Custom.css'
import {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
} from "../actions";
import Logo from "../assets/images/logo.svg";
import LogoWhite from "../assets/images/ourlogo_White.png";

import Avatar4 from "../assets/images/xs/avatar4.jpg";
import Avatar5 from "../assets/images/xs/avatar5.jpg";
import Avatar2 from "../assets/images/xs/avatar2.jpg";
import Avatar1 from "../assets/images/xs/avatar1.jpg";
import Avatar3 from "../assets/images/xs/avatar3.jpg";
import Geocode from "react-geocode";
import { DecryptVal, Logout } from "../Utils/GlobalFunctions";
import { Toaster } from "react-hot-toast";

class NavbarMenu extends React.Component {
  constructor(props) {
    super(props);
   this.state = {
      CredsObj: this.props.credsDetails,
      linkupdate: false,
      User_Data: this.props.UserObj,
      Pricing_toggle: false,
      UserLocation: JSON.parse(localStorage.getItem("UserLocation")==null||localStorage.getItem("UserLocation")==""||localStorage.getItem("UserLocation")==undefined?"{}":DecryptVal(localStorage.getItem("UserLocation"))),
    };


  }
  componentWillReceiveProps(NewProps){
    if(NewProps.UserObj!==this.state.User_Data){
      this.setState({User_Data:NewProps.UserObj})
    }
    if(NewProps.credsDetails!==this.state.CredsObj){
      this.setState({CredsObj:NewProps.credsDetails})
     
    }
    }
  componentWillMount() {
    document.body.classList.add("full-dark");

  }
  componentDidMount() {
    this.props.tostMessageLoad(true);
    var res = window.location.pathname;
    res = res.split("/");
    res = res.length > 4 ? res[4] : "/";
    const { activeKey } = this.props;
    this.activeMenutabwhenNavigate("/" + activeKey);

    
  }

  activeMenutabwhenNavigate(activeKey) {
    if (
      activeKey === "/dashboard" ||
      activeKey === "/demographic" ||
      activeKey === "/ioT"
    ) {
      this.activeMenutabContainer("dashboradContainer");
    } else if (
      activeKey === "/appinbox" ||
      activeKey === "/appchat" ||
      activeKey === "/appcalendar" ||
      activeKey === "/appcontact" ||
      activeKey === "/apptaskbar"
    ) {
      this.activeMenutabContainer("AppContainer");
    } else if (
      activeKey === "/filemanager/dashboard" ||
      activeKey === "/filedocuments" ||
      activeKey === "/filemedia"
    ) {
      this.activeMenutabContainer("FileManagerContainer");
    } else if (
      activeKey === "/blognewpost" ||
      activeKey === "/bloglist" ||
      activeKey === "/blogdetails"
    ) {
      this.activeMenutabContainer("BlogContainer");
    } else if (
      activeKey === "/uitypography" ||
      activeKey === "/uitabs" ||
      activeKey === "/uibuttons" ||
      activeKey === "/bootstrapui" ||
      activeKey === "/uiicons" ||
      activeKey === "/uinotifications" ||
      activeKey === "/uicolors" ||
      activeKey === "/uilistgroup" ||
      activeKey === "/uimediaobject" ||
      activeKey === "/uimodal" ||
      activeKey === "/uiprogressbar"
    ) {
      this.activeMenutabContainer("UIElementsContainer");
    } else if (
      activeKey === "/widgetsdata" ||
      activeKey === "/widgetsweather" ||
      activeKey === "/widgetsblog" ||
      activeKey === "/widgetsecommers"
    ) {
      this.activeMenutabContainer("WidgetsContainer");
    } else if (activeKey === "/login") {
      this.activeMenutabContainer("WidgetsContainer");
    } else if (
      activeKey === "/teamsboard" ||
      activeKey === "/profilev2page" ||
      activeKey === "/helperclass" ||
      activeKey === "/searchresult" ||
      activeKey === "/invoicesv2" ||
      activeKey === "/invoices" ||
      activeKey === "/pricing" ||
      activeKey === "/timeline" ||
      activeKey === "/profilev1page" ||
      activeKey === "/blankpage" ||
      activeKey === "/imagegalleryprofile" ||
      activeKey === "/projectslist" ||
      activeKey === "/maintanance" ||
      activeKey === "/testimonials" ||
      activeKey === "/faqs"
    ) {
      this.activeMenutabContainer("PagesContainer");
    } else if (
      activeKey === "/formvalidation" ||
      activeKey === "/basicelements"
    ) {
      this.activeMenutabContainer("FormsContainer");
    } else if (activeKey === "/tablenormal") {
      this.activeMenutabContainer("TablesContainer");
    } else if (activeKey === "/echart") {
      this.activeMenutabContainer("chartsContainer");
    } else if (activeKey === "/leafletmap") {
      this.activeMenutabContainer("MapsContainer");
    }
  }

  // componentWillReceiveProps(){
  //   this.setState({
  //     linkupdate:!this.state.linkupdate
  //   })
  // }

  activeMenutabContainer(id) {
    var parents = document.getElementById("main-menu");
    var activeMenu = document.getElementById(id);

    for (let index = 0; index < parents.children.length; index++) {
      if (parents.children[index].id !== id) {
        parents.children[index].classList.remove("active");
      }
    }
  }

  logout_Trigger = () => {
    Logout();
  };
  PricingModal_trigger = () => {
    this.setState({ Pricing_toggle: true });
  };
  PricingModal_Close = () => {
    this.setState({ Pricing_toggle: false });
  };
  render() {
    const {
      addClassactive,
      addClassactiveChildAuth,
      addClassactiveChildMaps,
      themeColor,
      toggleNotification,
      toggleEqualizer,
      sideMenuTab,
      isToastMessage,
      activeKey,
    } = this.props;
    var path = window.location.pathname;
    document.body.classList.add(themeColor);
if(this.state.User_Data===null||this.state.User_Data===undefined){
return<></>
}else{
  return (
    <div>
      <Toaster/>
      {isToastMessage ? (
        <Toast
          id="toast-container"
          show={isToastMessage}
          onClose={() => {
            this.props.tostMessageLoad(false);
          }}
          className="toast-info toast-top-right"
          autohide={true}
          delay={5000}
        >
          <Toast.Header className="toast-info mb-0">
            Hello,{this.state.User_Data.user_fullName} welcome to LeadsFizz
          </Toast.Header>
        </Toast>
      ) : null}
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-btn">
            <button
              className="btn-toggle-offcanvas"
              onClick={() => {
                this.props.onPressSideMenuToggle();
              }}
            >
              <i className="lnr lnr-menu fa fa-bars"></i>
            </button>
          </div>

          <div className="navbar-brand">
            <a href="dashboard">
              <img
                src={
                  document.body.classList.contains("full-dark")
                    ? LogoWhite
                    : Logo
                }
                alt="LeadsFizz Logo"
                className="img-responsive logo"
              />
            </a>
          </div>

          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li>
                  <a
                    href="/"
                    className="icon-menu"
                    onClick={() => this.logout_Trigger()}
                  >
                    <i className="icon-login"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
        <div className="sidebar-scroll">
          <div className="user-account">
            {this.state.User_Data.user_image === null || this.state.User_Data.user_image === "null"  ||this.state.User_Data.user_image == "" ||this.state.User_Data.user_image ==="nill"||this.state.User_Data.user_image ===undefined ? 
            (
              <img
                src={defaultavatar}
                className="rounded-circle user-photo"
                alt="User Profile Picture"
              />
            )
            :
            (
              <img
                src={this.state.User_Data.user_image}
                className="rounded-circle user-photo"
                alt="User Profile Picture"
              />
            )}
            <Dropdown>
              <span>Welcome,</span>
              <Dropdown.Toggle
                variant="none"
                as="a"
                id="dropdown-basic"
                className="user-name"
              >
                <strong>{this.state.User_Data.user_fullName}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-right account Custom_dropdown">
                <Dropdown.Item>
                  <Link to="profile">
                    {" "}
                    <i className="icon-user"></i>My Profile
                  </Link>
          
              
                  <Link to="Subscription">
                    {" "}
                    <i className="icon-settings"></i>Subscriptions
                  </Link>
                </Dropdown.Item>

            
                <li className="divider"></li>
                <Dropdown.Item href="/">
                  {" "}
                  <i className="icon-power"></i>
                  <span onClick={() => this.logout_Trigger()}>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <hr />
            <h6 className="text-center">
              {" "}
              <i className="fa-solid fa-coins"></i> Credits
            </h6>
            <hr />
            <ul className="row list-unstyled">
              <li className="col-4">
                <p>Total</p>
                {/* <h6>{this.state.User_Data.user_credits}</h6> */}
                <h6>{this.state.CredsObj.CredsCount}</h6>

              </li>
              <li className="col-4">
                <p>Spent</p>
                {/* <h6>{this.state.User_Data.credits_spent}</h6> */}
                <h6>{this.state.CredsObj.credSpent}</h6>

              </li>
              <li className="col-4">
                <p>Bought</p>
                {/* <h6>{this.state.User_Data.credits_bought}</h6> */}
                <h6>{this.state.CredsObj.credsBought}</h6>

              </li>

              <div className="align-center">
                <button
                type="button"
                  className="btn btn-primary hvr-grow text-white mt-2"
                  onClick={() => this.PricingModal_trigger()}
                >
                  Buy More Credits
                </button>
              </div>
            </ul>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className={sideMenuTab[0] ? "nav-link active" : "nav-link"}
                data-toggle="tab"
                onClick={() => {
                  this.props.onPressSideMenuTab(0);
                }}
              >
                Menu
              </a>
            </li>

            <li className="nav-item">
              <a
                className={sideMenuTab[3] ? "nav-link active" : "nav-link"}
                data-toggle="tab"
                onClick={() => {
                  this.props.onPressSideMenuTab(3);
                }}
              >
                <i className="icon-question"></i>
              </a>
            </li>
          </ul>
          <div className="tab-content p-l-0 p-r-0">
            <div
              className={sideMenuTab[0] ? "tab-pane active show" : "tab-pane"}
              id="menu"
            >
              <Nav id="left-sidebar-nav" className="sidebar-nav">
                <ul id="main-menu" className="metismenu">
                  <li className="" id="dashboradContainer">
                    <Link to="dashboard">
                      {" "}
                      <i className="icon-home"></i> <span>Dashboard</span>
                    </Link>
                    <li id="AppContainer" className="">
                    <Link to="Leads">
                    <i className="fa-regular fa-address-book"></i> <span>My Leads</span>
                    </Link>
                  </li>
                  </li>
                  <li className="" id="dashboradContainer">
                    <Link to="LinkedInMenu">
                      {" "}
                      <i className="fa-brands fa-linkedin"></i> <span>Linkedin</span>
                    </Link>
                    <PricingModal
                      ModalState={this.state.Pricing_toggle}
                      ModalStateCallback={() => this.PricingModal_Close()}
                    />
                  </li>
                  <li id="AppContainer" className="">
                    <Link to="comingsoon">
                      <i className="fa-brands fa-google"></i> <span>Google</span>
                    </Link>
                  </li>
                  <li id="FileManagerContainer" className="">
                  <Link to="comingsoon">
                      <i className="fa fa-facebook-square" aria-hidden="true"></i>{" "}
                      <span>Facebook</span>
                  </Link>
                  </li>
                  <li id="BlogContainer" className="">
                  <Link to="comingsoon">
                      <i className="fa fa-instagram" aria-hidden="true"></i> <span>Instagram</span>
                    </Link>
                  </li>
                  <li id="UIElementsContainer" className="">
                  <Link to="comingsoon">
                      <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                      <span>Twitter</span>
                    </Link>
                  </li>
                </ul>
              </Nav>
            </div>
            <div
              className={
                sideMenuTab[1]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="Chat"
            >
              <form>
                <div className="input-group m-b-20">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
              <ul className="right_chat list-unstyled">
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar4} alt="" />
                      <div className="media-body">
                        <span className="name">Chris Fox</span>
                        <span className="message">Designer, Blogger</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar5} alt="" />
                      <div className="media-body">
                        <span className="name">Joge Lucky</span>
                        <span className="message">Java Developer</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="offline">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar2} alt="" />
                      <div className="media-body">
                        <span className="name">Isabella</span>
                        <span className="message">CEO, Thememakker</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="offline">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar1} alt="" />
                      <div className="media-body">
                        <span className="name">Folisise Chosielie</span>
                        <span className="message">
                          Art director, Movie Cut
                        </span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar3} alt="" />
                      <div className="media-body">
                        <span className="name">Alexander</span>
                        <span className="message">Writter, Mag Editor</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div
              className={
                sideMenuTab[3]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="question"
            >
              <form>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
              <ul className="list-unstyled question">
                <li className="menu-heading">HOW-TO</li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    How to Create Campaign
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Boost Your Sales
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Website Analytics
                  </a>
                </li>
                <li className="menu-heading">ACCOUNT</li>
                <li>
                  <a
                    href="registration"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Cearet New Account
                  </a>
                </li>
                <li>
                  <a
                    href="forgotpassword"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Change Password?
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Privacy &amp; Policy
                  </a>
                </li>
                <li className="menu-heading">BILLING</li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Payment info
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Auto-Renewal
                  </a>
                </li>
                <li className="menu-button m-t-30">
                  <a
                    href="#!"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="icon-question"></i> Need Help?
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
  }
}

NavbarMenu.propTypes = {
  addClassactive: PropTypes.array.isRequired,
  addClassactiveChild: PropTypes.array.isRequired,
  addClassactiveChildApp: PropTypes.array.isRequired,
  addClassactiveChildFM: PropTypes.array.isRequired,
  addClassactiveChildBlog: PropTypes.array.isRequired,
  addClassactiveChildUI: PropTypes.array.isRequired,
  addClassactiveChildWidgets: PropTypes.array.isRequired,
  addClassactiveChildAuth: PropTypes.array.isRequired,
  addClassactiveChildPages: PropTypes.array.isRequired,
  addClassactiveChildForms: PropTypes.array.isRequired,
  addClassactiveChildTables: PropTypes.array.isRequired,
  addClassactiveChildChart: PropTypes.array.isRequired,
  addClassactiveChildMaps: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  generalSetting: PropTypes.array.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
  toggleEqualizer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ navigationReducer }) => {
  const {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  } = navigationReducer;
  return {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  };
};

export default connect(mapStateToProps, {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
})(NavbarMenu);
