import {
  CheckPass_Endpoint,
  Login_Endpint,
  SignUp_Endpoint,
  Update_Pass,
  BasicInfo_Endpoint,
  MultiProfiles_Endpoint,
  QueryMultiUserProfiles_Endpoint,
  SendingInvites_Endpoint,
  ValidateEmail_Endpoint,
  ResetPassword_Endpoint,
} from "./EndPoint";
import { EncryptVal, GetCurrDate, GetUserData } from "./GlobalFunctions";
export const SignUp_User = (Name, Email, Password, Date, Time, Img) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    user_fullName: Name,
    user_Image: Img,
    login_email: Email,
    login_password: Password,
    user_location: localStorage.getItem("UserLocation"),
    joining_date: Date,
    last_login_time: Time,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(SignUp_Endpoint, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return "Error";
    });
};

export const Login_User = (Email, Pasword, Time) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    LoginEmail: Email,
    LoginPassword: Pasword,
    UserLocation:
      localStorage.getItem("UserLocation") == null ||
      localStorage.getItem("UserLocation") == "{}" ||
      localStorage.getItem("UserLocation") == undefined
        ? EncryptVal("{}")
        : localStorage.getItem("UserLocation"),
    LoginTime: Time,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(Login_Endpint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      return null;
    });
};

export const Update_Password = (OldPass, NewPass) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    userEmail: EncryptVal(GetUserData().login_email),
    userpasswordOld: OldPass,
    userNewPassword: NewPass,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(Update_Pass, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      return "null";
    });
};

export const Check_Old_Pass = (OldPass) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    userPassword: OldPass,
    userEmail: EncryptVal(GetUserData().login_email),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(CheckPass_Endpoint, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};

export const UpdateUserInfo = (
  Fullname,
  Phone,
  Gender,
  Address,
  Dob,
  Website
) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    userFullName: Fullname,
    userEmail: EncryptVal(GetUserData().login_email),
    userPhone: Phone,
    userGender: Gender,
    userAddress: Address,
    userDob: Dob,
    userWebsite: Website,
    userImage: EncryptVal("nill"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(BasicInfo_Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      if (result.Status === "Updated") {
        localStorage.setItem("User_Data", result.UserObject);
      }

      return result.Status;
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};

export const MultiQuerySearch = (User_Query_array) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    User_Email:GetUserData().login_email,
    Current_Date:GetCurrDate(),
    SearchQueries: User_Query_array,
    LoginToken: EncryptVal(
      JSON.parse(localStorage.getItem("authentication_param")).value
    ),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(MultiProfiles_Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return null;
    });
};


export const QueryMultipleProfileData = async (profileLinksList) => {

  if (profileLinksList.length !== 0) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      User_Email:GetUserData().login_email,
    Current_Date:GetCurrDate(),
      SearchList: profileLinksList,
      Search_Token: JSON.parse(localStorage.getItem("authentication_param")).value,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(QueryMultiUserProfiles_Endpoint, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return null;
      });
  }
};

export const SendingInvites =(EntityArray,InvitationMessage)=>{

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "ConnectionUrns": EntityArray,
    "InvitationMessage": InvitationMessage,
    "LoginToken": EncryptVal(JSON.parse(localStorage.getItem("authentication_param")).value)
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  return fetch(SendingInvites_Endpoint, requestOptions)
    .then(response => response.text())
    .then(result =>{ console.log(result)
    return result
    })
    .catch(error => 
      {
        console.log('error', error)
      return null
      });

}
export const SendResetLink=(Email,Encryptmail,Timeout)=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    mode:'no-cors'
  };
  var MyPage=window.location.href
  MyPage= MyPage.substring(0, MyPage.lastIndexOf('/'));
  
   return fetch("https://flow.zoho.com/755607908/flow/webhook/incoming?zapikey=1001.2ae727a7a407afc0aeafcb71b92dcaea.b41e66bb66ef3424637ce97f2c7cea72&isdebug=false&Email="+Email+"&ResetLink="+MyPage+"/reset?email="+Encryptmail+"&time="+Timeout, requestOptions)
    .then(response => response.text())
    .then(result => {
    return "success"
    })
    .catch(error => {console.log('error', error)
  return "Error"
  });
}
export const ValidateEmail=(Email)=>{


  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "UserEmail": Email
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

 
return fetch(ValidateEmail_Endpoint, requestOptions)
  .then(response => response.text())
  .then(result => {console.log(result)
  return result
  })
  .catch(error => {console.log('error', error)
return null;
});
}

export const Reset_Password=(Email,Password)=>{
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "UserEmail": Email,
  "UserNewPass": Password
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(ResetPassword_Endpoint, requestOptions)
  .then(response => response.text())
  .then(result => {console.log(result)
  return result
  })
  .catch(error => {console.log('error', error)
return null
});
}