import React from "react";
import { connect } from "react-redux";
import image from "../../assets/images/defaultavatar.png";
import './customcss/Custom.css'
import { GetUserData, EncryptVal} from "../../Utils/GlobalFunctions";
import {Update_Password,Check_Old_Pass} from "../../Utils/ApiCalls"
import { toast } from "react-hot-toast"
 
class ProfileHeaderCard extends React.Component {
 constructor(props){
  super(props)
  this.state={
    User_Data:props.User_Obj,
    CurrPassword:"",
    NewPassword:"",
    ReNewPassword:"",
    oldPass:"",
    newPass:false,
    samePass:false
 }
 

  };
  componentDidMount(){
  
  }
  componentWillReceiveProps(NewProps){
    if(NewProps.User_Obj!==this.state.User_Data){
  
      this.setState({User_Data:NewProps.User_Obj})
    }
    }
  handlePasswrodUpdate=()=>{
   
if(this.state.NewPassword!==this.state.ReNewPassword){
  this.setState({samePass:false})
this.setState({newPass:true})
}
   else{
    if(this.state.NewPassword===this.state.CurrPassword){
      this.setState({samePass:true})
      this.setState({newPass:false})

    } else{
      this.setState({newPass:false})
      this.setState({samePass:false})
     var res_Update= Update_Password(EncryptVal(this.state.CurrPassword),EncryptVal(this.state.NewPassword))
     toast.promise(
      res_Update,
      {
        loading: 'Please Wait',
        success: (data) => {
          if(data.includes("Updated")){
            
            return "Password Successfully Updated";    
          }
       
          else{
            return 'Please Try Again' 
          }
       },
        error: (err) => `Something Went Wrong Please Try Again`,
      },
      {
        style: {
          minWidth: '250px',
        },
        success: {
          duration: 4000,
        },
      }
    );
     
    }
   

   } 
    

  }
  CurrPasswordCeck=()=>{
  var response= Check_Old_Pass(EncryptVal(this.state.CurrPassword))
  response.then((data)=>{
    if(data.includes("Valid")){
      this.setState({oldPass:"true"})      
      
      document.getElementById('newPass').disabled = false;
      document.getElementById('ReNewPass').disabled = false;
      document.getElementById('newPass').classList.remove("DisabledCls")
      document.getElementById('ReNewPass').classList.remove("DisabledCls")
    }
    else if(data.includes("Wrong_Pass")){
      this.setState({oldPass:"false"})
      document.getElementById('newPass').disabled = true;
      document.getElementById('ReNewPass').disabled = true;
      document.getElementById('newPass').classList.add("DisabledCls")
      document.getElementById('ReNewPass').classList.add("DisabledCls")
    }
    else{
      this.setState({oldPass:""})
    }
  }
  ).catch((er)=>{
alert("Something went wrong")
  })



    
  }
  render() {
    var CityCountry=this.state.User_Data.user_address
    CityCountry= CityCountry.substring(CityCountry.indexOf('|') + 1);
    return (<>
      <div className="card profile-header">
        <div className="body">
          <div className="profile-image">

{this.state.User_Data.user_image==="null"||this.state.User_Data.user_image===""||this.state.User_Data.user_image==="nill"?
            <img alt="" className="rounded-circle testimg" src={image} />
            :
          <img alt="" className="rounded-circle testimg" src={this.state.User_Data.user_image} />}
          </div>
          <div>
            <h4 className="m-b-0">
             {this.state.User_Data.user_fullName}
            </h4>
            <span>{CityCountry}</span>
          </div>
          
        </div>
      </div>
        {/* Change Pasword */}
        <div className="card">
        <div className="body">
          <div className="row clearfix">
            
            <div className="col-lg-12 col-md-12">
              <h6>Change Password</h6>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Current Password"
                  onChange={(e)=>{
                    this.setState({CurrPassword:e.target.value})
                  }}
                  onBlur={()=>this.CurrPasswordCeck()}
                />
              </div>
              {
                this.state.oldPass==="false"?
                <div className="text-danger mb-2">Current Password Is Incorrect</div>
              :
              <></>
              }
               {
                this.state.oldPass==="true"?
                <div className="text-success mb-2">Password Valid</div>
              :
              <></>
              }
              <div className="form-group">
                <input
                id="newPass"
                  type="password"
                  className="form-control DisabledCls"
                  disabled
                  placeholder="New Password"
                  onChange={(e)=>{
                    this.setState({NewPassword:e.target.value})
                  }}
                />
              </div>
              <div className="form-group">
                <input
                id="ReNewPass"
                  type="password"
                  className="form-control DisabledCls"
                  placeholder="Confirm New Password"
                  disabled
                  onChange={(e)=>{
                    this.setState({ReNewPassword:e.target.value})
                  }}
                />
              </div>
              {
                this.state.newPass===true?
                <div className="text-danger mb-2">New Password Doest Not Match</div>
              :
              <></>
              }
              {
                this.state.samePass===true?
                <div className="text-danger mb-2">Current Password And New Password Cannot Be Same</div>
              :
              <></>
              }
            </div>
          </div>
          <button type="button" className="btn btn-primary" onClick={()=>this.handlePasswrodUpdate()}>
            Update
          </button>{" "}
          &nbsp;&nbsp;
          {/* <button className="btn btn-default">Cancel</button> */}
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = ({ mailInboxReducer }) => ({});

export default connect(mapStateToProps, {})(ProfileHeaderCard);
