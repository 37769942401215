import React from "react";
import toast from "react-hot-toast";
import PricingModal from "../../../WebApp/Components/PricingModal";
import { GettingMultipleProfileData, UpdateCredits_Backend } from "../../../WebApp/Utils/ApiCalls";
import { Mini_Function_Cost } from "../../../WebApp/Utils/DummyData";
import { DownloadCSV, FilteExportData, Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import { QueryMultipleProfileData } from "../../Utils/ApiCalls";
import { GetUserData } from "../../Utils/GlobalFunctions";
import Api_Loader from "../API_Loader";
import '../Pages/customcss/Custom.css'
import { SaveLeads } from "../SaveLeads";
import InfoMessage from "./InfoMessage";

class ScrapeSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Proceed: false,
      isLoading: false,
      ExportData_Json: [],
      ExportData_CSV: [],
      BulkExport: JSON.parse(localStorage.getItem("BulkLinks")),
      fileName: "",
      LimitExceede: false,
      pricingModal:false,
      FilteredData:[],
      userEmail:GetUserData().login_email
    };
  }
  componentDidMount(){
  
  }
HandleProceed=async(e)=>{
  var ContinueBtn= document.getElementById("Continue_Btn");
  ContinueBtn.innerText = "Loading..";
  ContinueBtn.disabled = true;
  var response=await UpdateCredits_Backend(this.state.userEmail,this.state.BulkExport.length*Mini_Function_Cost,"down","dash")
  if(!response.includes("null")){
    this.HandelMultipleLinks();
  } 
  else{
    ContinueBtn.innerText = "Continue";
    ContinueBtn.disabled = false;
    toast.error("You Dont Have Enough Credits")
    this.setState({pricingModal:true})    
  }              
}
  HandelMultipleLinks = () => {
    this.setState({ Proceed: true,isLoading: true  });
       
    var response = QueryMultipleProfileData(this.state.BulkExport);
    response
      .then((data) => {
    
        if (data != null) {

          var Filtered_Data= FilteExportData(data)
         this.setState({FilteredData:Filtered_Data})
          this.setState({ ExportData_Json: data },()=>{console.log(this.state.ExportData_Json)});

          var arrayFinal = [];
          data.forEach((item) => {
            var flattened = {};
            Flatten(item, flattened, "");
            arrayFinal.push(flattened);
          });
          console.log(arrayFinal)
          this.setState({ ExportData_CSV: arrayFinal });
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        alert(error)
        this.setState({ isLoading: false });
      });
  };
  updatePricingModal=()=>{
    this.setState({pricingModal:false})
  }


  render() {
    return (
      <div className="Bulk_Export mt-4">
        < InfoMessage
       TitleLine="There's a limit to how much you can automate on LinkedIn"
       Description=" We recommend you process a maximum of 20 sLinks
       queries per scrape, or
        50  Links if you have a Sales Navigator
       account. If you bypass this by too much, you will have to update
       your session cookie more often."
       SubDesc=" You should always spread your actions across several launches
       spaced throughout the day, during office hours where possible."
       />
        {!this.state.Proceed ? (
          <div className="ContinueBtn container d-flex justify-content-start flex-column">
            <div className="panel-body text-light p-4 shadow rounded">
              <table className="table borderless text-light">
                <thead>
                  <tr>
                    <td className="text-left">
                      <strong>Links Found In File</strong>
                    </td>

                    <td className="text-right">
                      <strong>Credits Required To Scrape</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">
                      Total LinkedIn Accounts:{" "}
                      <strong> {this.state.BulkExport.length}</strong>
                    </td>

                    <td className="text-right">
                      Total:{" "}
                      <strong> {this.state.BulkExport.length * 5} Credits</strong>
                    </td>
                  </tr>
                  <tr><td colSpan={2}><input type={"text"}
                  onChange={(e)=>{
                   this.setState({fileName:e.target.value})
                  }}
                  placeholder="Enter File Name"
                  className="form-control"
                  ></input></td></tr>
                </tbody>
              </table>
            </div>
          <div className="mt-2">
            <button
              className="btn btn-primary hvr-grow"
              onClick={(e) => this.HandleProceed(e)}
              id="Continue_Btn"
            >
              Continue
            </button>
            <PricingModal ModalState={this.state.pricingModal} ModalStateCallback={()=>this.updatePricingModal()}/>
            </div>
          </div>
        ) : (
          <div className="ExitDiv d-flex justify-content-center">
            {this.state.isLoading ? (
              <Api_Loader/>
            ) : (
              <>
              <div className="text-center">
                <h4 className="mb-4">Select Your Action</h4>

                <DownloadCSV
                  fileName={this.state.fileName}
                  dataDetailFormatted={this.state.ExportData_CSV}
                  JsonData={this.state.ExportData_Json}
                  Deduct={false}
                />
     <SaveLeads LeadsData={this.state.FilteredData}/>
                </div>
              </>
            )}
          </div>
        )}

      
      </div>
    );
  }
}
export default ScrapeSummary;
