import React, { Component } from "react";
import "../Css_Files/SearchBar.css";
import { BasicDetails_Backend } from "../Utils/ApiCalls";



export class Searchable_Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IncomingList: this.props.data,
      SearchedList: null,
      SelectedValue_name: "",
      SelectedValue_code: 0,
    };
  }

  SelectFunc = (e) => {
    this.setState(
      {
        SelectedValue_name: e.target.innerText,
        SelectedValue_code: e.target.id,
      },
      () => {
        localStorage.setItem(
          this.props.dropDown_Type + "_Code",
          this.state.SelectedValue_code
        );
        console.log(
          this.state.SelectedValue_name + ":  " + this.state.SelectedValue_code
        );

        this.setState({ SearchedList: null }, () => {
          console.log(this.state.SearchedList);
          var searchWrapper = document.getElementById(this.props.wrapper);
          searchWrapper.classList.remove("active"); //hide autocomplete box
        });
      }
    );
  };

  ValueChanging = async (e) => {
    var searchWrapper = document.getElementById(this.props.wrapper);

    if (e.keyCode === 13) {
      let userQuery = e.target.value; //user enetered data
      let resultArray = [];
      if (userQuery) {
        searchWrapper.classList.add("active"); //show autocomplete box
        this.setState({ SearchedList: undefined }, () => {
          console.log(this.state.SearchedList);
        });

        //Start the timer
        //making api call
        var resultPromise = await BasicDetails_Backend(
          userQuery,
          this.props.dropDown_Type
        );
        console.log(resultPromise)
        this.setState({ SearchedList: resultPromise }, () => {
          console.log(this.state.SearchedList);
        });
      } else {
        searchWrapper.classList.remove("active"); //hide autocomplete box
      }
    } else {
      searchWrapper.classList.add("active"); //show autocomplete box
      this.setState({ SearchedList: null, SelectedValue_name: e.target.value });
      this.setState({ SearchedList: null }, () => {
        // console.log(this.state.SearchedList);
      });
    }
  };

  render() {
    return (
      <div className="wrapper">
        <div className="search-input" id={this.props.wrapper}>
          <a href="" target="_blank" hidden></a>
          <input
          autoComplete="off"
            id={this.props.inputBox}
            type="text"
            value={this.state.SelectedValue_name}
            placeholder={this.props.placeholder}
            onChange={(e) => {
              this.setState({ SelectedValue_name: e.target.value });
            }}
            onKeyUp={this.ValueChanging}
          />
          <div className="autocom-box" id={this.props.suggBox}>
            {
              this.state.SearchedList === null ? (
                <li>Press Enter to Continue</li> //if null or undefined
              ) : this.state.SearchedList === undefined ? (
                <li>Please Wait</li> //if null or undefined
              ) : this.state.SearchedList.length === 0 ? (
                <li>No records Found</li> //if empty array
              ) : (
                this.state.SearchedList.map((item, key) => {
                  if (item.Image !== "") {
                    return (
                      <li key={key} id={item.Code} onClick={this.SelectFunc}>
                        <img className="Result_Img" src={item.Image} />
                        &nbsp;{item.Name}
                      </li>
                    );
                  } else {
                    return (
                      <li key={key} id={item.Code} onClick={this.SelectFunc}>
                        {item.Name}
                      </li>
                    );
                  }
                })
              ) //real if found
            }
          </div>
          <div className="icon">
            <i className="fas fa-search"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Searchable_Dropdown;
