
import React from "react"
import './Pages/customcss/Custom.css'


const  Api_Loader=(props)=>{

return(

    <div> <div className="d-flex justify-content-center">
              <h4>Loading...</h4></div>
                <div className="d-flex justify-content-center">
                
                  <div className="Progress">
                    <div className="Progress-value"></div>
                  </div>
                </div>
              </div>
)


}
export default Api_Loader