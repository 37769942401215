import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { GetUserData } from "../../Utils/GlobalFunctions";

class ProfileInfoCard extends React.Component {
  constructor(props){
super(props)
this.state={
  User_Data:props.ProfileData
}

  }
  componentWillReceiveProps(NewProps){
    if(NewProps.ProfileData!==this.state.User_Data){
      this.setState({User_Data:NewProps.ProfileData})
    }
    }
  render() {if(this.state.User_Data.user_address!=="nill"){
    var streetaddress= this.state.User_Data.user_address; 
    streetaddress=  streetaddress.substring(0, streetaddress.indexOf('|'));
  }
  else{
    var streetaddress=this.state.User_Data.user_address
  }
    return (
      <div className="card">
        <div className="header">
          <h2>Info</h2>

          <Dropdown as="ul" className="header-dropdown">
            <Dropdown.Toggle variant="success" as="li" id="dropdown-basic">
              <Dropdown.Menu
                as="ul"
                className="dropdown-menu dropdown-menu-right"
              >
                <li>
                  <a>Action</a>
                </li>
                <li>
                  <a>Another Action</a>
                </li>
                <li>
                  <a>Something else</a>
                </li>
              </Dropdown.Menu>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
        <div className="body">
          <small className="text-muted">Address: </small>
          <p>{streetaddress}</p>
          <div></div>
          <hr />
          <small className="text-muted">Email address: </small>
          <p>{this.state.User_Data.login_email}</p>
          <hr />
          <small className="text-muted">Mobile: </small>
          <p>{this.state.User_Data.user_phone}</p>
          <hr />
          <small className="text-muted">Birth Date: </small>
          <p className="m-b-0">{this.state.User_Data.user_dob}</p>
          <hr />
          <small className="text-muted">Website: </small>
          <p>
            <i className="fa fa-globe m-r-5"></i> {this.state.User_Data.user_website}
          </p>
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ mailInboxReducer }) => ({});

export default connect(mapStateToProps, {})(ProfileInfoCard);
