import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader'
import { DecryptVal, generateUUID, GetUserData } from '../../Utils/GlobalFunctions'
import Success from '../../assets/images/success.png'
import failed from '../../assets/images/cancel.png'
import expired from '../../assets/images/complain.png'
import invalid from '../../assets/images/warning.png'
import { UpdateCredits_Backend, Verify_Transaction } from '../../../WebApp/Utils/ApiCalls'
import {PackageDetails,CreditsDetails} from '../../../WebApp/Utils/DummyData'
import toast from 'react-hot-toast'
export const Payments = () => {

const [Payment_Status, setPaymentStatus]=useState()

useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    var Status=""
   var ProductID=""
   console.log(encodeURI(params.LogID))
   console.log(decodeURI(encodeURI(params.LogID)))

    if(params.Status!==undefined){
        // var LogID=DecryptVal(params.LogID)
     Status= DecryptVal(params.Status)
     if(Status=="true"){
    var  response= Verify_Transaction(params.LogID, params.Mode, params.SubType, generateUUID(), "Verified")
       response.then((data)=>{
ProductID=data.ProdID
        setPaymentStatus(data.Status)
        var UserDetails=GetUserData()
        UserDetails.PurchasingDetails=data.PurchasingDetails
        localStorage.setItem("User_Data", JSON.stringify(UserDetails))
       var ProductObj={}
CreditsDetails.forEach((item)=>{
if(ProductID==item.PriceID){
ProductObj=item
}
})
PackageDetails.forEach((item)=>{
  if(ProductID==item.PriceID){
  ProductObj=item
}
})
{if(ProductObj.Credits!==undefined)
  UpdateCredits(ProductObj.Credits)
}

       })
     }
     else{
      var  response= Verify_Transaction(params.LogID, params.Mode, params.SubType, generateUUID(), "Failed")
       response.then((data)=>{
ProductID=data.ProdID
        setPaymentStatus(data.Status)
        var UserDetails=GetUserData()
        UserDetails.PurchasingDetails=data.PurchasingDetails
        localStorage.setItem("User_Data", JSON.stringify(UserDetails))
       
       })
     }
    }
     else{
        Status="false"
     }
   





    if(Status=="true"){
setPaymentStatus(true)
    }
    else{
        setPaymentStatus(false)
    }

}, [])

const UpdateCredits=(Credits)=>{

  var Response= UpdateCredits_Backend(GetUserData().login_email, Credits,"up","Dash")
  toast.promise(
   Response,
   {
     loading: 'Please Wait',
     success: (data) => {
       if(data){
           
           return 'Credits Updated'
       }
       else{
        return 'Not Enough Balance'
       }
    },
     error: (err) => `Something Went Wrong Please Try Again`,
   },
   {
     style: {
       minWidth: '250px',
       position: "top-center"
     },
     success: {
       duration: 3000,      
     },
   }
 );
 }

  return (
    <div className="p-3">
<PageHeader
      HeaderText="Payment Status"
      Breadcrumb={[
        { name: "Dasboard", navigate: "dashboard" },
        { name: "Payments", navigate: "" },
     ]}     
    />
    <h3>Payment Status</h3>
 {   Payment_Status=="Verified"?
<div className='d-flex justify-content-center'>
<div className='d-block text-center pt-5'>
<img src={Success} width={"200"} className="mb-2"></img>
<h2 className='mt-5'>Payment Successful</h2>
</div>

</div>:
Payment_Status=="Failed"?
<div className='d-flex justify-content-center'>
<div className='d-block text-center pt-5'>
<img src={failed} width={"200"} className="mb-2"></img>
<h2 className='mt-5'>Invalid Link</h2>
</div>

</div>:
Payment_Status=="Already Verified"?
<div className='d-flex justify-content-center'>
<div className='d-block text-center pt-5'>
<img src={expired} width={"200"} className="mb-2"></img>
<h2 className='mt-5'>Link Expired</h2>
</div>

</div>
:
Payment_Status=="Invalid Transaction"?
<div className='d-flex justify-content-center'>
<div className='d-block text-center pt-5'>
<img src={invalid} width={"200"} className="mb-2"></img>
<h2 className='mt-5'>Link Invalid</h2>
</div>

</div>
:
<></>}


    </div>
  )
}
