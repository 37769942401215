import "../Css_Files/ProfileModal.css";
import { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import No_Record from "./NoRecord";
import PlaceHolder from '../images/logo3.png'
import { Basic_Function_Cost, Mini_Function_Cost } from "../Utils/DummyData";
import toast, { Toaster } from "react-hot-toast";
import { UpdateCredits_Backend } from "../Utils/ApiCalls";
import PricingModal from "./PricingModal";
import React from "react"

export function Sideinfo(props) {
  const[ModalState, setModalState]=useState(false);
  const [ExpisOpen, setExpisOpen] = useState(false);
 
  const toggleExp = () => {
    setExpisOpen(!ExpisOpen);
  };
  const [userEmail]=useState(JSON.parse(localStorage.getItem("user_details")).username)

  return (

    <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
            {/* <Toaster/> */}
    <h4 className="SideHeading">{props.Heading}</h4>
      <div className={props.Heading+"Div"}>
        

        {
          (
            props.DataType==undefined||props.DataType==null||props.DataType==[]?
            <No_Record/>:
          props.DataType.map((item,key)=>(
            <div className="row Experience mb-2" key={key}>
            <div className="col-lg-2 col-md-4 col-sm-4 col-4 Exp_Img">

              {
                item.img==""?<img src={PlaceHolder}/>:
                <img src={item.img} />}
            </div>
            <div className="col-lg-10 col-md-8 col-sm-8 col-8 sideInfo">
              <p className="SideTitle">{item.title}</p>
              <p className="SideSubTitle">{item.subtitle}</p>
              <p className="SideDuration">{item.durration}</p>
              <p className="SideActivity">{item.activity}</p>

            </div>
          </div>
         ))
         )
        //   (props.firstItem==undefined||props.firstItem==null?
        //  <No_Record/>:
//           <div className="NoCreditsData">
            
//             <div className="row Experience">
//             <div className="col-lg-2 col-md-4 col-sm-4 col-4 Exp_Img">
//               {
//                props.firstItem.img==""?<img src={PlaceHolder}/>:
//                 <img src={props.firstItem.img} />}
//             </div>
//             <div className="col-lg-10 col-md-8 col-sm-8 col-8 sideInfo">
//               <p className="SideTitle">{props.firstItem.title}</p>
//               <p className="SideSubTitle">{props.firstItem.subtitle}</p>
//               <p className="SideDuration">{props.firstItem.durration}</p>
//               <p className="SideActivity">{props.firstItem.activity}</p>

//             </div>
//           </div>
                    
//         {/* {props.DataType.length>1?
//           <div className="DummynBuy">
//             <div className="row DummyExp">
//               <div className="col-lg-4 col-md-4 col-sm-6 col-6">
//                 {" "}
//                 <h6>Email </h6>
//                 <p>DummyUser@gmail.com</p>{" "}
//               </div>
//               <div className="col-lg-4 col-md-6 col-sm-6 col-6">
//                 <h6> Dummy Phone </h6>
//                 <p>+1-234-56789</p>{" "}
//               </div>

//               <div className="col-lg-4 col-md-6 col-sm-6 col-6">
//                 <h6> Dummy Skype</h6>
//                 <p>@username</p>{" "}
//               </div>
//             </div>
//             <div className="buyCredits">
//               <h5>Unlock {props.Heading} for 50 Credits</h5>
//               <button className="btn btn-success" 
//                onClick={() => {
//                 var response=UpdateCredits_Backend(userEmail,Mini_Function_Cost,"down","StandAlone")
//                 response.then((data)=>{
//                   if(!data.includes("null")){
//                     toggleExp();
//                     }
//                 else{
                  
//                   toast.error("You Dont Have Enough Credits")
//                 props.pricingModal()
                  
//                 }

//                 }).catch((e)=>{console.log(e)
//                   toast.error("Something went Wrong")
//                 })
//               }}
//               >
                
//                 Unlock Now
//               </button>
//             </div>
// </div>:
// <></>
// } */}

            
//           </div>
       //)
       }
      </div>
    </div>
  );



}

export default Sideinfo;
