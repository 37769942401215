import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import React from "react";
import PageHeader from "../PageHeader";
import GetToken from "../Linkedin/GetToken";
import LinksExtract from "./LinksExtract";
import "../Pages/customcss/Custom.css";
import InvitationMessage from "./InvitationMessage";
import InfoMessage from "./InfoMessage";
import SendIvitations from "./SendInvitations";
import { EncryptVal } from "../../Utils/GlobalFunctions";
import { Flatten } from "../../../WebApp/Utils/GlobalFunctions";
import { QueryMultipleProfileData } from "../../Utils/ApiCalls";
import BoostNetwork from "./SendAllInvites";






class NetworkBooster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      isDiabled0: false,
      isDiabled1: false,
      isDiabled2: true,
      Entityurn_Array:[],
    };
  }
  componentWillMount() {
    if (
      JSON.parse(localStorage.getItem("authentication_param")) !== null &&
      JSON.parse(localStorage.getItem("BulkLinks")) === null
    ) {
      this.setState({ tabIndex: 1 });
    }
    if (
      JSON.parse(localStorage.getItem("authentication_param")) !== null &&
      JSON.parse(localStorage.getItem("BulkLinks")) !== null
    ) {
      this.setState({ tabIndex: 2 });
    }
    if (JSON.parse(localStorage.getItem("authentication_param")) === null) {
      this.setState({ isDiabled0: true });
    }
    if (JSON.parse(localStorage.getItem("BulkLinks")) === null) {
      this.setState({ isDiabled1: true ,isDiabled2: true });
    }
  }
  

  SetTabValue = () => {
    this.setState({ isDiabled0: false });
  };
  SetTabIndex = () => {
    this.setState({ tabIndex: 1 });
  };
  SetTabIndex2 = () => {
    this.setState({ tabIndex: 2 });
  };
  SetTabValue2 = () => {
    this.setState({ isDiabled1: false });
  };
  SetTabIndex3 = () => {
    this.setState({ tabIndex: 3 });
  };
  SetTabValue3 = () => {
    this.setState({ isDiabled2: false });
  };


  render() {
    return (
      <>
        <div className="text-left p-3">
          <PageHeader
            HeaderText="Linkedin Network Booster"
            Breadcrumb={[
              { name: "Linkedin", navigate: "LinkedInMenu" },
              { name: "Network booster", navigate: "" },
            ]}
          />
        </div>
    
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList>
            <Tab>Connect Linkedin Account</Tab>
            <Tab disabled={this.state.isDiabled0}>Profiles To Invite</Tab>
            <Tab disabled={this.state.isDiabled1}> Invitation Message </Tab>
            <Tab disabled={this.state.isDiabled2}> Result</Tab>
          </TabList>

          <TabPanel>
            <GetToken
              setTab={() => this.SetTabIndex()}
              seValue={() => this.SetTabValue()}
            />
          </TabPanel>
          <TabPanel>
            <LinksExtract
              setTab={() => this.SetTabIndex2()}
              seValue={() => this.SetTabValue2()}
              Type="Connect"
              InfoMessage={true}
            />
          </TabPanel>
          <TabPanel>
            <InvitationMessage
               setTab={()=>this.SetTabIndex3()}
               seValue={()=>this.SetTabValue3()}
            
            />
          </TabPanel>
          <TabPanel>   <BoostNetwork
             
              UserMsg={EncryptVal(localStorage.getItem("Invite_Message"))}
             
              /></TabPanel>
        </Tabs>
      </>
    );
  }
}
export default NetworkBooster;
