import React, { useEffect, useState } from "react";
import LinkedIn from "../../assets/images/linkedin.svg";
import { Link } from "react-router-dom";
import "../Pages/customcss/Custom.css";
import { GetUserData } from "../../Utils/GlobalFunctions";
import PricingModal from "../../../WebApp/Components/PricingModal";
const PhantomCard = (props) => {
 const RemoveStorageItems=()=>{
    localStorage.removeItem("BulkLinks")
    localStorage.removeItem("User_Query")
    localStorage.removeItem("Companies_List")
  }

  const [KeyLimit, setKeyLimit]=useState()
  const [Disabled, setDisabled]=useState("")
  const[Pricing_toggle, setPricing_Toggle]=useState(false)
  useEffect(() => {
    
  var UserSub=GetUserData().PurchasingDetails.Subscription.user_subscription
var Key_Limit;


switch (UserSub) {
  case 'Free':
    setKeyLimit(1)
    Key_Limit=1
    break;
    case 'Basic':
    setKeyLimit(2)
    Key_Limit=2
    break;
    case 'Standard':
      setKeyLimit(4)
      Key_Limit=4
    break;
    case 'Enterprise':
      setKeyLimit(8)
      Key_Limit=8
    break;

  default:
    break;
}
    if(Key_Limit <= props.Key){
      setDisabled("Disbaled_Module")
    }
    else{
      setDisabled("")
    }
  }, [])
  
  return (<>
    <div className={"col-lg-3 col-md-6 col-sm-12 col-12  "+Disabled}>
      <div className="container mt-4 mb-4  d-flex justify-content-center">
        <div className="card p-4 m-0 bg-white" key={props.Key}>
          <div className="d-flex flex-column justify-content-start">
            <img src={LinkedIn} height="40" width="40" />
             <div className="fixedHeight mt-2">
             <span className="name mt-3 text-dark" id="Result_Name">
              {props.Title}
            </span>
             </div>
           
            <div className="mt-3 mb-3 text-dark fixedHeight">
              <span>
                <p>{props.Desc}</p>
              </span>
            </div>
            <div className="w-100">
           { 
           props.Key < KeyLimit ?

             <Link to={props.Link}>
                <button 
                onClick={()=>RemoveStorageItems()}
                className="btn btn-primary hvr-grow align-left">
                  Use This Tool
                </button>
              </Link>  
              :
              <button 
              onClick={()=>setPricing_Toggle(true)}
              className="btn btn-primary hvr-grow align-left">
              
             Upgrade Plan
              </button>
               }       
            </div>
          </div>
        </div>
      </div>
    </div>
    {   <PricingModal
                      ModalState={Pricing_toggle}
                      ModalStateCallback={() => setPricing_Toggle(false)}
                    />}
    </>
  );
};
export default PhantomCard;
