import React from "react"

export default function No_Record(){
    return(

<div className="No Record">

    <h5>No Record</h5>
</div>

    )
}