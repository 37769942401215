import React from "react"

import {
  AddingLeadsEndpoint,
  Authenticate_Endpoint,
  BasicDetails_Endpoint,
  cancelSubEndpoint,
  changeSubEndpoint,
  FetchingLeadsEndpoint,
  QueryMultipleProfiles,
  QuerySearch_Endpoint,
  QuerySpecificProfile,
  StripeEndpoint,
  TransactionEndpoint,
  UpdateCreds_Endpoint,
} from "./ApiEndpoints";
import { EncryptVal } from "./Encryption";
import { GetCurrDate, GetUserEmail } from "./GlobalFunctions";
import { GetUserData } from "../../Dashboard/Utils/GlobalFunctions";
var GettingAuthToken = () => {
  localStorage.setItem("MultipleProfile", "undefined");
  var AuthenticationToken_Object = JSON.parse(
    localStorage.getItem("authentication_param")
  );
  var Token_Authentication = AuthenticationToken_Object.value;
  return Token_Authentication;
};

export const Authenticate_User = async (UserName, UserPassword) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var UserName_encrypt = EncryptVal(UserName);
  var UserPassword_encrypt = EncryptVal(UserPassword);
  var raw = JSON.stringify({
    UserName: UserName_encrypt,
    UserPassword: UserPassword_encrypt,
    systemType:EncryptVal("Dashboard"),
    dashDetail:EncryptVal(GetUserData().login_email)
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(Authenticate_Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result.Token);
      console.log(result);
      localStorage.setItem("UserCredits", result.Credits);
      return result.Token;
 })
    .catch((error) => {
      console.log("error", error);
      return null;
    });
};

export const BasicDetails_Backend = async (Query, type) => {
  var Token_Authentication = GettingAuthToken();

  console.log(Query + " " + type + " " + Token_Authentication);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    SearchQuery: Query,
    DataType: type,
    Search_Token: Token_Authentication,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(BasicDetails_Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const QuerySearch_Backend = async (
  SearchTerm,
  SearchNumber,
  SearchType,
  SearchIndustry,
  SearchLocation,
  SearchCompany,
  SearchDefination
) => {
  var Token_Authentication = GettingAuthToken();

  console.log(SearchTerm + " " + SearchLocation + " " + Token_Authentication);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    User_Email: GetUserEmail(),
    Current_Date: GetCurrDate(),
    Search_Details: {
      Search_Query: SearchTerm,
      Search_Number: SearchNumber,
      Search_Type: SearchType,
      Search_Industry: SearchIndustry,
      Search_Location: SearchLocation,
      Search_Company: SearchCompany,
      Search_Defination: SearchDefination,
      Search_Token: Token_Authentication,
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(QuerySearch_Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return [];
    });
};

export const GettingProfileInfo = async (profileLink) => {
  localStorage.setItem("SingleProfileData", "undefined");

  var Token_Authentication = GettingAuthToken();

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    Profile_Link: profileLink,
    Search_Token: Token_Authentication,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(QuerySpecificProfile, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return null;
    });
};

export const GettingMultipleProfileData = async (profileLinksList) => {
  var Token_Authentication_ = GettingAuthToken();
  if (profileLinksList.length !== 0) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchList: profileLinksList,
      Search_Token: Token_Authentication_,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(QueryMultipleProfiles, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return null;
      });
  }
};

export const UpdateCredits_Backend = async (UserEmail,credits_count,type,sysType) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var CurrDate = GetCurrDate();
  var raw = JSON.stringify({
    UserEmail: UserEmail,
    Credit_Count: credits_count,
    updateType: type,
    updatingDate: CurrDate,
    sysType:sysType


  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(UpdateCreds_Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      if (result!==null) {
        if(result.CredsCount==="null"){}
        else{
          localStorage.setItem("Creds_Detail",JSON.stringify(result))
          localStorage.setItem("UserCredits", result.CredsCount);          
        }
      if(result.SearchStat!==[]&&result.TransStat!==[])
      {
        localStorage.setItem("SearchStats", JSON.stringify(result.SearchStat));
      localStorage.setItem("TransStat", JSON.stringify(result.TransStat));
    }
        
      }
      return result.CredsCount;
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};

export const Stripe_Checkout = async (URL, PriceID,mode,Sub) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
   Domain:  URL,
   Price_ID:PriceID,
   UserEmail:GetUserData().login_email,
   Mode:mode,
   SubName:Sub
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(StripeEndpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
      console.log(result);
    return result
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};
export const Change_Plan = async (URL, PriceID,mode,Sub) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
   Domain:  URL,
   Price_ID:PriceID,
   UserEmail:GetUserData().login_email,
   Mode:mode,
   SubName:Sub
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(changeSubEndpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
      console.log(result);
    return result
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};
export const Verify_Transaction = async (TransID,Type,SubType,UniqueID, TransStat) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    Trans_Id:TransID,
    Mode:Type,
    subType:SubType,
    UserEmail:GetUserData().login_email,
    Trans_GUID:UniqueID,
    Trans_Stat:TransStat
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(TransactionEndpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
      console.log(result);
    return result
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};
export const Cancel_Subscription= async (SubID,TransID) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    subID:SubID,
    TransID:TransID,
    UserEmail:GetUserData().login_email,
    
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(cancelSubEndpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
      console.log(result);
    return result
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};

export const FetchingLeads= async () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({

    "useremail":GetUserData().login_email
});

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(FetchingLeadsEndpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
      console.log(result);
    return result
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};

export const Saving_Leads= async (LeadData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(LeadData);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(AddingLeadsEndpoint, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      
      console.log(result);
    return result
    })
    .catch((error) => {
      console.log("error", error);
      return "null";
    });
};

