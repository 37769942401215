import React from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/ourlogo_White.png";
import { Link } from "react-router-dom";
import { EncryptVal } from "../../Utils/GlobalFunctions";
import { SendResetLink, ValidateEmail } from "../../Utils/ApiCalls";
import toast,{Toaster} from "react-hot-toast";
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
      EmailNotExist: false,
      ResetEmail: "",
      EmptyEmail: false,
      EmailSent: false,
      Link_Timeout: "",
      EncryptedEmail: "",
    };
  }

  HandleResetClick = () => {
    if (this.state.ResetEmail === "" || !this.state.ResetEmail.includes("@")) {
      this.setState({ EmptyEmail: true });
    } else {
      this.setState({ EmptyEmail: false });
      var EmailResponse=ValidateEmail(EncryptVal(this.state.ResetEmail))
      EmailResponse.then((data)=>{
        
        if (data.includes("false")) {
          this.setState({ EmailNotExist: true });
        } else {
          this.setState({ EmailNotExist: false });
          this.setState({ EncryptedEmail: EncryptVal(this.state.ResetEmail) });
  
          var Curr_Time = new Date();
          Curr_Time = Curr_Time.getTime();
          var addMlSeconds = 3 * 60 * 60 * 1000;
          var Link_Time = new Date(Curr_Time + addMlSeconds);
          this.setState({ Link_Timeout: Link_Time });
          var Response = SendResetLink(
            this.state.ResetEmail,
            EncryptVal(this.state.ResetEmail),
            Link_Time
          );
          toast.promise(
            Response,
            {
              loading: "Please Wait",
              success: (data) => {
                if (data == "success") {
                  this.setState({ EmailSent: true });
                  return "Reset Link Sent";
                }
              },
              error: (err) => {
                this.setState({ EmailSent: false });
                return `Something Went Wrong Please Try Again`;
              },
            },
            {
              style: {
                minWidth: "250px",
              },
              success: {
                duration: 15000,
              },
            }
          );
        }

      })
      
    }
  };

  render() {
    return (
      <div className="theme-cyan">
        <Toaster/>
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Recover my password</p>
                  </div>
                  <div className="body">
                    <p>
                      Please enter your email address below to receive
                      instructions for resetting password.
                    </p>
                    <div className="form-auth-small ng-untouched ng-pristine ng-valid">
                      <div className="form-group">
                        {this.state.EmailSent ? (
                          <p className="text-success">
                            Reset link sent to your email.
                          </p>
                        ) : (
                          <></>
                        )}
                        <input
                          className="form-control"
                          placeholder="Enter email here.."
                          type="email"
                          onChange={(e) =>
                            this.setState({ ResetEmail: e.target.value })
                          }
                        />
                        {this.state.EmptyEmail ? (
                          <p className="text-danger">
                            Please enter a valid email
                          </p>
                        ) :
                        
                        this.state.EmailNotExist?
                        <p className="text-danger">This email is not registered</p>
                        :
                        (
                          <></>
                        )}
                      </div>

                      <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={(ee) => this.HandleResetClick()}
                      >
                        RESET PASSWORD
                      </button>
                      <div className="bottom">
                        <span className="helper-text">
                          Know your password? <Link to={"/"}> Login</Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {};

const mapStateToProps = ({ loginReducer }) => ({});

export default connect(mapStateToProps, {})(ForgotPassword);
