import React from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";
import "echarts-gl";
import echarts from "echarts";
import LogoiCON from "../../assets/images/logo-icon.svg";

import { Dropdown } from "react-bootstrap";
import ReferralsCard from "../../components/Dashboard/ReferralsCard";

import PageHeader from "../../components/PageHeader";
import {
  topProductOption,
  topRevenueOption,
  topRevenueMonthlyOption,
  saleGaugeOption,
  dataManagetOption,
  sparkleCardData,
} from "../../Data/DashbordData";

import {
  toggleMenuArrow,
  onPressTopProductDropDown,
  loadSparcleCard,
  onPressReferralsDropDown,
  onPressRecentChatDropDown,
  onPressDataManagedDropDown,
  facebookProgressBar,
  twitterProgressBar,
  affiliatesProgressBar,
  searchProgressBar,
} from "../../actions";
import SparkleCard from "../../components/SparkleCard";
import {
  GetLastByType,
  GetLastWeekTrans,  
  GroupByWeekly,
} from "../../Utils/GlobalFunctions";
import Donutchart from "../../components/Dashboard/DonutChart";

var timer = null;
class Dashbord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],

    };
  }
  componentDidMount() {
    // this.chartPlace();
    this.GeneratCardData()
   
   
  }


GeneratCardData=()=>{
  var ResponseData = GetLastByType();
  var Response_ = GroupByWeekly(ResponseData, "up");
  console.log(Response_);
  var ResponseData_ = GetLastByType();
  var Response__ = GroupByWeekly(ResponseData_, "down");
  console.log(Response__);
  window.scrollTo(0, 0);

  var cardDataNew=[]

  var TotalTransactionObj = {
    heading: "Total Transactions",
    money: GetLastWeekTrans().reduce((a, b) => a + b, 0),
    perText: "Credit Transactions in last week",
    sparklineData: {
      type: "line",
      data: GetLastWeekTrans(),
      areaStyle: {
        color: "#fac091",
      },
      itemStyle: {
        color: "#f79647",
      },
      symbolSize: 1,
    },
  };
var UpTransactionObj=
{
heading: "Credits Bought",
money: Response_.reduce((a, b) => a + b, 0),
perText: "Credits bought in the last week",
sparklineData: {
  type: "line",
  data: Response_,
  areaStyle: {
    color: "#a092b0",
  },
  itemStyle: {
    color: "#604a7b",
  },
  symbolSize: 1,
},
}

var DownTransactionObj=
{
heading: "Credits Spent",
money: Response__.reduce((a, b) => a + b, 0),
perText: "Credits Spent In The Last Week",
sparklineData: {
  type: "line",
  data: Response__,
  areaStyle: {
    color: "#92cddc",
  },
  itemStyle: {
    color: "#4aacc5",
  },
  symbolSize: 1,
},
}
cardDataNew.push(TotalTransactionObj)
cardDataNew.push(UpTransactionObj)
cardDataNew.push(DownTransactionObj)
  this.setState({
    cardData: cardDataNew,
  });

}


  // chartPlace = () => {
  //   var chartDom = document.getElementById("topsaleDonut");
  //   var myChart = echarts.init(chartDom);
  //   var option;
  //   option = saleGaugeOption;

  //   option && myChart.setOption(option);
  // };

  render() {
    const { loadingPage } = this.props;
    const { cardData } = this.state;
    if (loadingPage) {
      return (
        <div className="page-loader-wrapper">
          <div className="loader">
            <div className="m-t-30">
              <img src={LogoiCON} width="48" height="48" alt="Lucid" />
            </div>
            <p>Please wait...</p>
          </div>
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText="Dashboard"
              Breadcrumb={[{ name: "Dashboard" }]}
            />
            <div className="row clearfix">
              {cardData.map((data, i) => (
                <SparkleCard
                  index={i}
                  key={data.heading}
                  Heading={data.heading}
                  Money={data.money}
                  PerText={data.perText}
                  isRandomUpdate={false}
                  // Data={data.sparklineData}
                  mainData={data.sparklineData.data}
                  chartColor={data.sparklineData.areaStyle.color}
                  ContainerClass="col-lg-3 col-md-6 col-sm-6"
                />
              ))}
            </div>

            <div className="row clearfix">
              <div className="col-lg-9 col-md-12">
                <div className="card">
                  <div className="header">
                    <h2>Top Searches<small>(Monthly)</small></h2>
                    <Dropdown as="ul" className="header-dropdown">
                      <Dropdown.Toggle
                        variant="success"
                        as="li"
                        id="dropdown-basic"
                      >
                        <Dropdown.Menu
                          as="ul"
                          className="dropdown-menu dropdown-menu-right"
                        >
                          <li>
                            <a>Action</a>
                          </li>
                          <li>
                            <a>Another Action</a>
                          </li>
                          <li>
                            <a>Something else</a>
                          </li>
                        </Dropdown.Menu>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                  <div className="body">
                    <ReactEcharts
                      option={topProductOption}
                      opts={{ renderer: "svg" }} // use svg to render the chart.
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <ReferralsCard />
              </div> */}
              <div className="col-lg-3 col-md-6">
                <div className="card pt-4 p-2 pb-0">
                <Donutchart/>
               
                </div>
              </div>
            </div>
       
           
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  loginReducer,
  navigationReducer,
  analyticalReducer,
}) => ({
  email: loginReducer.email,
  menuArrowToggle: navigationReducer.menuArrowToggle,
  sparkleCardData: analyticalReducer.sparkleCardData,
  topProductDropDown: analyticalReducer.topProductDropDown,
  referralsDropDown: analyticalReducer.referralsDropDown,
  recentChatDropDown: analyticalReducer.recentChatDropDown,
  facebookShowProgressBar: analyticalReducer.facebookShowProgressBar,
  twitterShowProgressBar: analyticalReducer.twitterShowProgressBar,
  affiliatesShowProgressBar: analyticalReducer.affiliatesShowProgressBar,
  searchShowProgressBar: analyticalReducer.searchShowProgressBar,
  loadingPage: analyticalReducer.loadingPage,
});

export default connect(mapStateToProps, {
  toggleMenuArrow,
  loadSparcleCard,
  onPressTopProductDropDown,
  onPressReferralsDropDown,
  onPressRecentChatDropDown,
  onPressDataManagedDropDown,
  facebookProgressBar,
  twitterProgressBar,
  affiliatesProgressBar,
  searchProgressBar,
})(Dashbord);
