import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import React from 'react';
import PageHeader from '../PageHeader';
import GetToken from '../Linkedin/GetToken';
import '../Pages/customcss/Custom.css'
import QueryScrapeSummary from './QueryScrapeSummary';
import EmployeeScrapper from './EmployeeScrapper';
class EmployeeScrape extends React.Component{

constructor(props){
    super(props)
    this.state={

tabIndex:0,
isDiabled0:false,
isDiabled1:false,
isDiabled2:false
    }
}
componentWillMount(){
   if(JSON.parse(localStorage.getItem("authentication_param")) !==null && JSON.parse(localStorage.getItem("Companies_List"))===null){
    this.setState({tabIndex:1})    
   }
   if(JSON.parse(localStorage.getItem("authentication_param")) !==null && JSON.parse(localStorage.getItem("Companies_List"))!==null){
    this.setState({tabIndex:2})    
   }
   if(JSON.parse(localStorage.getItem("authentication_param")) ===null){
 
   this.setState({isDiabled0:true}) 
   }
   if(JSON.parse(localStorage.getItem("Companies_List")) ===null){
    this.setState({isDiabled1:true}) 
   }

}
componentDidMount(){

}
SetTabValue=()=>{
  this.setState({isDiabled0:false}) 
}
SetTabIndex=()=>{
    this.setState({tabIndex:1})
}
SetTabIndex2=()=>{
  this.setState({tabIndex:2})
}
SetTabValue2=()=>{
  this.setState({isDiabled1:false}) 
}

render(){

    return(
<>
<div className="text-left p-3">
    <PageHeader
          HeaderText="Linkedin Employees Profiles Scrapper"
          Breadcrumb={[
            { name: "Linkedin", navigate: "LinkedInMenu" },
            { name: "Exployees Profile Scrapper", navigate: "" },
          ]}
        />
    </div>
<Tabs     selectedIndex={this.state.tabIndex} onSelect={(tabIndex) =>  this.setState({ tabIndex })}
 >
    <TabList>
      <Tab  >Connect Linkedin Account</Tab>
      <Tab disabled={this.state.isDiabled0}>Company Name Or List</Tab>
      <Tab disabled={this.state.isDiabled1}> Results </Tab>
    
    </TabList>

    <TabPanel>
      <GetToken
         setTab={()=>this.SetTabIndex()}
         seValue={()=>this.SetTabValue()}
         />
    </TabPanel>
    <TabPanel>
     <EmployeeScrapper
  setTab={()=>this.SetTabIndex2()}
  seValue={()=>this.SetTabValue2()}
     />
    </TabPanel>
    <TabPanel>
    <QueryScrapeSummary
     UserQuery={JSON.parse(localStorage.getItem("Companies_List"))}
     OueryType="Companies"
     Credits={40}
     />
    </TabPanel>
    
  </Tabs>
  </>
    )
};

}
export default EmployeeScrape;