import React from "react";
import "../Css_Files/SearchBar.css";
import { scroller } from "react-scroll";

// import { QuerySearch_Backend } from "../Utils/ApiCalls";
import { CompaniesData, CountriesData, IndustryData } from "../Utils/DummyData";
import { GettingRecords } from "../Utils/GlobalFunctions";
import SearchableDropdown from "./SearchableDropdown";
import mainvector from "../images/mainvector.png";
import toast from "react-hot-toast";

export class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowFilters: false,
      Countries_Data: CountriesData,
      Industry_Data: IndustryData,
      Company_Data: CompaniesData,
      Search_Type:"Location",
      Entity_Filter: "",
      Defination_Filter: "",
      EntityList: {
        Entities: [
          { id: "PEOPLE", Title: "People" },
          { id: "COMPANIES", Title: "Organization" },
        ],
      },
      SearchTypes: {
        SearchType: [
          { id: "Location", Title: "Location" },
          { id: "Industry", Title: "Industry" },
          { id: "Company", Title: "Company" },
        ],
      },
      DefinationList: {
        Defination: [
          { value: "By_Name", Title: "By Name" },
          { value: "By_Company", Title: "By Company" },
          { value: "By_Domain", Title: "By Domain" },
        ],
      },
    };
  }

  scrollToSection = () => {
    scroller.scrollTo("noRecords", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  Search_Change =(e) =>{


this.setState({Search_Type:e.target.value})


  }

  SubmitBasic = () => {
    localStorage.setItem("SelectedDataArray","[]")
localStorage.setItem("SelectedDataArray_Link","[]")
localStorage.setItem("Search_Response", "[]");
        var UserInput= document.getElementById('UserInput')
if(UserInput.value===""){
  UserInput.classList.add("emptyInput")
}
else{
    var response = GettingRecords(false);
    UserInput.classList.remove("emptyInput")
    toast.promise(
      response,
      {
        loading: "Please Wait Searching..",
        success: (data) => {
          if (data === true) {
            this.scrollToSection();

            return "Records Loaded";
          } else {
            this.scrollToSection();

            return "No Records Found";
          }
        },
        error: (err) => "Something Went Wrong Please Try Again",
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          color: "green",
          duration: 4000,
        },
      }
    );
  }
  };

  clearFilters = () => {
    localStorage.removeItem("Country_Code");
    localStorage.removeItem("Industry_Code");
    localStorage.removeItem("Entity");
    localStorage.removeItem("Company_Code");
    localStorage.removeItem("Defination");

    toast('Filters Cleared', {
      duration: 4000,
      position: 'top-center',
      // Styling
      style: {},
      className: '',
      // Custom Icon
      icon: '👏',
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });

  };
  ShowHideFilters = () => {
    this.setState({ ShowFilters: !this.state.ShowFilters });
  };
  render() {
    return (
      <div>
        <div className="MainContainer">
          <div className="titletext ">
            <img src={mainvector} alt="Vector" className="searchVector" />
            <h3>LinkedIn Lead Finder</h3>
          </div>
          <section className="search-sec ">
            {/* clear filter button start*/}
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
              {this.state.ShowFilters ? (
                <div clasName="clrFilterDiv">
                  
                <button className="btn clrFilter" onClick={this.clearFilters} id="clear_Filters">
                <i className="fa-solid fa-filter-circle-xmark clrFilter mr-1"></i>
                  Clear Filters
                </button>                
                </div>
                )
                :
                <div className="mb-5"></div>
                }
              </div>
            </div>
            {/* clear filter button end*/}
            <div className="container">
              <form action="#">
                <div className="row Row1">
                  <div className="col-lg- 12 col-md-12 col-sm-12">
                    <div className="row Row2">
                      {/* Query Search Input */}
                      <div className="col-lg-5 col-md-5 col-sm-12 p-2 search-input">
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control search-fld white_bg"
                          placeholder="Search Term..."
                          id="UserInput"
                          onChange={(e) => {
                            localStorage.setItem("UserQuery", e.target.value);
                          }}
                        />
                      </div>
                      {/* Main Location Search */}
                    {this.state.Search_Type=="Location"?
                      <div className="col-lg-5 col-md-5 col-sm-12 p-2">
                      <SearchableDropdown
                        wrapper="search_Input_1"
                        inputBox="search_Input_tag_1"
                        suggBox="sugg_Box_1"
                        dropDown_Type="Country"
                        data={this.state.Countries_Data}
                        placeholder="Enter Location..."
                      />
                    </div>:<></>}
                      {/* Industry Search */}

                   { this.state.Search_Type=="Industry"?
                      <div className="col-lg-5 col-md-5 col-sm-12 p-2">
                            <SearchableDropdown
                              wrapper="search_Input_2"
                              inputBox="search_Input_tag_2"
                              suggBox="sugg_Box_2"
                              dropDown_Type="Industry"
                              data={this.state.Industry_Data}
                              placeholder="Search Industry"
                            />
                          </div>:<></>}
                       {/* Companies Search */}

                          {
                          this.state.Search_Type=="Company"?
                          <div className="col-lg-5 col-md-5 col-sm-12 p-2">
                            <SearchableDropdown
                              wrapper="search_Input_3"
                              inputBox="search_Input_tag_3"
                              suggBox="sugg_Box_3"
                              dropDown_Type="Company"
                              data={this.state.Company_Data}
                              placeholder="Search Company"
                            />
                          </div>:<></>}

{/* Search Type DropDown */}
<div className="col-lg-2 col-md-2 col-sm-12 p-2 ">

<select
                              className="form-control filter-slct search-input"
                              id="Search_Type"
                              onChange={(e) => {
                                this.Search_Change(e)
                              }}
                            >
                              <option
                              selected
                              disabled
                                value=""                               
                              >
                                Search Type
                              </option>
                              {this.state.SearchTypes &&
                                this.state.SearchTypes.SearchType.map((h, i) => (
                                  <option key={i} value={h.id}>
                                    {h.Title}
                                  </option>
                                ))}
                            </select>
</div>

                    </div>
                    {
                      /* Filters */
                      this.state.ShowFilters ? (
                        <div className="row Row3">
                     

                          {/* Industry Search */}

                         { 
                         this.state.Search_Type!="Industry"?
                         <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                            <SearchableDropdown
                              wrapper="search_Input_2"
                              inputBox="search_Input_tag_2"
                              suggBox="sugg_Box_2"
                              dropDown_Type="Industry"
                              data={this.state.Industry_Data}
                              placeholder="Search Industry"
                            />
                          </div>
                        :<></>  
                        }
                          {this.state.Search_Type!="Location"?
                      <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                      <SearchableDropdown
                        wrapper="search_Input_1"
                        inputBox="search_Input_tag_1"
                        suggBox="sugg_Box_1"
                        dropDown_Type="Country"
                        data={this.state.Countries_Data}
                        placeholder="Enter Location..."
                      />
                    </div>:<></>}

                          {/* Companies Search */}

                         {this.state.Search_Type!="Company"?
                         <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                            <SearchableDropdown
                              wrapper="search_Input_3"
                              inputBox="search_Input_tag_3"
                              suggBox="sugg_Box_3"
                              dropDown_Type="Company"
                              data={this.state.Company_Data}
                              placeholder="Search Company"
                            />
                          </div>:<></>}

                          {/* Entity Select */}

                          <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                            <select
                              className="form-control filter-slct"
                              id="Entity_Select"
                              onChange={(e) => {
                                localStorage.setItem("Entity", e.target.value);
                              }}
                            >
                              <option
                                value=""
                                defaultValue={"Select Entity Type"}
                              >
                                Select Entity Type
                              </option>
                              {this.state.EntityList &&
                                this.state.EntityList.Entities.map((h, i) => (
                                  <option key={i} value={h.id}>
                                    {h.Title}
                                  </option>
                                ))}
                            </select>
                          </div>

                          {/* Domain Select */}
                          <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                            <select
                              className="form-control filter-slct"
                              id="Defination_Select"
                              onChange={(e) => {
                                localStorage.setItem(
                                  "Defination",
                                  e.target.value
                                );
                              }}
                            >
                              <option
                                value=""
                                defaultValue={"Select Defination Type"}
                              >
                                Select Defination Type
                              </option>
                              {this.state.DefinationList &&
                                this.state.DefinationList.Defination.map(
                                  (h, i) => (
                                    <option key={i} value={h.value}>
                                      {h.Title}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                    }
                    {/* Search  & Filter Buttons */}
                    <div className="filterToggle">
                      {!this.state.ShowFilters ? (
                        <div>
                          <p className="filterTitle">Advanced Search</p>
                          <i
                            className="fa-solid fa-angle-down filterIcon"
                            onClick={this.ShowHideFilters}
                          ></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className="fa-solid fa-angle-up filterIcon"
                            onClick={this.ShowHideFilters}
                          ></i>
                          <p className="filterTitle"> Hide Advanced Search</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-danger wrn-btn search-btn shadow"
              onClick={this.SubmitBasic.bind(this)}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar;
