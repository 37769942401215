import React from "react";
import PhantomCard from "./PhantomCard";
import "../Pages/customcss/LinkedinMenu.css";
import PageHeader from "../PageHeader";
import { LinkedInCards } from "../../Utils/DummyData";

class LinkedInMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: LinkedInCards,
    };
  }

  render() {
    return (
      <>
        <div className="text-left p-3">
          <PageHeader
            HeaderText="LinkedIn Tools"
            Breadcrumb={[{ name: "Linkedin" }]}
          />
          <h3>Welcome To Our LinkedIn Module</h3>
        </div>

        <div className="row">
          {this.state.cardData.map((item, Itemkey) => (
            <PhantomCard
              Key={Itemkey}
              Title={item.Title}
              Desc={item.Description}
              Link={item.Link}
            />
          ))}
        </div>
      </>
    );
  }
}
export default LinkedInMenu;
